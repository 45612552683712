import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.api.network.firewall.ruleset.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_FIREWALL_RULESET_LIST',
	method: 'POST',
	url: '/network/firewall/ruleset/list.json',
});

const selectors = {
	items: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'items'], ImmutableList()),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
