import { createSelector } from 'reselect';

const getStateSlice = (state) => state.sshkeys;

const getMode = createSelector(getStateSlice, (sshkeys) => sshkeys.mode);

const getErrors = createSelector(
	getStateSlice,
	(sshkeys) => sshkeys.errors || [],
);

const getPublicKeyName = createSelector(
	getStateSlice,
	(sshkeys) => sshkeys.publicKeyName,
);

const getPublicKeyValue = createSelector(
	getStateSlice,
	(sshkeys) => sshkeys.publicKeyValue,
);

const getAPIParams = createSelector(getStateSlice, (sshkeys) => {
	const { publicKeyName, publicKeyValue, id } = sshkeys;
	return {
		id,
		publicKeyName,
		publicKeyValue,
	};
});

export {
	getStateSlice,
	getMode,
	getErrors,
	getAPIParams,
	getPublicKeyName,
	getPublicKeyValue,
};
