import { connect } from 'react-redux';
import { compose } from 'redux';
import {
	getIsValidateDialogOpen,
	getAddress,
} from 'modules/account/address/addressSelectors';
import {
	actions as validateAddressActions,
	selectors as validateAddressSelectors,
} from 'modules/api/contact/validateAddressModule';
import addressActions from 'modules/account/address/addressActions';
import ValidateAddressDialog from './ValidateAddressDialog';

const mapStateToProps = (state) => ({
	isOpen: getIsValidateDialogOpen(state),
	isLoading: validateAddressSelectors.isLoading(state),
	hasError: validateAddressSelectors.hasError(state),
	validatedAddress: validateAddressSelectors.getNativeData(state)?.address,
	originalAddress: getAddress(state),
});
const mapDispatchToProps = (dispatch) => ({
	clearValidatedAddress: () => dispatch(validateAddressActions.clear()),
	setIsOpen: (isOpen) => dispatch(addressActions.toggleDialog(isOpen)),
	submitOriginal: () => dispatch(addressActions.submitOriginal()),
	submitValidated: () => dispatch(addressActions.submitValidated()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
	ValidateAddressDialog,
);
