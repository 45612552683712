import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import connectAndWrap from 'utility/form/connectAndWrap';
import { selectors as contactListSelectors } from 'modules/api/contact/listModule';
import { actions as contactActions } from 'modules/contact';
import { Field, formValueSelector } from 'redux-form';
import { required, email as emailValidator } from 'redux-form-validators';
import { phoneNumber } from 'utility/form/validators';
import TextField from 'components/atoms/TextField';

import Required from 'components/atoms/Required';
import LWCard from 'components/common/LWCard';

const formName = 'AccountCreateContactForm';
const formSelector = formValueSelector(formName);

// Grid does not do different spacings for x and y :(
const p = { px: 1, py: 2 };

const AccountCreateContactForm = ({ initContactList, isLoading }) => {
	useEffect(() => {
		if (!initContactList) return;
		initContactList();
	}, [initContactList]);
	return (
		<LWCard title="Primary Contact Info">
			<form>
				<Grid container>
					{/* makes the whole form take up 2/3s or block */}
					<Grid item xs={12}>
						<Required />
					</Grid>
					<Grid item xs={12} md={8}>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Box {...p}>
									<Field
										aria-label="First Name"
										name="firstname"
										label="First Name"
										component={TextField}
										validate={[required()]}
										variant="outlined"
										disabled={isLoading}
										required
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box {...p}>
									<Field
										aria-label="Last Name"
										name="lastname"
										label="Last Name"
										component={TextField}
										validate={[required()]}
										variant="outlined"
										disabled={isLoading}
										required
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box {...p}>
									<Field
										aria-label="Email"
										name="email"
										label="Email"
										component={TextField}
										validate={[required(), emailValidator()]}
										variant="outlined"
										disabled={isLoading}
										required
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box {...p}>
									<Field
										aria-label="Phone Number"
										name="phone"
										label="Phone Number"
										component={TextField}
										validate={[required(), phoneNumber()]}
										variant="outlined"
										disabled={isLoading}
										required
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box {...p}>
									<Field
										aria-label="Organization"
										name="organization"
										label="Organization"
										component={TextField}
										variant="outlined"
										disabled={isLoading}
										optional
									/>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</LWCard>
	);
};

AccountCreateContactForm.propTypes = {
	isLoading: PropTypes.bool,
	initContactList: PropTypes.func.isRequired,
	// eslint-disable-next-line react/no-unused-prop-types
	initialValues: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	isLoading: contactListSelectors.isLoading(state),
	initialValues: contactListSelectors.accountCreateInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
	initContactList: () => {
		dispatch(contactActions.initList());
	},
});

export { AccountCreateContactForm, formSelector, formName };
export default connectAndWrap({
	mapStateToProps,
	mapDispatchToProps,
	form: formName,
})(AccountCreateContactForm);
