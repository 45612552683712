import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.licensing.controlPanelPrices;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'LICENSING_CONTROLPANELPRICES',
	method: 'POST',
	url: '/licensing/controlPanelPrices.json',
});

const selectors = {
	getCPanelPrice: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'summary', 'cpanel'], ''),
	),
	getInterworxPrice: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'summary', 'interworx'], ''),
	),
	getDetails: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'details'], []),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
