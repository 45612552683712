import React from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import dialogActions from 'modules/dialogs/actions';
import { actions as cloneActions } from 'modules/basket/addItemForClone';
import cartSelectors from 'modules/cart/selectors';

// MUI components
import Grid from '@material-ui/core/Grid';

// custom components
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import cartActions from 'modules/cart/actions';

const CloneServerModalContents = ({
	domain,
	uniqId,
	featureDetails,
	productCode,
}) => {
	const dispatch = useDispatch();

	const isCartLocked = useSelector(cartSelectors.getIsCartLocked);
	const addItemForClone = () => {
		dispatch(
			cloneActions.addItemForClone({
				uniqId,
				force: !featureDetails,
				productCode,
			}),
		);
	};

	const handleConfirm = () => {
		if (isCartLocked) {
			dispatch(
				cartActions.openAbandonCartDialog({
					onAbandoned: addItemForClone,
				}),
			);
		} else {
			addItemForClone();
		}
	};

	return (
		<Grid container direction="column" spacing={3}>
			<Grid item>
				<LWTypography>
					You have chosen to clone: <b>{domain}</b>
				</LWTypography>
			</Grid>
			<Grid item>
				<LWTypography>
					To do this, a new VPS will be added to your cart with identical
					configuration, able to be changed before checkout.
				</LWTypography>
			</Grid>
			<Grid item>
				<LWTypography>
					After checkout, the clone will be created using a ‘hot copy’ method,
					meaning that all data will be copied from the original VPS to the
					clone VPS.{' ' /* intentional space */}
					<b>
						If the server is busy, this could lead to cloning incompletely
						written files and corrupted data.
					</b>
				</LWTypography>
			</Grid>

			<Grid item>
				<ConfirmCancel
					confirmText="Add to cart"
					cancel={() => {
						dispatch(dialogActions.close());
					}}
					confirm={handleConfirm}
				/>
			</Grid>
		</Grid>
	);
};

export default CloneServerModalContents;
