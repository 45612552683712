import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice: (state) => state.api.server.reset,
	actionType: 'SERVER_RESET_STATE',
	method: 'POST',
	url: '/server/power/reset.json',
});

export { actions, reducer, sagas, selectors };
