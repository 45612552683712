const unauthedRoutes = [
	'/cart/:basketUuid?',
	'/cart/saved/:basketUuid',
	'/external/chatLoading',
	'/external/chatUnavailable',
	'/external/invoicepdf/:id',
	'/external/news',
	'/external/openid/return',
	'/external/openid/silent-return',
	'/external/vnc-console/:uniqId',
	'/shop',
	'/shop/add/:productCode',
	'/shop/config/:itemUuid',
	'/shop/config/:itemUuid/:step',
	'/shop/domain',
	'/shop/domain/configure',
	'/shop/marketplace',
	'/shop/marketplace/:tab',
	'/shop/package/:packageCode',
	'/shop/package/:packageCode/:uuid',
];

export default unauthedRoutes;
