import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	actions as assetListActions,
	selectors as assetListSelectors,
} from 'modules/api/asset/listModule';
import blockStorageActions from 'modules/blockStorage/actions';
import {
	actions as blockStoragePriceActions,
	selectors as blockStoragePriceSelectors,
} from 'modules/api/product/detailsModule';
import { actions as dialogActions } from 'modules/dialogs';
import {
	actions as storageBlockClusterListActions,
	selectors as storageBlockClusterListSelectors,
} from 'modules/api/storage/block/cluster/listModule';

import Box from '@material-ui/core/Box';
import { Form } from 'react-final-form';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import Loading from 'components/common/Loading';
import LWButton from 'components/common/LWButton';
import { LWLink } from 'components/common/LWLink';
import LWSlider from 'components/molecules/LWSlider';
import LWTooltip from 'components/common/LWTooltip';
import LWTypography from 'components/common/LWTypography';

const CreateStorage = ({
	uniqueId,
	allowServerSelection = false,
	isDialog = false,
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(blockStoragePriceActions.fetch({ code: 'SS.SBS' }));

		dispatch(storageBlockClusterListActions.fetch());
	}, [dispatch]);

	useEffect(() => {
		dispatch(
			assetListActions.fetch({
				alsowith: ['capabilities', 'zone'],
				category: [
					'Dedicated',
					'WordPress',
					'Shared',
					'LoadBalancer',
					'Service',
					'SBS',
					'SSL',
					'ObjectStorage',
				],
				page_size: 9999,
			}),
		);
	}, [dispatch]);

	const blockStoragePricesLoading = useSelector(
		blockStoragePriceSelectors.isLoading,
	);
	const blockStoragePrices = useSelector(
		blockStoragePriceSelectors.getBlockStoragePrices,
	);

	const storageBlockClustersLoading = useSelector(
		storageBlockClusterListSelectors.isLoading,
	);
	const storageBlockClusters = useSelector(
		storageBlockClusterListSelectors.getNativeItems,
	);
	const regions = useSelector(storageBlockClusterListSelectors.getRegions);

	const assetsLoading = useSelector(assetListSelectors.isLoading);
	const assets = useSelector(assetListSelectors.getNativeItems);
	const storageCapableAssets = useMemo(() => {
		const attachableAssets = assets?.filter(
			(item) =>
				item?.capabilities?.volumeAttach ||
				item?.capabilities?.volumeCouldAttach,
		);
		return attachableAssets?.filter(
			(item) =>
				item?.zone &&
				storageBlockClusters?.some((cluster) =>
					cluster?.zoneAvailability?.includes(item?.zone.id),
				),
		);
	}, [assets, storageBlockClusters]);

	const isLoading = useMemo(
		() =>
			assetsLoading || blockStoragePricesLoading || storageBlockClustersLoading,
		[assetsLoading, blockStoragePricesLoading, storageBlockClustersLoading],
	);

	return isLoading ? (
		<Box display="flex" justifyContent="center" alignItems="center">
			<Loading position="static" />
		</Box>
	) : (
		<CreateForm
			uniqueId={uniqueId}
			allowServerSelection={allowServerSelection}
			isDialog={isDialog}
			prices={blockStoragePrices}
			assets={storageCapableAssets}
			regions={regions}
		/>
	);
};

const CreateForm = ({
	uniqueId,
	allowServerSelection = false,
	isDialog = false,
	prices,
	assets,
	regions,
}) => {
	const DEFAULT_VOLUME_SIZE = 5;

	const dispatch = useDispatch();

	const [selectedAssetUniqueId, setSelectedAssetUniqueId] = useState(
		uniqueId || assets[0]?.uniq_id || 0,
	);
	const [selectedRegionId, setSelectedRegionId] = useState(
		regions[0]?.id || '',
	);
	const [volumeName, setVolumeName] = useState('');
	const [volumeSize, setVolumeSize] = useState(DEFAULT_VOLUME_SIZE);

	const selectedAsset = useMemo(
		() => assets?.find((item) => item.uniq_id === selectedAssetUniqueId),
		[assets, selectedAssetUniqueId],
	);

	const regionId =
		selectedAssetUniqueId === 0
			? selectedRegionId
			: selectedAsset?.zone?.region?.id;

	const monthlyPrice = prices?.[regionId]?.month;
	const hourlyPrice = prices?.[regionId]?.hour;

	const closeDialog = () => dispatch(dialogActions.close());
	const handleConfirm = () => {
		const payload = {
			domain: volumeName,
			size: volumeSize,
			region: regionId,
		};

		if (selectedAssetUniqueId !== 0) {
			payload.serverUniqId = selectedAssetUniqueId;
		}

		dispatch(blockStorageActions.createVolume(payload));

		if (isDialog) {
			closeDialog();
		}
	};
	const handleVolumeNameChange = (event) => setVolumeName(event.target.value);

	return (
		<Form
			onSubmit={handleConfirm}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<Box px={1} py={2}>
						<LWTypography>
							SBS is a distributed, highly available block storage device that
							can be attached and detached from any server dynamically. It is
							provisioned as a raw block device allowing you the flexibility to
							use it in any way you require and create any filesystem that meets
							your needs. For additional information on using SBS please see{' '}
							<LWLink
								sendEvent={() => undefined}
								to="https://www.liquidweb.com/kb/using-storm-block-storage/"
							>
								www.liquidweb.com/kb/using-storm-block-storage/
							</LWLink>
						</LWTypography>
						<Box display="flex" alignItems="center" py={2}>
							<LWTypography>
								SBS storage disks carry a base charge of{' '}
								<strong>
									<u>${monthlyPrice} / mo</u>
								</strong>{' '}
								per GB.
							</LWTypography>
							<LWTooltip>
								<strong>
									<u>${hourlyPrice} / hr</u>
								</strong>
							</LWTooltip>
						</Box>
						<Box display="flex" alignItems="center" mt={2} mb={2}>
							<Box width="33%" mr="50px">
								<FormControl variant="outlined">
									<TextField
										type="text"
										label="Volume Name"
										value={volumeName}
										onChange={handleVolumeNameChange}
									/>
								</FormControl>
							</Box>
							<Box width="33%" mr="50px">
								<FormControl variant="outlined">
									<TextField
										type="text"
										label="Volume Size"
										value={`${volumeSize} GB`}
									/>
								</FormControl>
								<FormControl variant="outlined">
									<LWSlider
										lable="Size"
										value={volumeSize}
										min={DEFAULT_VOLUME_SIZE}
										max={150}
										onChange={(e) => {
											setVolumeSize(e);
										}}
									/>
								</FormControl>
							</Box>
							<Box flexBasis="max-content">
								<FormControl variant="outlined">
									<TextField
										type="text"
										label="Volume Price"
										value={`$${(volumeSize * monthlyPrice).toFixed(2)}`}
									/>
								</FormControl>
							</Box>
						</Box>
						<Box mt={2} mb={2}>
							<Grid spacing={2} container>
								<Grid item xs={4}>
									<LWTypography>
										<strong>Attach this Volume to:</strong>
									</LWTypography>
								</Grid>
								<Grid item xs={8}>
									{allowServerSelection ? (
										<FormControl variant="outlined">
											<InputLabel id="asset">Select</InputLabel>
											<Select
												label="Asset"
												labelId="asset"
												value={selectedAssetUniqueId}
												onChange={(e) =>
													setSelectedAssetUniqueId(e.target.value)
												}
												inputProps={{
													name: 'asset',
													id: 'asset',
												}}
											>
												<MenuItem value={0}>Nothing</MenuItem>
												{assets &&
													assets.map((item) => (
														<MenuItem key={item.uniq_id} value={item.uniq_id}>
															{item?.domain}
														</MenuItem>
													))}
											</Select>
										</FormControl>
									) : (
										selectedAsset?.domain
									)}
								</Grid>
								{selectedAssetUniqueId === 0 && regions && (
									<>
										<Grid item xs={4}>
											<LWTypography>
												<strong>Create Volume in Region:</strong>
											</LWTypography>
										</Grid>
										<Grid item xs={8}>
											<FormControl variant="outlined">
												<InputLabel id="region">Select</InputLabel>
												<Select
													label="Region"
													labelId="region"
													value={selectedRegionId}
													onChange={(e) => setSelectedRegionId(e.target.value)}
													inputProps={{
														name: 'region',
														id: 'region',
													}}
												>
													{regions &&
														regions.map((item, index) => (
															<MenuItem
																key={item.id}
																value={item.id}
																default={index === 0}
															>
																{item?.name}
															</MenuItem>
														))}
												</Select>
											</FormControl>
										</Grid>
									</>
								)}
							</Grid>
						</Box>
						<Grid spacing={2} container justifyContent="flex-end">
							{isDialog && (
								<Grid item>
									<LWButton variant="subtle" onClick={closeDialog}>
										Close
									</LWButton>
								</Grid>
							)}
							<Grid item>
								<FormControl variant="outlined">
									<LWButton
										color="secondary"
										variant="contained"
										type="submit"
										disabled={volumeName === ''}
									>
										Confirm
									</LWButton>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
				</form>
			)}
		/>
	);
};

export default CreateStorage;
