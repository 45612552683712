import { call, select, takeLatest } from 'redux-saga/effects';
import snackbarSaga from 'modules/snackbar/sagas';
import { actions as dnsRecordCreateActions } from 'modules/api/network/dns/record/createModule';
import { actions as dnsRecordUpdateActions } from 'modules/api/network/dns/record/updateModule';
import { actions as dnsRecordDeleteActions } from 'modules/api/network/dns/record/deleteModule';
import { actions as bulkUpdateTTLActions } from 'modules/api/network/dns/zone/bulkUpdateTTLModule';
import { actions as zoneDelegationActions } from 'modules/api/network/dns/zone/delegationModule';
import { actions as multiCreateAndUpdateActions } from 'modules/api/network/dns/record/multiCreateAndUpdateModule';
import { actions as bindActions } from 'modules/api/network/dns/zone/formatAsBindModule';
import {
	selectors as zoneDetailsSelectors,
	actions as zoneDetailsActions,
} from 'modules/api/network/dns/zone/detailsModule';
import { actions as zoneDeleteActions } from 'modules/api/network/dns/zone/deleteModule';
import { fetchZoneDetails } from './sagas';

function* zoneDelegationError(error) {
	yield call(snackbarSaga, {
		error,
		errorMessage:
			'Failed to recheck nameserver delegation. Please retry and if the error persists, contact support.',
	});
}

function* dnsRecordCreateError(error) {
	yield call(snackbarSaga, {
		error,
		errorMessage: 'Failed to create a new record',
	});
}

function* dnsRecordUpdateError(error) {
	yield call(snackbarSaga, {
		error,
		errorMessage: 'Failed to update record',
	});
}

function* dnsRecordDeleteError(error) {
	yield call(snackbarSaga, {
		error,
		errorMessage: 'Failed to delete record',
	});
}

function* multiCreateAndUpdateError(error) {
	yield call(snackbarSaga, {
		error,
		errorMessage: 'Failed to save changes',
	});
}

function* bulkUpdateTTLError(error) {
	yield call(snackbarSaga, {
		error,
		errorMessage: 'Failed to bulk update TTL',
	});
}

function* fetchZoneDetailsError() {
	yield call(snackbarSaga, {
		error: true,
		errorMessage: 'Failed to fetch zone details',
	});
}

function* delegationSuccess() {
	const name = yield select(zoneDetailsSelectors.getName);
	yield call(snackbarSaga, {
		successMessage: 'Name server delegation status updated',
	});
	yield call(fetchZoneDetails, { name });
}

function* bindError() {
	yield call(snackbarSaga, {
		error: true,
		errorMessage: 'Failed to retrieve DNS zone data.',
	});
}

function* deleteZoneSuccess() {
	yield snackbarSaga({ successMessage: 'The DNS zone has been deleted.' });
}

export default function* snackbarHandlers() {
	yield takeLatest(zoneDelegationActions.errorType, zoneDelegationError);
	yield takeLatest(dnsRecordCreateActions.errorType, dnsRecordCreateError);
	yield takeLatest(dnsRecordUpdateActions.errorType, dnsRecordUpdateError);
	yield takeLatest(dnsRecordDeleteActions.errorType, dnsRecordDeleteError);
	yield takeLatest(
		multiCreateAndUpdateActions.errorType,
		multiCreateAndUpdateError,
	);
	yield takeLatest(bulkUpdateTTLActions.errorType, bulkUpdateTTLError);
	yield takeLatest(bindActions.errorType, bindError);
	yield takeLatest(zoneDetailsActions.errorType, fetchZoneDetailsError);
	yield takeLatest(zoneDelegationActions.setType, delegationSuccess);
	yield takeLatest(zoneDeleteActions.setType, deleteZoneSuccess);
}
