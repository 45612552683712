import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import LWTypography from 'components/common/LWTypography';
import PropTypes from 'prop-types';
import randomNumberBetween from 'utility/randomNumberBetween';
import { useTheme } from '@material-ui/core/styles';
import {
	labeledTextVariants,
	typographyVariants,
	colors,
	bolded,
	labelBoxProps,
	textBoxProps,
} from './variants';

const createTextArray = ({ text, isLoading }) => {
	if (isLoading) return ['loading']; // This will trigger a single skeleton loader.
	if (!text) return []; // will cause null render.
	return (Array.isArray(text) ? text : [text]).filter(
		(elem) =>
			!!((typeof elem === 'object' ? elem.value : elem) || '').replace(
				/\s/g,
				'',
			),
	);
}; // Shows skeleton loaders if loading.

const LabeledText = ({
	label,
	text,
	inline = false,
	isLoading = false,
	variant = 'default',
	noMarginTop = false,
	hideLabelWhileLoading = false,
	BoxProps,
	dataTestId,
}) => {
	const theme = useTheme();
	const inlineBox = useMemo(
		() => (inline ? { display: 'inline-block' } : {}),
		[inline],
	);
	const labelProps = useMemo(
		() => ({
			variant: typographyVariants[variant]?.label,
			bold: bolded[variant]?.label,
			BoxProps: {
				...labelBoxProps[variant],
				...(noMarginTop ? { mt: 0 } : {}),
				...inlineBox,
			},
			color: colors[variant]?.label,
		}),
		[inlineBox, noMarginTop, variant],
	);

	const loaderWidth = useMemo(() => `${randomNumberBetween(40, 70)}%`, []);
	const textArray = useMemo(
		() => createTextArray({ text, isLoading }),
		[isLoading, text],
	);

	const content = textArray.map((elem) => (
		<LWTypography
			BoxProps={{
				...textBoxProps[variant],
				...inlineBox,
				minWidth: `${isLoading ? theme.spacing(13) : 0}px`,
			}}
			isLoading={isLoading}
			SkeletonProps={{ width: loaderWidth }}
			key={elem.key || elem}
			variant={typographyVariants[variant]?.text}
			color={colors[variant]?.text}
			bold={bolded[variant]?.text}
		>
			{typeof elem === 'object' ? elem.value : elem}
		</LWTypography>
	));

	// no content? no render.
	if (!content.length) return null;

	return (
		<Box data-testid={dataTestId} {...BoxProps}>
			{(!hideLabelWhileLoading || !isLoading) && !!label && (
				<LWTypography {...labelProps}>
					{label}
					{inline && <span>:&nbsp;</span>}
				</LWTypography>
			)}
			{content}
		</Box>
	);
};

LabeledText.propTypes = {
	/** The label for the text. */
	label: PropTypes.string,
	/** A string, or an array of strings. If this is empty, the whole component will not render. */
	text: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }),
				PropTypes.string,
			]),
		),
		PropTypes.string,
	]),
	/** This is passed into the LWTypography components for making skeletons. */
	isLoading: PropTypes.bool,
	/** adjusts the version of labeled text. */
	variant: PropTypes.oneOf(labeledTextVariants),
	/** Set this to true to get rid of the top margin (i.e. this is the first of a list.). Default is false. */
	noMarginTop: PropTypes.bool,
	/** Set this to true to remove the label if isLoading is also true. */
	hideLabelWhileLoading: PropTypes.bool,
	/** Make text appear beside the label preceeded by ':' */
	inline: PropTypes.bool,
	BoxProps: PropTypes.object,
};

export default LabeledText;
