import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import LWButton from 'components/common/LWButton';
import InfoBox from 'components/molecules/InfoBox';

import restoreActions from 'modules/server/backupsAndImages/restore/actions';

import { selectors as assetListSelectors } from 'modules/api/asset/listModule';
import { selectors as imageListSelectors } from 'modules/api/storm/image/listModule';

import { dateTimeLong } from 'utility/datetime';

const ServerSelectField = ({ input, options, isLoading, isDisabled }) => {
	return (
		<Autocomplete
			fullWidth
			options={options}
			{...input}
			getOptionLabel={(option) => option.label}
			getOptionSelected={(option) => option.value}
			onChange={(e, value) => {
				input.onChange(value);
			}}
			disabled={isDisabled || isLoading}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						{...(isLoading
							? {
									InputProps: {
										endAdornment: (
											<InputAdornment position="end">
												<CircularProgress />
											</InputAdornment>
										),
									},
								}
							: {})}
						label="Server"
						variant="outlined"
						helperText={
							isDisabled
								? 'Reimaging will be available once the current tasks on this server are complete.'
								: ''
						}
					/>
				);
			}}
		/>
	);
};

const ImageServerSelectDialogContents = ({ id, isDisabled }) => {
	const dispatch = useDispatch();

	const isLoading = useSelector(assetListSelectors.isLoading);

	const options = useSelector(assetListSelectors.options);
	const selector = imageListSelectors.selectedGenerator(id);

	const {
		time_taken: timeTaken,
		name,
		size,
		source_hostname: sourceHostname,
		template_description: templateDescription,
	} = useSelector(selector);

	const onSubmit = (values) => {
		dispatch(
			restoreActions.openImageConfirmDialog({
				uniqId: values.server.value,
				id,
			}),
		);
	};
	const defaultOption = { label: 'Please wait', value: 0 };

	return (
		<Form
			onSubmit={onSubmit}
			initialValues={{
				server: [...options].shift() || defaultOption,
			}}
			render={({ handleSubmit, values }) => {
				return (
					<form onSubmit={handleSubmit}>
						<InfoBox
							mb={4}
							details={[
								name,
								dateTimeLong(timeTaken),
								`${size} GB`,
								sourceHostname,
								templateDescription,
							]}
						/>

						<Field
							name="server"
							render={({ input }) => (
								<ServerSelectField
									options={options || [defaultOption]}
									isLoading={isLoading}
									isDisabled={isDisabled}
									input={input}
								/>
							)}
						/>
						<LWButton
							BoxProps={{ mt: 2, justifyContent: 'center', display: 'flex' }}
							color="secondary"
							variant="contained"
							disabled={!values.server}
							type="submit"
							data-testid="ImageServerSelectDialogContents__SubmitButton"
						>
							Continue with Reimage
						</LWButton>
					</form>
				);
			}}
		/>
	);
};

ImageServerSelectDialogContents.propTypes = {
	id: PropTypes.string,
	isDisabled: PropTypes.bool,
};

export default ImageServerSelectDialogContents;
