/* eslint-disable camelcase */
import { createSelector } from 'reselect';
import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.storm.config.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
	additionalSagas,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORM_CONFIG_LIST',
	method: 'POST',
	url: '/storm/config/list.json',
});

/**
 * gets a map of ConfigId `id`s to zone availablity
 */
const getConfigIdToZoneAvailablity = createSelector(
	defaultSelectors.getNativeItems,
	/** @returns {Object.<string, ZoneAvailabilty>} */
	(items) => {
		const configIdToZoneAvailablity = {};
		items.forEach((config) => {
			configIdToZoneAvailablity[config.id] = config.zone_availability;
		});

		return configIdToZoneAvailablity;
	},
);

const getConfigIdToExtraData = createSelector(
	defaultSelectors.getNativeItems,
	/** @returns {Object.<string, Object>} */
	(items) => {
		const configIdToExtraData = {};
		items.forEach(
			({
				id,
				category,
				cpu_cores,
				cpu_model,
				cpu_hyperthreading,
				cpu_speed,
				description,
				disk,
				disk_type: storage_type,
				memory,
				raid_level: storage_raid,
				ram_available,
				vcpu,
			}) => {
				const extraData = {
					cores: category === 'bare-metal' ? cpu_cores : vcpu,
					vcpu,
					cpu_description: cpu_model || description,
					cpu_speed,
					cpu_hyperthreading,
					memory: category === 'bare-metal' ? ram_available : memory,
					storage_raid,
					storage_size: disk,
					storage_type,
					category,
				};
				configIdToExtraData[id] = extraData;
			},
		);

		return configIdToExtraData;
	},
);

const selectors = {
	...defaultSelectors,
	getConfigIdToExtraData,
	getConfigIdToZoneAvailablity,
};

export {
	actions as stormConfigListActions,
	reducer as stormConfigListReducer,
	sagas as stormConfigListSagas,
	selectors as stormConfigListSelectors,
	additionalSagas as stormConfigListAdditionalSagas,
};
