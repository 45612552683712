const prod = {
	baseURL: 'https://api.liquidweb.com/',
	session: {
		length: 900,
		warningTime: 150,
	},
	hosts: {
		www: 'www.liquidweb.com',
		cart: 'cart.liquidweb.com',
		manage: 'manage.liquidweb.com',
		salesforce: 'liquidweb.lightning.force.com/lightning/r',
		customer_community: 'help.liquidweb.com/s',
		managed_apps: 'app.liquidweb.com',
		authorize_net: 'accept.authorize.net',
		gator: 'gator.liquidweb.com',
	},
	gaTrackerId: 'UA-363336-2',
	gtmContainerId: 'GTM-5GC5R3B',
	cartStack: {
		siteId: 'k5FaX1dK',
		trackingScriptUrl:
			'https://api.cartstack.com/js/customer-tracking/cart.liquidweb.com_71f77bbc0c9db256f407bbc6821d6994.js',
	},
	guestAuth: {
		username: 'remote-signup',
		password: 's|gnm3up',
	},
	cart: {
		checkoutURL: 'https://www.liquidweb.com/cart/checkout',
	},
	chatConfig: {
		analyticsActionContext: 'lw',
		storageDomain: 'liquidweb.com',
		baseCoreURL: 'https://liquidweb.my.salesforce.com',
		communityEndpointURL: 'https://sf-assets.liquidweb.com/public',
		gslbBaseURL: 'https://service.force.com',
		orgId: '00D30000000pmDy',
		eswConfigDevName: 'Liquidweb',
		settings: {
			baseLiveAgentContentURL:
				'https://c.la3-c2-ph2.salesforceliveagent.com/content',
			deploymentId: '5720c0000004aCZ',
			buttonId: '5730c0000004aJB',
			baseLiveAgentURL: 'https://d.la3-c2-ph2.salesforceliveagent.com/chat',
			eswLiveAgentDevName:
				'EmbeddedServiceLiveAgent_Parent04I3a0000008OJmEAM_177cb2c3883',
			isOfflineSupportEnabled: false,
			chatbotAvatarImgURL:
				'https://media.liquidweb.com/salesforce/img/liquid-web-logo.jpg',
		},
	},
	openId: {
		client_id: 'HxSmx8sSSTKOuvDEKYRdgw',
		authority: 'https://login.liquidweb.com',
		accessTokenExpiringNotificationTime: 1200,
	},
};

export default prod;
