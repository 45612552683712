import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import navTrackerActions from 'modules/navTracker/actions';
import { connect } from 'react-redux';

const NavTracker = ({
	displayName = null,
	sendTrackingEvent,
	path,
	assetId = null,
	isAssetDomain = false,
}) => {
	const [hasSentEvent, setHasSentEvent] = useState(false);
	useEffect(() => {
		// TODO: check if LWAgent and ignore if true
		if (!hasSentEvent && path && displayName && assetId) {
			sendTrackingEvent({
				path,
				displayName,
				assetId,
				isAssetDomain,
			});
			setHasSentEvent(true);
		}
	}, [
		assetId,
		displayName,
		hasSentEvent,
		isAssetDomain,
		path,
		sendTrackingEvent,
	]);
	return null;
};

NavTracker.propTypes = {
	displayName: PropTypes.string,
	sendTrackingEvent: PropTypes.func.isRequired,
	path: PropTypes.string.isRequired,
	assetId: PropTypes.string,
	isAssetDomain: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	path: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) => ({
	sendTrackingEvent: (payload) => dispatch(navTrackerActions.trackNav(payload)),
});

export { NavTracker };

export default connect(mapStateToProps, mapDispatchToProps)(NavTracker);
