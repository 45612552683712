import { push } from 'connected-react-router';
import {
	accountBillingAddressAdditionalSagas,
	selectors as billingAddressSelectors,
} from 'modules/api/account/billingAddress/detailsModule';
import {
	contactListAdditionalSagas,
	selectors as contactListSelectors,
} from 'modules/api/contact/listModule';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import {
	isKeroOnly as getIsKeroOnly,
	hasAuthToken,
} from 'modules/auth/authSelectors';
import { selectors as cartSelectors } from 'modules/cart';
import analyticsActions from 'modules/gtm/actions';
import {
	hasPaymentMethodCheck,
	init as initPaymentDetails,
} from 'modules/payment/paymentSagas';
import assertMetadataSaga from 'modules/cart/assertMetadataSaga';
import snackbarSaga from 'modules/snackbar/sagas';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import basketCheckoutActions from './actions';

function* hasContactCheck() {
	yield call(contactListAdditionalSagas.fetchDirectly, {}, undefined, {
		init: true,
	});
	const contacts = yield select(contactListSelectors.getNativeItems);
	if (contacts.length > 0) {
		return true;
	}

	yield put(push('/account/create'));

	yield call(snackbarSaga, {
		variant: 'info',
		successMessage: 'Please complete your contact details',
	});

	return false;
}

function* initBillingAndAddress() {
	if (!(yield select(billingAddressSelectors.hasData))) {
		yield call(accountBillingAddressAdditionalSagas.fetchDirectly);
	}
}

// used for if the user wants to shop before finishing creating their account and then tries to check out.
function* takeToNextStep({ path } = {}) {
	const shouldAssertShowSpecialOffers = yield select(
		cartSelectors.getShouldAssertShowSpecialOffers,
	);

	if (shouldAssertShowSpecialOffers) {
		yield call(assertMetadataSaga, { metadata: { showSpecialOffers: true } });
	}

	const isKeroOnly = yield select(getIsKeroOnly);
	if (isKeroOnly) {
		yield put(push('/account/create'));
		return;
	}

	const isLoggedIn = yield select(hasAuthToken);
	if (!isLoggedIn) {
		yield put(push('/shop/checkout'));
		return;
	} // this will redirect them to login page

	const hasContact = yield call(hasContactCheck);

	if (!hasContact) {
		return;
	}

	yield call(initPaymentDetails); // blocking.
	yield call(initBillingAndAddress); // blocking

	const nextStepPath = path || (yield select(cartSelectors.getNextStepPath));

	// We handle this situation here instead of in the selector because we need to show the snackbar.
	if (nextStepPath === '/shop/checkout') {
		const value = yield select(cartDetailsSelectors.getSubtotal);
		const items = yield select(cartDetailsSelectors.getNativeItems);
		const totals = yield select(cartDetailsSelectors.getTotals);
		yield put(
			analyticsActions.sendAnalyticsCheckoutBegin({
				items,
				value,
				totals,
			}),
		);

		yield call(hasPaymentMethodCheck, {
			navigate: true,
		}); // doing this instead of the selector because we want the snackbar and navigation.

		return;
	}

	yield put(push(nextStepPath));
}

export { takeToNextStep };
export default function* saga() {
	yield takeLatest(
		basketCheckoutActions.ACCOUNT_CREATE_TAKE_TO_NEXT_STEP,
		takeToNextStep,
	);
}
