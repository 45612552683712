import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import LWButton from 'components/common/LWButton';
import LWTypography from 'components/common/LWTypography';
import PaperBox from 'components/molecules/PaperBox';
import LabeledText from 'components/atoms/LabeledText';

const defaultContent = [
	{
		key: 'about',
		label: 'About Unexpected Error',
		text: "A new bug. We'll take a look to prevent it from happening in the future.",
	},
	{
		key: 'what',
		label: 'What happened?',
		text: 'An error occurred during an operation, preventing the page from being displayed.',
	},
	{
		key: 'action',
		label: 'What can I do?',
		text: 'Refresh to try again. If the error continues, contact support.',
	},
];

const ErrorText = ({
	title = 'Error',
	hideRefresh,
	refreshButtonText = 'Refresh',
	contentArray = defaultContent,
	children,
	...BoxProps
}) => {
	const theme = useTheme();

	return (
		<Box display="flex" justifyContent="center" {...BoxProps}>
			<Box
				maxWidth={`${theme.spacing(45)}px`}
				flexDirection="column"
				display="flex"
				alignItems="center"
			>
				{/* TODO: eventually there will need to be support for icons here once they are approved */}
				<LWTypography
					variant="h2"
					light
					color="palette.primary.main"
					BoxProps={{ pb: 4 }}
				>
					{title}
				</LWTypography>
				<PaperBox display="flex" alignItems="center" flexDirection="column">
					<Grid container spacing={3} direction="column">
						{contentArray.map(({ label, text, key }) => (
							<Grid item key={key}>
								<LabeledText {...{ label, text }} variant="boldedLabel" />
							</Grid>
						))}
						{!hideRefresh && (
							<Grid item component={Box} textAlign="center">
								<LWButton
									onClick={() => window.location.reload()}
									data-testid="ErrorText__RefreshButton"
								>
									{refreshButtonText}
								</LWButton>
							</Grid>
						)}
						<Grid item>{children}</Grid>
					</Grid>
				</PaperBox>
			</Box>
		</Box>
	);
};
ErrorText.propTypes = {
	/** Text displayed at the very top of the page */
	title: PropTypes.string,
	/** Set to true to hide the refresh button */
	hideRefresh: PropTypes.bool,
	/** Array is label/text pairs to pass into LabeledText */
	contentArray: PropTypes.arrayOf(
		PropTypes.shape({ label: PropTypes.string, text: PropTypes.string }),
	),
	/** String to display in the refresh button */
	refreshButtonText: PropTypes.string,
};

export default ErrorText;
