import { connect } from 'react-redux';
import referAFriendDialogActions from 'modules/referAFriendDialog/actions';
import referAFriendDialogSelectors from 'modules/referAFriendDialog/selectors';
import { selectors as accountDetailsSelectors } from 'modules/api/account/detailsModule';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import snackbarActions from 'modules/snackbar/snackbarActions';
import ReferAFriendDialog from './ReferAFriendDialog';

const mapStateToProps = (state) => ({
	isOpen: referAFriendDialogSelectors.isOpen(state),
	referAFriendCode: accountDetailsSelectors.referAFriendCode(state),
	wwwHostname: appConfigSelectors.getWwwHostname(state),
	cartHostname: appConfigSelectors.getCartHostname(state),
});

const mapDispatchToProps = (dispatch) => ({
	closeDialog: () => dispatch(referAFriendDialogActions.toggleDialog(false)),
	openSnackbar: (message, color) =>
		dispatch(snackbarActions.pushMessage({ message, variant: color })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferAFriendDialog);
