import { useTheme } from '@material-ui/core/styles';
import get from 'lodash/get';

// Takes array of two colors, or a string of a single color.
const useGradient = (color, direction = 'vertical') => {
	const theme = useTheme();
	const color1 = get(theme.palette, Array.isArray(color) ? color[0] : color);
	const color2 = get(theme.palette, Array.isArray(color) ? color[1] : color);

	// make sure the hexcodes do not include alpha.
	const hasAlpha = /^#.{8}$|rgba/; // No 8 charactor hex codes. No "a" in "rgba"
	if (hasAlpha.test(color1) || hasAlpha.test(color2)) {
		// eslint-disable-next-line no-console
		console.error(
			`colors used for gradients may not contain an alpha value. Either ${color1} or ${color2} with break gradients in Safari.`,
		);
	}
	return `background: white
    linear-gradient(
        ${direction === 'vertical' ? '180deg' : '90deg'}, 
				${color1} 0%, 
				${color2} 100%
    )
    0% 0% no-repeat padding-box;`;
};

// Discovered while fixing NEWMAN-2037: only opaque colors should be used for gradiants.
// Adding alpha values to the end of the hexcode breaks the gradiant in safari.

export default useGradient;
