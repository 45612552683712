import { takeEvery, select, take, put, call } from 'redux-saga/effects';
import {
	selectors as assetDetailsSelectors,
	actions as assetDetailsActions,
} from 'modules/api/asset/detailsModule';
import { removeUndefinedKeys } from 'utility/tools/objects';
import { takeSetOrError } from 'utility/redux/apiModuleHelpers';
import actions from './actions';

function* uniqIdDetailsSync({ uniqId, alsowith, force }) {
	if (yield select(assetDetailsSelectors.isLoading)) {
		yield take([assetDetailsActions.setType, assetDetailsActions.errorType]);
	}
	if ((yield select(assetDetailsSelectors.uniqId)) !== uniqId || force) {
		yield put(
			assetDetailsActions.fetch(
				removeUndefinedKeys({ uniq_id: uniqId, alsowith }),
			),
		);
		yield call(takeSetOrError, {
			selectors: assetDetailsSelectors,
			actions: assetDetailsActions,
		});
	}
}

export { uniqIdDetailsSync };
export default function* assetSagas() {
	yield takeEvery(actions.ASSET_UNIQ_ID_DETAILS_SYNC, uniqIdDetailsSync);
}
