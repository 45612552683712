import { all, call } from 'redux-saga/effects';

import accountSagas from './account/accountSagas';
import acronisSagas from './acronis/acronisSagas';
import assetSagas from './asset/assetSagas';
import billingSagas from './billing/billingSagas';
import contactSagas from './contact/contactSagas';
import domainSagas from './domain/domainSagas';
import licensingSagas from './licensing/licensingSagas';
import marketSagas from './market/sagas';
import monitoringSagas from './monitoring/monitoringSagas';
import networkSagas from './network/networkSagas';
import notificationsSagas from './notifications/notificationsSagas';
import productSagas from './product/productSagas';
import projectSagas from './project/projectSagas';
import salesforceSagas from './salesforce/salesforceSagas';
import serverSagas from './server/serverSagas';
import sslCertificateSagas from './ssl/certificate/sslCertificateSagas';
import storageSagas from './storage/storageSagas';
import stormSagas from './storm/stormSagas';
import supportSagas from './support/supportSagas';
import usertaskSagas from './usertask/usertaskSagas';
import utilitiesSagas from './utilities/utilitiesSagas';
import vmwareSagas from './vmware/vmwareSagas';
import vpnSagas from './vpn/vpnSagas';

export default function* apiRootSagas() {
	yield all([
		call(accountSagas),
		call(acronisSagas),
		call(assetSagas),
		call(billingSagas),
		call(contactSagas),
		call(domainSagas),
		call(licensingSagas),
		call(marketSagas),
		call(monitoringSagas),
		call(networkSagas),
		call(notificationsSagas),
		call(productSagas),
		call(projectSagas),
		call(salesforceSagas),
		call(serverSagas),
		call(sslCertificateSagas),
		call(storageSagas),
		call(stormSagas),
		call(supportSagas),
		call(usertaskSagas),
		call(utilitiesSagas),
		call(vmwareSagas),
		call(vpnSagas),
	]);
}
