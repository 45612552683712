// @ts-check

/**
 * @typedef RoleI
 * @property {boolean} [isMaster]
 * @property {string} key
 * @property {string} name
 * @property {string} description
 * */

/** @type {Record<string, RoleI} */
const roles = {
	BASKET_ADMIN: {
		isMaster: true,
		key: 'BasketAdmin',
		name: 'Basket Admin',
		description: 'Internal user with administrative permissions for baskets.',
	},
	ACCOUNT_OWNER: {
		isMaster: true,
		key: 'AccountOwner',
		name: 'Account Owner',
		description: 'Primary User',
	},
	SECONDARY_OWNER: {
		isMaster: true,
		key: 'SecondaryOwner',
		name: 'Secondary Owner',
		description:
			'The user has the same permissions as the Account Owner (primary) user.',
	},
	PURCHASER: {
		key: 'Purchaser',
		name: 'Purchaser',
		description:
			'The user has the ability to make purchases and manage account billing information.',
	},
	TECHNICIAN: {
		key: 'Technician',
		name: 'Technician',
		description: 'The user has the ability to manage technical details.',
	},
	SUSPENDED_OWNER: {
		key: 'SuspendedOwner',
		name: 'Suspended Owner',
		description: 'Primary User on a suspended account.',
	},
	SETUP_USER: {
		key: 'SetupUser',
		name: 'Setup User',
		description: 'New user account that has not yet been activated.',
	},
};

const allowedRoles = [
	'AccountOwner',
	'SecondaryOwner',
	'Purchaser',
	'Technician',
	'SuspendedOwner',
	'SetupUser',
];

/** @param {string} key */
const getRoleFromKey = (key) =>
	Object.values(roles).find((role) => role.key === key);

/** @param {string[]} keys */
const getRolesFromKeys = (keys) =>
	Object.values(roles).filter((role) => keys.includes(role.key));

/** @param {string} key */
const getDisplayNameFromKey = (key) => {
	const role = getRoleFromKey(key);
	if (role) return role.name;
	return key;
};

const masterRoles = Object.values(roles).filter((role) => role.isMaster);

export {
	roles,
	getRoleFromKey,
	getRolesFromKeys,
	getDisplayNameFromKey,
	masterRoles,
	allowedRoles,
};
