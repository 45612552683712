import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.api.contact.create;

const {
	actions: contactCreateActions,
	additionalSagas: contactCreateAdditionalSagas,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'CONTACT_CREATE',
	method: 'POST',
	url: '/contact/create.json',
});

const contactCreateSelectors = {
	...defaultSelectors,
};

export {
	contactCreateActions,
	contactCreateAdditionalSagas,
	contactCreateSelectors,
	reducer,
	sagas,
};
