import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Box from '@material-ui/core/Box';
import classnames from 'classnames';
import LWTypography from 'components/common/LWTypography';
import LWLink from 'components/common/LWLink';
import RowActions from 'components/common/table/RowActions';
import Warning from '@material-ui/icons/WarningRounded';

const lwCardTestIds = {
	typography: 'LWCard__TitleTypography',
	content: 'LWCard__Content',
};

const types = {
	DARK: 'dark',
	LIGHT: 'light',
	ERROR: 'error',
};

const headerAlignment = {
	LEFT: 'left',
	CENTER: 'center',
};

const styles = ({ breakpoints, palette, spacing }) => ({
	root: {
		display: 'flex',
		flexFlow: 'column',
		justifyContent: 'space-between',
	},
	grid: {
		height: '100%',
	},
	cardContent: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	cardContentPadding: {
		padding: '32px',
	},
	cardContentBare: {
		padding: 0,
	},
	nolinks: {
		flexGrow: 1,
	},
	loading: {
		display: 'block',
		margin: '0 auto',
	},
	cardHeaderRoot: {
		[breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
		borderBottom: `2px solid ${palette.divider}`,
		padding: `${spacing(1.5)}px ${spacing(2)}px`,
		textOverflow: 'ellipsis',
	},
	cardHeaderActions: {
		[breakpoints.down('sm')]: {
			marginTop: spacing(1),
		},
		marginRight: 0,
	},
	cardHeaderContent: {
		[breakpoints.up('md')]: {
			height: '1.125rem',
		},
		display: 'flex',
		width: '100%',
		alignItems: 'center',
	},
	headerIcon: {
		paddingRight: '12px',
	},
	title: {
		width: '100%',
		marginRight: spacing(1),
		fontSize: '1.125rem',
		fontWeight: '600',
		lineHeight: '24px',
	},
	titleDark: {
		color: palette.common.white1,
	},
	contentTypography: {
		fontSize: '16px',
	},
	cardActions: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'center',
		flexGrow: 1,
		padding: '0px 32px 32px 32px',
	},
	linkWrapper: {
		display: 'flex',
		textAlign: 'center',
		alignItems: 'center',
		'&> *': {
			borderWidth: '0 1px 0 0',
			borderStyle: 'solid',
			borderColor: palette.grey.disabledOpaque,
			'&:last-child': {
				border: '0',
			},
		},
	},
	info: {
		backgroundColor: palette.primary.main,
	},
	dark: {
		backgroundColor: palette.primary.dark,
	},
	light: {
		backgroundColor: palette.common.white,
	},
	warning: {
		color: palette.common.red,
	},
});

const LWCard = ({
	classes,
	children = null,
	grid = true,
	column = true,
	title = null,
	type = types.LIGHT,
	links = null,
	isLoading = false,
	headerAlign = headerAlignment.LEFT,
	headerActions = null,
	headerIcon = null,
	headerLink,
	noPadding = false,
	...rest
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const content = isLoading ? (
		<Box pt={noPadding ? 2 : 0}>
			<CircularProgress className={classes.loading} />
		</Box>
	) : (
		children
	);

	const getHeaderIcon = () => {
		if (headerIcon)
			return <div className={classes.headerIcon}>{headerIcon}</div>;
		if (type === types.ERROR) {
			return (
				<div className={classes.headerIcon}>
					<Warning className={classes.warning} />
				</div>
			);
		}
		return null;
	};

	return (
		<Card
			className={classnames({ [classes.root]: true, [classes.grid]: grid })}
			square
			{...rest}
			variant="outlined"
		>
			<CardHeader
				action={
					headerActions ? (
						<RowActions
							vertical
							light
							rowActions={headerActions}
							placement={isMobile ? 'right-end' : 'bottom-start'}
						/>
					) : null
				}
				className={classnames(classes.header, classes[type])}
				classes={{
					root: classes.cardHeaderRoot,
					content: classes.cardHeaderContent,
					action: classes.cardHeaderActions,
				}}
				title={
					<>
						{getHeaderIcon()}
						<LWTypography
							className={classnames(
								classes.title,
								type === types.DARK ? classes.titleDark : classes.titleLight,
							)}
							align={headerAlign}
							variant="h1"
							data-testid={lwCardTestIds.typography}
						>
							{title}
						</LWTypography>
						{headerLink && (
							<Box whiteSpace="nowrap">
								<LWLink to={headerLink.to} variant="body2" semiBold>
									{headerLink.text}
								</LWLink>
							</Box>
						)}
					</>
				}
				disableTypography
			/>
			<CardContent
				className={classnames({
					[classes.cardContent]: column,
					[classes.nolinks]: !links,
					[classes.cardContentBare]: noPadding,
					[classes.cardContentPadding]: !noPadding,
				})}
				data-testid={lwCardTestIds.content}
			>
				{typeof content === 'string' ? (
					<LWTypography
						className={classes.contentTypography}
						color="textPrimary"
						component="div"
					>
						{content}
					</LWTypography>
				) : (
					content
				)}
			</CardContent>
			{links && (
				<CardActions className={classes.cardActions}>
					<Box className={classes.linkWrapper} pt={1}>
						{links.map((link) => (
							<LWLink
								key={`${link.href}${link.text}`}
								to={link.href}
								eventOptions={link.eventOptions}
								onClick={link.onClick}
							>
								{link.text}
							</LWLink>
						))}
					</Box>
				</CardActions>
			)}
		</Card>
	);
};

LWCard.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	column: PropTypes.bool,
	grid: PropTypes.bool,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	/** One of DARK | LIGHT | ERROR */
	type: PropTypes.oneOf(Object.values(types)),
	links: PropTypes.arrayOf(
		PropTypes.shape({
			href: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
			eventOptions: PropTypes.object,
		}),
	),
	headerActions: PropTypes.array,
	headerAlign: PropTypes.oneOf(Object.values(headerAlignment)),
	headerIcon: PropTypes.node,
	headerLink: PropTypes.shape({
		to: PropTypes.string.isRequired,
		text: PropTypes.string,
	}),
	isLoading: PropTypes.bool,
	noPadding: PropTypes.bool,
};

export { LWCard, types, headerAlignment, lwCardTestIds };

export default withStyles(styles)(LWCard);
