import {
	takeEvery,
	call,
	put,
	take,
	takeLatest,
	select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { actions as zoneDeleteActions } from 'modules/api/network/dns/zone/deleteModule';
import {
	actions as bindActions,
	selectors as bindSelectors,
} from 'modules/api/network/dns/zone/formatAsBindModule';
import {
	selectors as zoneDetailsSelectors,
	actions as zoneDetailsActions,
} from 'modules/api/network/dns/zone/detailsModule';
import { actions as registrationDetailsActions } from 'modules/api/network/dns/domain/detailsModule';
import { actions as listAssignedActions } from 'modules/api/network/dns/domain/nameserver/listAssignedModule';
import { actions as dialogActions } from 'modules/dialogs';
import { actions as downloadActions } from 'modules/utility/download';
import dnsActions from '../actions';

function* fetchZoneDetails({ name }) {
	if (yield select(zoneDetailsSelectors.isLoading)) {
		const res = yield take([
			zoneDetailsActions.setType,
			zoneDetailsActions.errorType,
		]);
		if (res.type === zoneDetailsActions.errorType) return;
	}
	if (name === (yield select(zoneDetailsSelectors.getName))) return;
	yield put(zoneDetailsActions.clear());
	yield put(zoneDetailsActions.fetch({ name, alsowith: ['delegation'] }));
}

function* initAssignedNameServers({ name }) {
	yield put(registrationDetailsActions.fetch({ domain: name }));
	yield put(listAssignedActions.fetch({ domain: name }));
}

function* launchDeleteZoneModal({ domain }) {
	yield put(
		dialogActions.open({
			contentKey: 'DeleteDNSZoneDialogContents1',
			dialogProps: { domain },
		}),
	);
}

function* launchConfirmDeleteZoneModal({ domain }) {
	yield put(
		dialogActions.open({
			contentKey: 'DeleteDNSZoneDialogContents2',
			dialogProps: { domain },
		}),
	);
}

function* downloadZoneData({ name: nameArg }) {
	const name = nameArg || (yield select(zoneDetailsSelectors.getName));
	if (!(yield select(bindSelectors.hasData))) {
		// This will only happen if something went wrong in `init`.
		yield put(bindActions.fetch({ name }));
		const res = yield take([bindActions.setType, bindActions.errorType]);
		if (res.type === bindActions.errorType) return;
	}
	const data = yield select(bindSelectors.bindData); // init should called by DomainDNS.jsx before this can be triggered.
	yield put(downloadActions.downloadText({ data, name }));
}

function* deleteZone({ name: nameArg }) {
	const name = nameArg || (yield select(zoneDetailsSelectors.getName));
	yield put(zoneDeleteActions.fetch({ name }));
	yield put(dialogActions.close());
	yield put(push('/domain/dns'));
}

function* initDomainDNS({ name }) {
	yield put(bindActions.fetch({ name })); // prepare the data for export buttons (downloadZoneData).
	yield call(fetchZoneDetails, { name });
}

export { fetchZoneDetails };
export default function* dnsSagas() {
	yield takeEvery(
		dnsActions.DNS_LAUNCH_DELETE_ZONE_MODAL,
		launchDeleteZoneModal,
	);
	yield takeEvery(
		dnsActions.DNS_LAUNCH_CONFIRM_DELETE_ZONE_MODAL,
		launchConfirmDeleteZoneModal,
	);
	yield takeEvery(dnsActions.DNS_DOWNLOAD_ZONE_DATA, downloadZoneData);
	yield takeLatest(dnsActions.DNS_ZONE_FETCH_DETAILS, fetchZoneDetails);
	yield takeLatest(
		dnsActions.DNS_INIT_ASSIGNED_NAME_SERVERS,
		initAssignedNameServers,
	);
	yield takeLatest(dnsActions.DNS_DELETE_ZONE, deleteZone);
	yield takeLatest(dnsActions.DNS_DOMAIN_INIT, initDomainDNS);
}
