import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import LWCard, { types as lwCardTypes } from 'components/common/LWCard';
import LWTypography from 'components/common/LWTypography';
import ProgressBar from 'components/common/ProgressBar';

const styles = ({ spacing }) => ({
	root: {},
	item: {
		padding: spacing(),
	},
	label: {
		fontWeight: 500,
	},
});

const testids = {
	PrivateParentResources__Container: 'PrivateParentResources__Container',
};

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const PrivateParentResources = ({
	classes,
	parentDiskResources,
	parentMemoryResources,
}) => (
	<LWCard
		classes={{
			root: classes.root,
		}}
		title="Private Parent Resources"
		type={lwCardTypes.LIGHT}
		data-testid={testids.PrivateParentResources__Container}
	>
		<Grid container className={classes.item}>
			<Grid item xs={4} md={2}>
				<LWTypography variant="body1" className={classes.label}>
					Storage
				</LWTypography>
			</Grid>
			<Grid item xs={8} md={2}>
				<LWTypography variant="body1">
					{parentDiskResources &&
						`${parentDiskResources.used}GB of ${parentDiskResources.total}GB`}
				</LWTypography>
			</Grid>
			<Grid item xs={12} md={8}>
				<ProgressBar
					value={
						parentDiskResources
							? (parentDiskResources.used / parentDiskResources.total) * 100
							: 0
					}
				/>
			</Grid>
		</Grid>
		<Grid container className={classes.item}>
			<Grid item xs={4} md={2}>
				<LWTypography variant="body1" className={classes.label}>
					Memory
				</LWTypography>
			</Grid>
			<Grid item xs={8} md={2}>
				<LWTypography variant="body1">
					{parentMemoryResources &&
						`${parentMemoryResources?.used}MB of ${parentMemoryResources?.total}MB`}
				</LWTypography>
			</Grid>
			<Grid item xs={12} md={8}>
				<ProgressBar
					value={
						parentMemoryResources
							? (Number(parentMemoryResources?.used) /
									Number(parentMemoryResources?.total)) *
								100
							: 0
					}
				/>
			</Grid>
		</Grid>
	</LWCard>
);

PrivateParentResources.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrivateParentResources);

export { PrivateParentResources, testids };
