import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import Box from '@material-ui/core/Box';
import LWCard, { types as lwCardTypes } from 'components/common/LWCard';
import LWLink from 'components/common/LWLink';
import LWTypography from 'components/common/LWTypography';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const Networking = ({
	networkSummary,
	categories,
	isOpenStack,
	assetType: serverType,
	uniq_id: uniqId,
	capabilities,
	isBeyondHosting,
	featureDetails,
}) => {
	const bandwidth = featureDetails?.Bandwidth;
	const {
		primary_ip: primaryIp,
		private_ip: privateIp,
		total_ips: totalIps,
		firewall,
	} = networkSummary || {};
	const { pathname } = useLocation();
	let bandwidthDescription;
	if (bandwidth) {
		bandwidthDescription = get(bandwidth, 'description');
	}
	const renderFirewallType = ({ type, config } = {}) => {
		switch (type) {
			case null:
				return 'Disabled';
			case 'saved':
				return `Preset - ${config}`;
			default:
				return capitalize(type);
		}
	};

	const publicIpCopy = `Public IP${totalIps ? ` (${totalIps} total)` : ''}`;

	const getPrivateIp = () => ({
		hasBlock:
			privateIp ||
			capabilities?.autoPrivateNetworking ||
			capabilities?.editPrivateNetwork,
		hasLabel: privateIp,
		hasLink:
			capabilities?.autoPrivateNetworking || capabilities?.editPrivateNetwork,
	});

	const getBandwidth = () => ({
		hasBlock:
			bandwidthDescription ||
			categories.includes('NocworxOpenstack') ||
			capabilities?.changeBWOptions,
		hasLabel: bandwidthDescription,
		hasLink:
			categories.includes('NocworxOpenstack') || capabilities?.changeBWOptions,
	});

	return (
		<LWCard title="Networking" type={lwCardTypes.LIGHT} links={[]}>
			<Box display="flex" flexDirection="column" pb={2}>
				<LWTypography
					color="textSecondary"
					variant="caption"
					data-testid="Networking__PublicIpCopy"
				>
					{publicIpCopy}
				</LWTypography>
				{primaryIp && <LWTypography variant="body1">{primaryIp}</LWTypography>}
				{!!capabilities?.multiplePublicIPs && !isBeyondHosting && (
					<LWLink
						to={`${pathname}/ips/public`}
						data-testid="Networking__ManagePublicIps"
					>
						Manage Public IPs
					</LWLink>
				)}
			</Box>
			{getPrivateIp().hasBlock && (
				<Box display="flex" flexDirection="column" pb={2}>
					<LWTypography color="textSecondary" variant="caption">
						Private IP
					</LWTypography>
					{getPrivateIp().hasLabel && (
						<LWTypography variant="body1">{privateIp}</LWTypography>
					)}
					{getPrivateIp().hasLink && (
						<LWLink
							to={`/services/cloud-private-networks/${
								isOpenStack ? 'openstack' : 'storm'
							}`}
						>
							View Private Network
						</LWLink>
					)}
				</Box>
			)}
			{/* This is still using a category for Openstack since the changeBWOptions capability isn't applied to those servers */}
			{getBandwidth().hasBlock && (
				<Box display="flex" flexDirection="column" pb={2}>
					<LWTypography color="textSecondary" variant="caption">
						Bandwidth
					</LWTypography>
					{getBandwidth().hasLabel && (
						<LWTypography variant="body1">{bandwidthDescription}</LWTypography>
					)}
					{getBandwidth().hasLink && (
						<LWLink to={`/servers/${serverType}/${uniqId}/bandwidth/`}>
							Manage Bandwidth
						</LWLink>
					)}
				</Box>
			)}

			{(capabilities?.provFirewall || capabilities?.nocworxFirewall) && (
				<Box display="flex" flexDirection="column" pb={2}>
					<LWTypography color="textSecondary" variant="caption">
						Firewall
					</LWTypography>
					<LWTypography variant="body1">
						{renderFirewallType(firewall)}
					</LWTypography>
					<LWLink to={`/servers/${serverType}/${uniqId}/firewall/`}>
						Manage Firewall
					</LWLink>
				</Box>
			)}
		</LWCard>
	);
};

Networking.propTypes = {
	uniq_id: PropTypes.string.isRequired,
	isBeyondHosting: PropTypes.bool,
};

export default Networking;
