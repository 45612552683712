import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import routeActions from 'modules/route/actions';
import { cartItemAddSelectors } from 'modules/api/market/cart/item/addModule';
import {
	addToCartSaga,
	invalidateCart,
	modifyItemInCart,
} from 'modules/cart/sagas';
import { snackbarSagas } from 'modules/snackbar/sagas';
import contentKeys from 'components/common/Snackbar/contents/contentKeys';
import packageConfigActions from './actions';

function* handleAddPackageToCart({ cycle, item }) {
	const { error } = yield call(addToCartSaga, { cycle, item });

	if (error) {
		return;
	}

	yield put(routeActions.smartNavigate({ path: '/cart' }));
}

function* handleQuickAddToCart({ cycle, item, onFinally = () => {} }) {
	const { error } = yield call(addToCartSaga, { cycle, item });

	onFinally();

	if (error) {
		return { error };
	}

	const isAddingToCart = yield select(cartItemAddSelectors.isLoading);
	if (!isAddingToCart) {
		yield call(invalidateCart);
	}

	return {};
}

function* handleAdminQuickAddToCart({ cycle, item, onFinally }) {
	const { error } = yield call(handleQuickAddToCart, {
		cycle,
		item,
		onFinally,
	});

	if (!error) {
		yield call(snackbarSagas, {
			successContentKey: contentKeys.StyledTextEm,
			successContentProps: {
				emText: item.code,
				afterText: 'was added to cart',
				emTextProps: {
					bold: true,
					variant: 'body2',
				},
				textProps: { variant: 'body2' },
			},
		});
	}
}

function* handleSavePackageChanges({ itemUuid, cycle, item }) {
	const { error } = yield call(modifyItemInCart, { cycle, item, itemUuid });

	if (error) {
		return;
	}

	yield put(routeActions.smartNavigate({ path: '/cart' }));
}

export default function* packageConfigSagas() {
	yield takeLatest(
		[packageConfigActions.PACKAGE_CONFIG_ADD_TO_CART],
		handleAddPackageToCart,
	);

	yield takeLatest(
		[packageConfigActions.PACKAGE_CONFIG_SAVE_CHANGES],
		handleSavePackageChanges,
	);

	yield takeEvery(
		packageConfigActions.PACKAGE_CONFIG_QUICK_ADD_TO_CART,
		handleQuickAddToCart,
	);

	yield takeEvery(
		packageConfigActions.PACKAGE_CONFIG_ADMIN_QUICK_ADD_TO_CART,
		handleAdminQuickAddToCart,
	);
}
