// This file is separate for readability only. It is not meant to be reusable.

import React from 'react';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';

// These boxes only display the left and right borders to make the lines.
// Disabled ones and filled with grey. White borders cover the main border
const Marks = ({ data, max, containerWidth, firstDisabled }) => {
	const theme = useTheme();

	// With default browser setting, this will be two px. Abstracted to a variable for readability.
	const two = theme.spacing(0.25).toString();
	return (
		data
			?.filter(({ value }) => value !== max)
			?.map(({ value, disabled }, idx) => {
				const nextValue = data[idx + 1]?.value || max;
				const prevDisabled = data[idx - 1] ? data[idx - 1].disabled : false;
				const nextDisabled = data[idx + 1] ? data[idx + 1]?.disabled : false;
				const last = data.length - 1 === idx;
				const first = idx === 0;

				const borderRadius = (() => {
					if (last) {
						return '0px 8px 8px 0px';
					}
					if (first && value === 0) {
						return '8px 0px 0px 8px';
					}
					return '';
				})();
				return (
					<Box
						key={value}
						position="absolute"
						height={theme.spacing(disabled ? 3.5 : 3)} // disabled marks should cover the parent outline.
						borderColor="primary.faint"
						borderLeft={`solid ${
							(prevDisabled && disabled) || value === 0 ? '0' : two
						}px ${theme.palette.primary.faint}`} // Makes a line at start of mark.
						borderRadius={borderRadius}
						left={`${(value / max) * containerWidth}px`}
						top={disabled ? '0px' : `${two}px`}
						width={`${
							((nextValue - value) / max) * containerWidth +
							(last ? theme.spacing(firstDisabled ? 1 : 1.25) : 0)
						}px`} // Covers the right border if last one. Not sure why firstDisabled is needed, but it matters.
						bgcolor={disabled ? 'grey.disabled' : 'inherit'}
					>
						{/* Using another box inside with a white outline since the parent's outline is already doing something. */}
						{disabled && (
							<Box
								height={theme.spacing(3.5)}
								borderColor="common.white"
								border={`solid ${two}px`}
								borderLeft="0px"
								borderRight={nextDisabled ? '' : '0px'}
								borderRadius={borderRadius}
							/>
						)}
					</Box>
				);
			}) || null
	);
};

export default Marks;
