import { call } from 'redux-saga/effects';

//
/**
 * proxy to axios call. Handles standard actions from bad status codes, and errors in general.
 * @param {object} axiosData - The object passed along to axios
 * @returns {(object)} - Returns keys request (full axios request) and data on good response,
 * returns error on bad response
 */
const flexSaga = (client) =>
	function* sendApiRequest(axiosData) {
		const axios = yield call(client);
		try {
			const response = yield call(axios, axiosData);
			return { response, data: response.data };
		} catch (error) {
			const { response, request } = error;
			if (response) {
				// server sent back bad status code
				if (response.error) {
					// TODO: how to handle these errors, maybe just ignore them
				}
				if (response.maintenance) {
					// TODO: implement maintenance screen redirect
				}
			} else if (request) {
				// TODO: handle no response recieved from server
			} else {
				// TODO: handle developer error
			}
			return { error };
		}
	};

export default flexSaga;
