import { call, put, takeLatest } from 'redux-saga/effects';
import { resetBasket } from 'modules/basket/rootSagas';
import sideSheetActions from 'modules/sideSheet/actions';
import cartActions from './actions';

function* handleCartAbandon({ onAbandoned = () => {} }) {
	// put the app in a loading state until basket has been reset
	yield put(sideSheetActions.close());
	yield put(cartActions.uninitialized());
	const didResetCart = Boolean(yield call(resetBasket));
	yield put(cartActions.initialized());

	if (didResetCart) {
		onAbandoned();
	}
}

export default function* watchCartAbandonSagas() {
	yield takeLatest(cartActions.CART_ABANDON, handleCartAbandon);
}
