// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/asset/index.raw').LWApiPublicAssetPasswordParamsRawI} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
const useAssetPassword = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['asset/password', params],
		queryFn: () => api.asset.apiRaw.password(params),
		enabled,
	});

export default useAssetPassword;
