import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.account.auth.twoFactor.enable;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_AUTH_TWOFACTOR_ENABLE',
	url: '/account/auth/twofactor/enable.json',
});

const selectors = {
	enabled: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'enabled']),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
