import {
	put,
	select,
	call,
	takeLatest,
	all,
	take,
	takeEvery,
} from 'redux-saga/effects';

import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import { cartItemAddSelectors } from 'modules/api/market/cart/item/addModule';
import { takeSetOrError } from 'utility/redux/apiModuleHelpers';
import {
	selectors as zoneListSelectors,
	actions as zoneListActions,
} from 'modules/api/network/zone/listModule';
import cartSelectors from 'modules/cart/selectors';
import cartActions from 'modules/cart/actions';
import routeActions from 'modules/route/actions';
import {
	addToCartSaga,
	invalidateCart,
	modifyItemInCart,
} from 'modules/cart/sagas';
import snackbarSaga from 'modules/snackbar/sagas/sagas';
import contentKeys from 'components/common/Snackbar/contents/contentKeys';

import {
	initializeFlatDetails,
	initOptionsFromBasket,
	handleCascadeUpdateOption,
	updateOptionActiveStates,
	resetOptionsToDefaults,
	handleChangeOS,
	initZone,
	handleAclSnackBar,
} from './flatDetailsSagas';

import {
	initDeployOnto,
	handleOnChangeDeployOnto,
	handleSetDeployOnto,
} from './deployOntoSagas';
import configActions from './actions';

import productConfigSelectors from './selectors';

function* handlePatchSelectedRegion({ productKey }) {
	yield call(initZone, productKey);

	yield call(updateOptionActiveStates, { productKey });
}

/**
 * Fetches network/zone/list if needed. Returns true if it does not need to make the call or if the call succeeds
 */
function* initNetworkZoneList() {
	const hasInit = yield select(zoneListSelectors.hasData);

	if (!hasInit) {
		yield put(zoneListActions.init());
		const res = yield call(takeSetOrError, {
			actions: zoneListActions,
			selectors: zoneListSelectors,
		});

		return res.type === zoneListActions.setType;
	}

	return true;
}

/**
 * Waits for the pending basket/details call to complete, a then initializes the given item from the basket
 * @param {Object} param0
 * @param {string} param0.uuid - The productKey to be initialized
 * @returns {boolean}
 */
function* waitAndInitFromBasket({ uuid }) {
	const basketIsInitialized = yield select(cartSelectors.getCartIsInitialized);

	if (!basketIsInitialized) {
		yield take(cartActions.CART_INITIALIZED);
	}

	if (uuid) {
		return yield call(initOptionsFromBasket, { uuid });
	}

	return true;
}

/**
 * Initializes a product for configuration. If passed a uuid, will initialize that item with its already selected configurations
 * @param {object} param0
 * @param {string} param0.productCode
 * @param {string} [param0.uuid] - an item uuid
 * @param {boolean} [param0.quickAdd] - is this initializing due to a quickAdd action?
 * @param {boolean} [param0.skipReset] - if true, the SelectedProductOptions will not reset to defaults.
 * @returns {boolean} true if all init processes succeed
 */
function* handleInitialize({ productCode, uuid, quickAdd, skipReset }) {
	yield put(configActions.setGroupsExpanded(false)); // Collapse panels by default. (first panel opened)
	yield put(
		configActions.setActiveProductKey({ productKey: uuid || productCode }),
	);

	// These must be completed before continuing, but are not dependant upon each other.
	const [networkSuccess, flatDetailsSuccess] = yield all([
		call(initNetworkZoneList),
		call(initializeFlatDetails, { productCode, quickAdd, skipReset }),
	]);

	// flatDetails must be completed before any item can be initialized
	const initOptionsSuccess = yield call(waitAndInitFromBasket, { uuid });

	// This should be called after basket/details is sure to be finished.
	yield call(initDeployOnto);

	return networkSuccess && flatDetailsSuccess && initOptionsSuccess;
}

/**
 * reverts a basket item's selected product options and selected region to its initial state
 * @param {object} param0
 * @param {string} param0.uuid - an item uuid
 */
function* handleRevertChanges({ uuid }) {
	yield call(initOptionsFromBasket, { uuid });

	const region = (yield select(cartDetailsSelectors.getItemsByUuid))[uuid]
		?.details?.region_id;
	yield put(
		configActions.patchSelectedProductRegions({ productKey: uuid, region }),
	);
}

/**
 * gets the selected region of the item or product
 * @param {string} productKey an item uuid or product code
 */
export function* retrieveRegion(productKey) {
	const selectedProductRegions = yield select(
		productConfigSelectors.getAllSelectedProductRegions,
	);
	return selectedProductRegions[productKey];
}

/**
 * constructs array of item's or product's configs in the shape required to be sent to the backend
 * @param {string} productKey an item uuid or product code
 */
export function* retrieveConfigs(productKey) {
	const productCode = (yield select(
		productConfigSelectors.getProductKeyToCodeMap,
	))[productKey];
	const selectedProductOptions = yield select(
		productConfigSelectors.getSelectedProductOptions,
	);

	if (!selectedProductOptions[productKey]) {
		return undefined;
	}

	const ptokToLocalDetails = (yield select(
		productConfigSelectors.getProductKeyToPtokToLocalDetails,
	))[productKey];

	const ptokToIsRevealed = (yield select(
		productConfigSelectors.getProductKeyToPtokToIsRevealed,
	))[productKey];
	const ptokToIsHidden = yield select(productConfigSelectors.getPtokToIsHidden);
	const ptokToPtokDetails = (yield select(
		productConfigSelectors.getProductsData,
	))[productCode].ptokData;

	return Object.entries(selectedProductOptions[productKey])
		.filter(([ptokId, optionValue]) => {
			const isRevealed = ptokToIsRevealed?.[ptokId];
			const isHidden = ptokToIsHidden[ptokId];
			const hasValueInBasket = ptokToLocalDetails?.[ptokId]?.hasValueInBasket;

			if (isHidden && !isRevealed && !hasValueInBasket) {
				return false;
			}
			return optionValue.active;
		})
		.map(([ptokId, optionValue]) => {
			const { parentPtokId, parentPtovId } = ptokToPtokDetails[ptokId];
			const { numUnits, value } = optionValue;

			return {
				parent_ptok_id: parentPtokId || null,
				parent_ptov_id: parentPtovId || null,
				ptok_id: Number(ptokId),
				ptov_id: value,
				num_units: typeof numUnits === 'number' ? numUnits : null,
			};
		});
}

/**
 * @param {object} param0
 * @param {string} param0.productCode */
function* handleAddToCart({ productCode }) {
	const options = yield call(retrieveConfigs, productCode);
	const regionId = yield call(retrieveRegion, productCode);
	const properties = (yield select(
		productConfigSelectors.getProductProperties,
	))[productCode];

	const item = { code: productCode, options, properties, region_id: regionId };
	yield call(addToCartSaga, { cycle: 'monthly', item });

	yield put(routeActions.smartNavigate({ path: '/cart' }));
}

/**
 * @param {object} param0
 * @param {string} param0.productCode
 * @param {object} param0.properties */
export function* handleQuickAddToCart({
	productCode,
	properties: propertiesArg,
}) {
	yield put(configActions.setQuickaddLoading({ productCode }));
	const initProductConfigSucceeds = yield call(handleInitialize, {
		productCode,
		quickAdd: true,
	}); // needs to be a call in order to hang on flat details fetch

	if (!initProductConfigSucceeds) {
		return {};
	}

	const options = yield call(retrieveConfigs, productCode);
	const regionId = yield call(retrieveRegion, productCode);
	const properties = (yield select(
		productConfigSelectors.getProductProperties,
	))[productCode];

	const item = {
		code: productCode,
		options,
		properties: { ...properties, ...propertiesArg },
		region_id: regionId,
	};
	const { error, newCart } = yield call(addToCartSaga, {
		cycle: 'monthly',
		item,
	});

	yield put(configActions.unsetQuickaddLoading({ productCode }));

	const isQuickAdding =
		(yield select(cartItemAddSelectors.isLoading)) ||
		(yield select(productConfigSelectors.isLoading));

	// since race conditions can result from quick adding multiple products at
	// once, invalidate the cart data to be certain that cart state is up to date
	if (!isQuickAdding) {
		yield call(invalidateCart);
	}

	return { error, newCart };
}

function* handleAdminQuickAddToCart({ productCode }) {
	const { error } = yield call(handleQuickAddToCart, { productCode });

	if (!error) {
		yield call(snackbarSaga, {
			successContentKey: contentKeys.StyledTextEm,
			successContentProps: {
				emText: productCode,
				afterText: 'was added to cart',
				emTextProps: {
					bold: true,
					variant: 'body2',
				},
				textProps: { variant: 'body2' },
			},
		});
	}
}

/**
 * @param {object} param0
 * @param {string} param0.productCode
 * @param {string} param0.uuid
 * */
function* handleModifyProduct({ uuid }) {
	const options = yield call(retrieveConfigs, uuid);
	const regionId = yield call(retrieveRegion, uuid);
	const properties = (yield select(
		productConfigSelectors.getProductProperties,
	))[uuid];
	const productCode = (yield select(
		productConfigSelectors.getProductKeyToCodeMap,
	))[uuid];

	const item = { code: productCode, options, properties, region_id: regionId };
	yield call(modifyItemInCart, { cycle: 'monthly', item, itemUuid: uuid });

	yield put(routeActions.smartNavigate({ path: '/cart' }));
}

export { handleInitialize };
export default function* productConfigSagas() {
	yield takeLatest([configActions.PRODUCT_CONFIG_INITIALIZE], handleInitialize);
	yield takeLatest(
		[configActions.SET_PRODUCT_CONFIG_DEPLOY_ONTO],
		handleSetDeployOnto,
	);
	yield takeLatest(
		[configActions.ON_CHANGE_DEPLOY_ONTO],
		handleOnChangeDeployOnto,
	);
	yield takeLatest(
		[configActions.PRODUCT_CONFIG_CASCADE_UPDATE_OPTION],
		handleCascadeUpdateOption,
	);
	yield takeLatest(
		[configActions.PRODUCT_CONFIG_PATCH_SELECTED_PRODUCT_REGIONS],
		handlePatchSelectedRegion,
	);
	yield takeLatest(
		[configActions.PRODUCT_CONFIG_REVERT_CHANGES],
		handleRevertChanges,
	);
	yield takeLatest(
		configActions.PRODUCT_CONFIG_RESET_OPTIONS,
		resetOptionsToDefaults,
	);
	yield takeLatest(configActions.PRODUCT_CONFIG_CHANGE_OS, handleChangeOS);
	// Taken by updateOptionActiveStates so that revealing hidden options can change what other non-hidden options could be available.
	yield takeLatest(
		configActions.PRODUCT_CONFIG_REVEAL_HIDDEN_OPTIONS,
		updateOptionActiveStates,
	);
	yield takeLatest(
		configActions.PRODUCT_CONFIG_ACL_SNACKBAR,
		handleAclSnackBar,
	);
	yield takeLatest(configActions.PRODUCT_CONFIG_ADD_TO_CART, handleAddToCart);
	yield takeEvery(
		configActions.PRODUCT_CONFIG_QUICK_ADD_TO_CART,
		handleQuickAddToCart,
	);
	yield takeEvery(
		configActions.PRODUCT_CONFIG_ADMIN_QUICK_ADD_TO_CART,
		handleAdminQuickAddToCart,
	);
	yield takeLatest(configActions.PRODUCT_CONFIG_MODIFY, handleModifyProduct);
}
