import { connect } from 'react-redux';
import actions from 'modules/statusSheet/actions';
import DismissButton from './DismissButton';

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		action: () => dispatch(actions.dismissNotification(ownProps.id)),
	};
};

export default connect(null, mapDispatchToProps)(DismissButton);
