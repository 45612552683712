import { connect } from 'react-redux';
import actions from 'modules/statusSheet/actions';
import { isOpen, pendingNotifications } from 'modules/statusSheet/selectors';
import StatusSheet from './StatusSheet';

const mapStateToProps = (state) => ({
	isOpen: isOpen(state),
	pendingNotifications: pendingNotifications(state),
});

const mapDispatchToProps = (dispatch) => ({
	closeSheet: () => dispatch(actions.close()),
	setHeight: (size) => dispatch(actions.setHeight(size)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusSheet);
