import { createSelector } from 'reselect';

const getStateSlice = (state) => state.unBlockIPDialog;

const isOpen = createSelector(getStateSlice, (slice) => slice.isOpen);

const serverDetails = createSelector(
	getStateSlice,
	(slice) => slice.serverDetails,
);

const selectors = { isOpen, serverDetails };

export default selectors;
