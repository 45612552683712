import { takeEvery, put, select, delay, call } from 'redux-saga/effects';
import selectors from './selectors';
import actions from './actions';

// action here is expected to be an already created action.
function* dispatchThrottledDispatch({ action, key = 'default' }) {
	// Check to see if a new action has come in since this one was scheduled.
	const targetTime = (yield select(selectors.getStateSlice))[
		`${action.type}.${key}`
	]?.targetTime;

	if (!targetTime) {
		// eslint-disable-next-line no-console
		console.error(
			`No target time was associated with action, ${action.type}`,
			action,
		);
		return;
	}
	const theTimeHasCome = Date.now() >= targetTime;
	if (theTimeHasCome) {
		yield put(action);
		yield put(actions.removeThrottledDispatch({ action, key }));
	} // If false, that means a new action was scheduled under the same type and key.
}

function* throttleDispatch({ action, ms, key }) {
	// If in the test environment, only wait 1 ms. This allows waitAndMeasure to change the time before this executes.
	yield delay(process.env.NODE_ENV === 'test' ? 0 : ms);
	yield call(dispatchThrottledDispatch, { action, key });
}

export default function* throttleDispatchSagas() {
	yield takeEvery(actions.UTILITY_THROTTLE_DISPATCH, throttleDispatch);
}
