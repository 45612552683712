import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LWHorizontalCard from 'components/common/LWHorizontalCard';
import LWTypography from 'components/common/LWTypography';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import displayTypes from 'utility/constants/displayTypes';

const styles = {
	root: {},
	card: {
		margin: '0.5em',
	},
};

const customErrorMessage = (verb) => `Failed to ${verb}.`;

const GenericError = ({
	classes,
	errorText = 'An error has occurred processing your request. Please retry. If the error persists, please contact support.',
	children = null,
	verb = null,
	shown = false,
	onClose,
}) => {
	return !shown ? null : (
		<LWHorizontalCard
			classes={{ root: classes.card }}
			type={displayTypes.ERROR}
			rightChildren={
				onClose && (
					<div>
						<IconButton onClick={onClose} className={classes.closeIconButton}>
							<Close />
						</IconButton>
					</div>
				)
			}
		>
			{verb ? (
				<>
					<LWTypography>{customErrorMessage(verb)}</LWTypography>
					<LWTypography>
						Please retry and if the error persists, contact support.
					</LWTypography>
				</>
			) : (
				<LWTypography>{children || errorText}</LWTypography>
			)}
		</LWHorizontalCard>
	);
};

GenericError.propTypes = {
	children: PropTypes.node,
	classes: PropTypes.object.isRequired,
	errorText: PropTypes.string,
	verb: PropTypes.string,
	shown: PropTypes.bool,
};

export { GenericError };

export default withStyles(styles)(GenericError);
