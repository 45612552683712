import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import get from 'lodash/get';

import { roles } from 'utility/constants/roles';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';

const User = lazy(() => import('containers/pages/account/users/User'));
const TwoFactor = lazy(
	() => import('containers/pages/account/twoFactor/TwoFactor'),
);

const AddUser = lazy(() => import('containers/pages/account/users/AddUser'));

const InvoiceDetails = lazy(
	() => import('containers/pages/billing/InvoiceDetails'),
);

const NotFound = lazy(() => import('pages/error/NotFound'));

const AccountRouter = (props) => {
	const { navData } = props;
	const subNavs = get(navData, 'subNav');
	return (
		<Switch>
			<RoleRestrictedRoute
				exact
				path={get(navData, 'path')}
				component={get(navData, 'component')}
			/>
			<RoleRestrictedRoute
				exact
				path={`${get(navData, 'path')}/billing/invoice/:invoiceId`}
				component={InvoiceDetails}
				whitelistedRoles={[
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.SUSPENDED_OWNER,
					roles.PURCHASER,
				]}
			/>
			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/users/:username/authentication`}
				component={TwoFactor}
			/>
			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/users/:username`}
				component={User}
			/>
			<RoleRestrictedRoute
				path={`${get(navData, 'path')}/add-user/`}
				component={AddUser}
			/>
			{Object.values(subNavs).map((subNavData) => {
				const whitelistedRoles = get(subNavData, 'whitelistedRoles') || [];
				return (
					<RoleRestrictedRoute
						key={get(subNavData, 'path')}
						path={get(subNavData, 'path')}
						component={get(subNavData, 'component')}
						whitelistedRoles={whitelistedRoles}
						exact
					/>
				);
			})}
			<Route component={NotFound} />
		</Switch>
	);
};

AccountRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};
const ACCOUNT_CREATE_PATH = '/account/create';

export { ACCOUNT_CREATE_PATH };

export default AccountRouter;
