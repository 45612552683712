import React from 'react';
import PropTypes from 'prop-types';
import DataCard from 'components/common/DataCard';
import LWTypography from 'components/common/LWTypography';

const AddressDataCard = ({ title = 'Address', addressData }) => {
	const {
		address,
		address2,
		city,
		state,
		postal_code: postalCode,
		country,
	} = addressData || {};

	return (
		<DataCard>
			<LWTypography BoxProps={{ paddingBottom: 2 }} bold>
				{title}
			</LWTypography>
			<LWTypography variant="body1">{address}</LWTypography>
			{address2 && <LWTypography variant="body1">{address2}</LWTypography>}
			<LWTypography variant="body1">
				{city}
				{', '}
				{state}
				{'  '}
				{postalCode}
			</LWTypography>
			<LWTypography variant="body1">{country}</LWTypography>
		</DataCard>
	);
};

AddressDataCard.propTypes = {
	title: PropTypes.string,
	addressData: PropTypes.shape({
		address: PropTypes.string,
		address2: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string,
		postal_code: PropTypes.string,
		state: PropTypes.string,
	}),
};

export default AddressDataCard;
