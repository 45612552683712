const getQuotaPricePath = (plan) => {
	switch (plan) {
		case 'CloudDaily':
		case 'Daily':
			return 'BackupStorage';
		case 'Quota':
			return 'BackupQuota';
		default:
			return 'LiquidWebBackupPlan';
	}
};

// eslint-disable-next-line import/prefer-default-export
export { getQuotaPricePath };
