import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { roles } from 'utility/constants/roles';
import { withRouter } from 'react-router';
import chatActions from 'modules/chat/actions';
import { connect } from 'react-redux';

const ChatRouter = ({ history, startChat }) => {
	// Opens chat and redirects user to home
	useEffect(() => {
		startChat();
		history.push('/');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Switch>
			<RoleRestrictedRoute
				exact
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				path="/chat/"
				component={() => <div />}
			/>
		</Switch>
	);
};

const mapDispatchToProps = (dispatch) => ({
	startChat: () => dispatch(chatActions.startChat()),
});

export default connect(null, mapDispatchToProps)(withRouter(ChatRouter));
