import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import {
	findOption,
	findOptionValue,
	optionValues,
	getOptionPrice,
	getPrice,
} from 'utility/products/options';
import { perMonth } from 'utility/format';
import { acronisDestinationOptionsMap } from 'utility/acronisProductMap';
import { getQuotaPricePath } from 'utility/redux/selectorHelperFunctions/backups';
import { selectors as varietyProductOptionsSelectors } from '../../licensing/varietyProductOptionsModule';

const getStateSlice = (state) => state.api.product.details;

const moduleKeys = {
	ACRONIS: 'ACRONIS',
	templates: 'TEMPLATES',
	addons: 'ADDONS',
};

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'PRODUCT_DETAILS',
	url: '/product/details.json',
});

const acronisSelectors = {
	...createSelectors(getStateSlice, false, moduleKeys.ACRONIS, {
		code: (slice) => slice?.code,
	}),
};

const getPrices = createSelector(
	defaultSelectors.getStateSlice,
	(slice) => slice?.getIn(['data', 'prices'])?.toJS() || null,
);

const getBlockStoragePrices = createSelector(
	defaultSelectors.getStateSlice,
	(slice) =>
		slice?.getIn(['data', 'options', 0, 'values', 0, 'prices'])?.toJS() || null,
);

const getAcronisPrices = createSelector(
	acronisSelectors.getNativeState,
	(slice) => slice?.data?.prices || null,
);

const getAcronisBasePrice = createSelector(
	getAcronisPrices,
	assetDetailsSelectors.regionId,
	(acronisPrices, regionId) => acronisPrices?.[regionId]?.month,
);

const options = createSelector(defaultSelectors.getStateSlice, (slice) =>
	slice.getIn(['data', 'options'], ImmutableList()),
);

const nativeOptions = createSelector(
	defaultSelectors.getNativeData,
	(data) => data?.options,
);

const imagePricing = createSelector(
	nativeOptions,
	assetDetailsSelectors.regionId,
	(options_, region) =>
		getOptionPrice({
			key: 'ImageStorage',
			value: '1GBMO',
			region,
			productOptions: options_,
		}),
);

const controlPanel = createSelector(options, (slice) =>
	slice ? slice.find((option) => option.get('key') === 'ControlPanel') : null,
);
const cPanel = createSelector(controlPanel, (slice) =>
	slice
		? slice
				.get('values')
				.find((value) => value.get('value').startsWith('cPanel'))
		: null,
);

const cPanelLicenseTier = createSelector(
	cPanel,
	options,
	(cPanelSlice, optionsSlice) => {
		const slice = cPanelSlice ? cPanelSlice.get('options') : optionsSlice;
		if (slice.isEmpty()) return null;

		return slice
			.find((option) => option.get('key') === 'cPanelLicenseTier')
			.get('values')
			.toJS();
	},
);

const getAcronisProductCode = createSelector(
	acronisSelectors.getNativeState,
	(slice) => slice?.data?.code,
);

// TODO: Move this to ./acronisSelecters
const acronisDestinationOptions = createSelector(
	getAcronisProductCode,
	(acronisProductCode) => acronisDestinationOptionsMap(acronisProductCode),
);

const cPanelLicensePrices = createSelector(
	cPanelLicenseTier,
	varietyProductOptionsSelectors.cPanel,
	(cPanelLicenseTierOptions, varietyProductOptions) => {
		if (varietyProductOptions && cPanelLicenseTierOptions) {
			return cPanelLicenseTierOptions.map((cPanelLicenseTierValue) => {
				let value;
				Object.values(varietyProductOptions).forEach((varietyProductOption) => {
					if (cPanelLicenseTierValue.value === varietyProductOption) {
						value = cPanelLicenseTierValue;
					}
				});
				return value;
			});
		}
		return null;
	},
);

const sslRenewal = createSelector(options, (slice) =>
	slice.find((option) => option.get('key') === 'SSLCertificate'),
);

const sslTermination = createSelector(
	options,
	(slice) =>
		slice.find(
			(option) => option.get('key') === 'LoadBalancerSSLTermination',
		) &&
		slice
			.find((option) => option.get('key') === 'LoadBalancerSSLTermination')
			.toJS(),
);

const loadBalancerNode = createSelector(
	options,
	(slice) =>
		slice.find((option) => option.get('key') === 'LoadBalancerNode') &&
		slice.find((option) => option.get('key') === 'LoadBalancerNode').toJS(),
);

const liquidWebBackupPlan = createSelector(
	defaultSelectors.getNativeState,
	(slice) => optionValues('LiquidWebBackupPlan', slice?.data?.options),
);

const backupsDisplayMap = {
	None: () => 'No Daily Backups',
	CloudDaily: ({ price }) => `Pay per GB (${perMonth(price)} per GB)`,
	Daily: ({ price }) => `Pay per GB (${perMonth(price)} per GB)`,
	Quota: () => 'Quota Pricing',
	Acronis: () => 'Acronis Backups',
};

const quotaPlanOptions = createSelector(liquidWebBackupPlan, (lwbp) => {
	const quota = findOptionValue('Quota', lwbp);
	return (
		optionValues('BackupQuota', quota?.options)
			?.sort((a, b) => a.display_order - b.display_order)
			.map((option) => {
				const price = getPrice(option)?.month;
				return {
					price,
					displayValue: option?.description,
					value: option.value,
					data: price,
				};
			}) || []
	);
});

const backupPlanOptionsFn = (backupPlans, backupsState) => {
	const noBackupOption = [{ value: 'None', label: backupsDisplayMap.None() }];
	const legacyBackupPlans =
		backupPlans?.map(({ value }) => {
			const path = getQuotaPricePath(value);
			const daily =
				findOptionValue('Daily', backupPlans)?.options ||
				findOptionValue('CloudDaily', backupPlans)?.options;
			const option = findOption(path, daily);
			const price = option?.values[0]?.prices['1']?.month;
			return {
				value,
				label: backupsDisplayMap[value]?.({ price }), // TODO: get region
			};
		}) || [];
	const currentLegacyBackupPlan = legacyBackupPlans?.filter(
		({ value }) => value === backupsState.currentPlan,
	);
	const acronisBackupOption = [
		{ value: 'Acronis', label: backupsDisplayMap.Acronis() },
	];

	if (!backupsState.canAcronis) {
		// Unable to use Acronis, need to show all legacy options
		// legacyBackupPlans includes the "None" option
		return legacyBackupPlans.length ? legacyBackupPlans : noBackupOption;
	}

	if (!backupsState.hasLegacyBackups) {
		// canAcronis and doesn't have legacy, can only use Acronis going forward
		return [...noBackupOption, ...acronisBackupOption];
	}

	// Everyone else would have a current legacy plan, let that be choosable, or they can switch to None or Acronis
	return [
		...noBackupOption,
		...currentLegacyBackupPlan,
		...acronisBackupOption,
	];
};

const sslRenewalOneYear = createSelector(
	sslRenewal,
	(slice) =>
		slice &&
		slice
			.get('values')
			.find(
				(value) => value.get('description') === '1 Year DomainSSL Certificate',
			),
);

const sslRenewalTwoYear = createSelector(
	sslRenewal,
	(slice) =>
		slice &&
		slice
			.get('values')
			.find(
				(value) => value.get('description') === '2 Year DomainSSL Certificate',
			),
);

// values are '1IP', '2IPs', or '3IPs'
const getIpPrice = (value, regionId) => {
	return createSelector(options, (slice) => {
		let price = optionValues('ExtraIp', slice.toJS());
		price = findOptionValue('PublicIPs', price);
		price = getOptionPrice({
			key: 'PublicIPAddresses',
			value,
			region: regionId,
			productOptions: price && price.options,
		});
		return price && price.month;
	});
};

const selectors = {
	getStateSlice,
	acronisSelectors,
	options,
	bhvmSizes: createSelector(options, (slice) => {
		if (slice) {
			const nocworx = slice.find((option) =>
				option ? option.get('key') === 'NocworxSKU' : null,
			);
			if (nocworx) return nocworx.get('values');
			return null;
		}
		return null;
	}),
	code: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'code']),
	),
	controlPanel,
	cPanel,
	sslTermination,
	cPanelLicenseTier,
	cPanelLicensePrices,
	loadBalancerNode,
	liquidWebBackupPlan,
	backupPlanOptionsFn,
	quotaPlanOptions,
	getAcronisProductCode,
	acronisDestinationOptions,
	sslRenewal,
	sslRenewalOneYearPrice: createSelector(
		sslRenewalOneYear,
		(slice) => slice && slice.get('prices').get('1').get('one-time'),
	),
	sslRenewalTwoYearPrice: createSelector(
		sslRenewalTwoYear,
		(slice) => slice && slice.get('prices').get('1').get('one-time'),
	),
	getPrices,
	getBlockStoragePrices,
	getAcronisPrices,
	getAcronisBasePrice,
	// Note, getIpPrice is a function that returns a selector, not the selector itself.
	// To use, pass in (('1IP' || '2IPs' || '3IPs'), region_id).
	// regionId should come from modules/api/asset/detailsModule.js.
	getIpPrice,
	imagePricing,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors, moduleKeys, getStateSlice };
