import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = ({ spacingPage }) => ({
	root: {
		display: 'block',
	},
	centered: {
		textAlign: 'center',
	},
	bold: {
		fontWeight: 'bold',
	},
	semiBold: {
		fontWeight: 600,
	},
	light: {
		fontWeight: 300,
	},
	inline: {
		display: 'inline',
	},
	padded: {
		padding: spacingPage,
	},
	strikeThrough: {
		textDecoration: 'line-through',
	},
	uppercase: {
		textTransform: 'uppercase',
	},
	lineBreaks: {
		whiteSpace: 'pre-line',
	},
});

const FTypography = ({ color, ...rest }) => {
	return <Typography {...rest} />;
};
// a styled component that is used only when color is passed in as a path in theme.js
const STypography = styled(FTypography)`
	color: ${(p) => get(p.theme, p.color)};
`;

const SSkeleton = styled(Skeleton)`
	${({ $inline }) =>
		$inline &&
		`
			display: inline;
		`}
`;

const LWTypography = ({
	bold = false,
	semiBold,
	light,
	BoxProps,
	classes,
	centered = false,
	children = null,
	color = 'textPrimary',
	component = 'span',
	hide = false,
	inline = false,
	padded,
	strikeThrough = false,
	uppercase,
	lineBreaks,
	isLoading,
	SkeletonProps = { width: '50px' },
	className,
	...rest
}) => {
	// if we pass in a path in theme.js, use the generated styled component
	const Component = useMemo(
		() => (color.startsWith('palette') ? STypography : Typography),
		[color],
	);

	const content = useMemo(
		() => (
			<Component
				className={classNames([
					{
						[classes.root]: true,
						[classes.centered]: centered,
						[classes.bold]: bold,
						[classes.semiBold]: semiBold,
						[classes.light]: light,
						[classes.inline]: inline,
						[classes.padded]: padded,
						[classes.strikeThrough]: strikeThrough,
						[classes.uppercase]: uppercase,
						[classes.lineBreaks]: lineBreaks,
					},
					className, // this will come in from styled-components if it's used to style the LWTypography component
				])}
				component={component}
				color={color}
				variant="body1"
				{...rest}
			>
				{isLoading ? (
					<SSkeleton variant="text" $inline={inline} {...SkeletonProps} />
				) : (
					children
				)}
			</Component>
		),
		[
			classes.root,
			classes.centered,
			classes.bold,
			classes.semiBold,
			classes.light,
			classes.inline,
			classes.padded,
			classes.strikeThrough,
			classes.uppercase,
			classes.lineBreaks,
			centered,
			bold,
			semiBold,
			light,
			inline,
			padded,
			strikeThrough,
			uppercase,
			lineBreaks,
			className,
			component,
			color,
			rest,
			isLoading,
			SkeletonProps,
			children,
		],
	);
	if (hide) return null;
	if (BoxProps) return <Box {...BoxProps}>{content}</Box>;
	return content;
};

LWTypography.propTypes = {
	classes: PropTypes.object.isRequired,
	bold: PropTypes.bool,
	semiBold: PropTypes.bool,
	light: PropTypes.bool,
	lineBreaks: PropTypes.bool,
	centered: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	color: PropTypes.string,
	component: PropTypes.string,
	hide: PropTypes.bool,
	inline: PropTypes.bool,
	strikeThrough: PropTypes.bool,
	SkeletonProps: PropTypes.shape({
		width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	}),
};

export { LWTypography };

export default withStyles(styles)(LWTypography);
