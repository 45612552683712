import { takeEvery, call } from 'redux-saga/effects';
import downloadActions from './actions';

function* downloadTextFile({ data, name }) {
	const link = document.createElement('a');
	yield call(
		[link, 'setAttribute'],
		'href',
		`data:text/plain;charset=utf-8,${encodeURIComponent(data)}`,
	);
	yield call([link, 'setAttribute'], 'download', `${name}.txt`);
	yield call([link, 'click']);
}

export default function* downloadSagas() {
	yield takeEvery(downloadActions.UTILITY_DOWNLOAD_TEXT_FILE, downloadTextFile);
}
