import { useSelector } from 'react-redux';
import { getIsStorm } from 'banana-stand/parsers';
import usePackageDetails from 'modules/queries/market/package/useDetails';
import cartSelectors from 'modules/cart/selectors';
import useNetworkZoneList from 'modules/queries/network/zone/useList';
import usePackageProductList from 'modules/queries/market/package/product/useList';
import useStormConfigList from 'modules/queries/storm/config/useList';
import useStormTemplateList from 'modules/queries/storm/template/useList';
import usePackageVersionId from './usePackageVersionId';

/**
 * @param {object} params
 * @param {string} params.packageCode
 * @param {string} [params.uuid] the uuid of the item (if it exists in the cart)
 */
const useConfigPackage = ({ packageCode, uuid }) => {
	const cartIsInitialized = useSelector(cartSelectors.getCartIsInitialized);
	const packageVersionId = usePackageVersionId(uuid);
	const packageQueriesEnabled = Boolean(!uuid || cartIsInitialized);

	const {
		data: packageDetailsData,
		error: packageDetailsError,
		isLoading: packageDetailsIsLoading,
	} = usePackageDetails(
		{
			code: packageCode,
			version_id: packageVersionId,
		},
		{ enabled: packageQueriesEnabled },
	);
	const { data: packageProductsData, isLoading: packageProductsIsLoading } =
		usePackageProductList(
			{
				code: packageCode,
				version_id: packageVersionId,
			},
			{ enabled: packageQueriesEnabled },
		);
	const { data: zonesData, isLoading: zonesIsLoading } = useNetworkZoneList();

	const baseIsLoading =
		packageDetailsIsLoading || packageProductsIsLoading || zonesIsLoading;

	const provisioningQueriesEnabled = Boolean(
		packageProductsData?.some(({ product_code: productCode }) =>
			getIsStorm(productCode),
		),
	);

	const { data: configsData, isLoading: configsIsLoading } = useStormConfigList(
		{
			available: 1,
			category: 'all',
			page_size: 999,
		},
		{ enabled: provisioningQueriesEnabled },
	);
	const { data: templatesData, isLoading: templatesIsLoading } =
		useStormTemplateList(
			{
				page_size: 999,
			},
			{ enabled: provisioningQueriesEnabled },
		);

	const provisioningIsLoading =
		provisioningQueriesEnabled && (configsIsLoading || templatesIsLoading);

	const isLoading = baseIsLoading || provisioningIsLoading;

	return {
		configsData,
		isLoading,
		packageDetailsData,
		packageDetailsError,
		packageProductsData,
		templatesData,
		zonesData,
	};
};

export default useConfigPackage;
