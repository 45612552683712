import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.api.network.dns.zone.summary;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_ZONE_SUMMARY',
	method: 'POST',
	url: '/network/dns/zone/summary.json',
});

const aRecords = createSelector(defaultSelectors.getStateSlice, (slice) =>
	slice.getIn(['data', 'records', 'a'], ImmutableList([])),
);

const selectors = {
	aRecords,
	master: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'master'], ''),
	),
	ipCount: createSelector(aRecords, (slice) => slice.size),
	nameServers: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'records', 'ns'], ImmutableList([])),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
