import { combineReducers } from 'redux';

import { reducer as create } from './createModule';
import { reducer as list } from './listModule';
import { reducer as update } from './updateModule';
import { reducer as validateAddress } from './validateAddressModule';
import { reducer as taxExemptReasons } from './taxExemptReasonsModule';

export default combineReducers({
	create,
	update,
	list,
	validateAddress,
	taxExemptReasons,
});
