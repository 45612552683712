import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

// Set the border radius to 0 on the corners where the border will appear
const borderRadius = (side) => {
	if (['top', 'bottom'].includes(side)) {
		return `
			border-${side}-left-radius: 0;
			border-${side}-right-radius: 0;
		`;
	}
	if (['right', 'left'].includes(side)) {
		return `
			border-top-${side}-radius: 0;
			border-bottom-${side}-radius: 0;
		`;
	}
	return ``;
};

const getBorderColor = (shade, theme) => {
	switch (shade) {
		case 'light':
			return theme.palette.common.white1;
		case 'dark':
			return theme.palette.text.secondary;
		case 'medium':
		default:
			return theme.palette.divider;
	}
};

const DividerBox = styled(Box)`
	${({ $between = true, $side = 'bottom', $shade = 'medium', theme }) =>
		`
			border-${$side}-width: ${$side === 'bottom' ? 2 : 1}px;
			border-${$side}-style: solid;
			border-${$side}-color: ${getBorderColor($shade, theme)};
			${borderRadius($side)}
			${
				$between &&
				`
				&:last-child {
					border-${$side}-style: none;
				}
			`
			}
		 `}
`;

DividerBox.propTypes = {
	$side: PropTypes.oneOf(['bottom', 'right']),
	$between: PropTypes.bool,
	$shade: PropTypes.oneOf(['light', 'medium', 'dark']),
};

export default DividerBox;
