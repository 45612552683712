import React from 'react';
import USFlag from 'images/flags/small/US Flag-1x.jpg';
import UKFlag from 'images/flags/small/UK Flag-1x.jpg';
import NetherlandsFlag from 'images/flags/small/Netherlands Flag-1x.jpg';
import AustraliaFlag from 'images/flags/small/Australia Flag-1x.jpg';

const DetermineCountryFlag = ({ country }) => {
	if (country)
		switch (country?.toUpperCase()) {
			case 'United States'.toUpperCase():
				return <img alt="us-flag" src={USFlag} />;
			case 'United Kingdom'.toUpperCase():
				return <img alt="uk-flag" src={UKFlag} />;
			case 'Netherlands'.toUpperCase():
				return <img alt="netherlands-flag" src={NetherlandsFlag} />;
			case 'Australia'.toUpperCase():
				return <img alt="australia-flag" src={AustraliaFlag} />;
			default:
				return <img alt="country-flag" />;
		}
	return null;
};

export default DetermineCountryFlag;
