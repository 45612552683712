import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import LWTypography from 'components/common/LWTypography';
import ProjectName from 'components/atoms/iconsWithText/ProjectName';
import LabeledServer from 'components/connectedMolecules/LabeledServer';
import OutlinedText from 'components/atoms/OutlinedText';
import LabeledIcon from 'components/atoms/LabeledIcon';

import randomNumberBetween from 'utility/randomNumberBetween';
import cloneToolTipText from 'containers/pages/servers/details/CloneServerModalContents/auxToolTipText';
import itemHelpers from 'utility/redux/selectorHelperFunctions/basketDetails/itemHelpers';
import HeaderIcon from './HeaderIcon';

const skeletonWidths = {
	title: '100px',
	hostnameOrSubtitle: '120px',
	secondaryInfo: `${randomNumberBetween(70, 80)}%`,
};

const SHeaderIcon = styled(HeaderIcon)`
	width: 1.5rem;
	height: 1.5rem;
	fill: ${({ theme }) => theme.palette.primary.main};
`;

const SecondaryInfo = ({ isLoading, children }) => (
	<LWTypography
		variant="body1"
		BoxProps={{
			marginY: 0.5,
		}}
		SkeletonProps={{ width: skeletonWidths.secondaryInfo }}
		isLoading={isLoading}
	>
		{children}
	</LWTypography>
);

const IndentedSecondaryInfo = ({ isLoading, children }) => (
	<LWTypography
		variant="body2"
		color="inherit"
		BoxProps={{
			color: 'text.secondary',
			ml: 2,
		}}
		SkeletonProps={{ width: skeletonWidths.secondaryInfo }}
		isLoading={isLoading}
	>
		{children}
	</LWTypography>
);

const Header = ({
	title = '',
	hostnameOrSubtitle = '',
	projectName = '',
	itemUuid,
	itemDetails,
	secondaryInfo = [],
	isLoading = false,
	hideDomain = false, // from drillToContent
	cloneFrom, // from drillTocontent
	variant, // from drillTocontent
	isNew, // from drillTocontent
	icon = null, // from drillTocontent
}) => {
	const cartOmitDomain = itemHelpers.getCartOmitDomain(itemDetails);

	return (
		<Box display="flex" flexDirection="column" flexGrow={1}>
			<Box my={0.5}>
				<Grid container alignItems="center" spacing={1}>
					<Grid item>
						<LabeledIcon
							display="flex"
							text={title}
							textProps={{ variant: 'subtitle1' }}
							icon={icon ? <SHeaderIcon icon={icon} /> : null}
						/>
					</Grid>
					{!isLoading && !!isNew && (
						<Grid item>
							<OutlinedText text="New!" color="primary.dark" />
						</Grid>
					)}
					{!(hideDomain || cartOmitDomain) && variant !== 'upsell' && (
						<Grid item>
							<LWTypography
								color="inherit"
								variant="subtitle2"
								BoxProps={{
									color: 'text.secondary',
									alignItems: 'center',
								}}
								SkeletonProps={{ width: skeletonWidths.hostnameOrSubtitle }}
								isLoading={isLoading}
							>
								{hostnameOrSubtitle}
							</LWTypography>
						</Grid>
					)}
					{projectName && (
						<Grid item>
							<ProjectName
								isLoading={isLoading}
								name={projectName}
								variant="secondary"
							/>
						</Grid>
					)}
					{cloneFrom && !isLoading && (
						<Grid item>
							<LabeledServer
								label="Clone from:"
								uniqId={cloneFrom}
								toolTipText={cloneToolTipText}
							/>
						</Grid>
					)}
				</Grid>
			</Box>
			{secondaryInfo.map((info) => {
				// Can't easily seperate this out, as there needs to be a key for the components, and it varies by the data type
				if (typeof info !== 'object')
					return (
						<SecondaryInfo isLoading={isLoading} key={info}>
							{info}
						</SecondaryInfo>
					);

				return (
					<React.Fragment key={info.value}>
						<SecondaryInfo isLoading={isLoading}>{info.value}</SecondaryInfo>
						{info.subitems &&
							info.subitems.map((item) => (
								<IndentedSecondaryInfo isLoading={isLoading} key={item}>
									{item}
								</IndentedSecondaryInfo>
							))}
					</React.Fragment>
				);
			})}
		</Box>
	);
};

Header.propTypes = {
	title: PropTypes.string,
	hostnameOrSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	projectName: PropTypes.string,
	itemUuid: PropTypes.string,
	secondaryInfo: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				value: PropTypes.string,
				subitems: PropTypes.arrayOf(PropTypes.string),
			}),
		]),
	),
	isLoading: PropTypes.bool,
	hideDomain: PropTypes.bool,
	hideConfigure: PropTypes.bool,
	removeItem: PropTypes.func.isRequired,
	/** instructions from parent for hiding item during removal. */
	setRemoved: PropTypes.func,
	/** Has item removal been attempted? */
	removed: PropTypes.bool,
	/** If the "New" highlight should display */
	isNew: PropTypes.bool,
};

export default Header;
