// @ts-check
import { useMutation } from '@tanstack/react-query';
import useSnackbarError from 'utility/hooks/useSnackbarError';
import api from 'modules/queries/api';
import useInvalidateAssets from 'modules/queries/invalidateQueries/useInvalidateAssets';

const useServerStart = () => {
	const snackbarError = useSnackbarError();
	const invalidateAssets = useInvalidateAssets();

	return useMutation({
		mutationFn:
			/** @param {import('club-sauce/public/server/power.raw').LWApiPublicServerPowerStartParamsRawI} params */
			(params) => api.server.power.apiRaw.start(params),
		onSuccess: (data, params) => {
			invalidateAssets(params.uniq_id);
		},
		onError: snackbarError,
	});
};

export default useServerStart;
