import Cookies from 'js-cookie';
import { call } from 'redux-saga/effects';
import { CART_UUID } from 'utility/constants/baskets';
import { cartMetadataAdditionalSagas } from 'modules/api/market/cart/metadataModule';
import mergeOldCartWithNew from './mergeOldCartWithNew';

function* assertMetadataSaga({ metadata, onError = () => {}, uuid: uuidArg }) {
	const uuid = uuidArg || Cookies.get(CART_UUID);

	const { data, error } = yield call(
		cartMetadataAdditionalSagas.fetchDirectly,
		{ data: metadata, uuid },
	);

	if (error) {
		onError(error);
		return { error };
	}

	yield call(mergeOldCartWithNew, { newCartPortion: data });

	return { error: false, errorMessage: null };
}

export default assertMetadataSaga;
