import { createSelector } from 'reselect';
import {
	isBasketAdmin as getIsBasketAdmin,
	isLoggedInAndNotBasketAdmin as getLoggedInNonAdmin, // TODO: change to this name in modules/auth/selectors
	isKeroOnly as getKeroOnly,
	isSetupUser as getIsSetupUser,
} from 'modules/auth';
import getConfigs, { navKeyArrays } from './configSelectors';

/**
 * @returns {array} the keys to the configs for the current user's navBar.
 * */
const getCurrentKeys = createSelector(
	getIsBasketAdmin,
	getLoggedInNonAdmin,
	getKeroOnly,
	getIsSetupUser,
	(isBasketAdmin, loggedInNonAdmin, keroOnly, isSetupUser) => {
		if (isBasketAdmin) {
			return navKeyArrays.admin;
		}
		if (isSetupUser || keroOnly) {
			return navKeyArrays.setup;
		}
		if (loggedInNonAdmin) {
			return navKeyArrays.authed;
		}
		return navKeyArrays.unauthed;
	},
);

/**
 * @returns {object} - an object decribing the navbar for the current user.
 */
const getUserConfigs = createSelector(
	getConfigs,
	getCurrentKeys,
	(configs, keys) => Object.fromEntries(keys.map((key) => [key, configs[key]])),
);

/**
 * @returns {object} - an object describing the parent navbar layer (buttons to hover over and click).
 */
const getParentConfigs = createSelector(getUserConfigs, (userConfigs) =>
	Object.fromEntries(
		Object.entries(userConfigs).filter((entry) => !entry[1].parent),
	),
);

/**
 * @returns {object} - returns an object with parent keys associated to their child configs.
 */
const getChildConfigs = createSelector(
	getParentConfigs,
	getUserConfigs,
	(parentConfigs, userConfigs) => {
		const parentConfigKeys = Object.keys(parentConfigs);
		return Object.fromEntries(
			parentConfigKeys.map((parentKey) => [
				parentKey,
				Object.values(userConfigs).filter(({ parent }) => parent === parentKey),
			]),
		);
	},
);

const getHasNotifications = createSelector(getUserConfigs, (userConfigs) => {
	return Object.values(userConfigs).some(
		({ notifications }) => notifications > 0,
	);
});

const navSelectors = {
	getChildConfigs,
	getCurrentKeys,
	getHasNotifications,
	getParentConfigs,
};

export default navSelectors;
