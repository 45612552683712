import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) =>
	state.api.network.dns.domain.transfer.cancelTransfer;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_DOMAIN_TRANSFER_CANCELTRANSFER',
	method: 'POST',
	url: '/network/dns/domain/transfer/cancelTransfer.json',
});

const selectors = {
	success: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'success']),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
