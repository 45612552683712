import { takeLatest, select, call } from 'redux-saga/effects';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import { cartMetadataAdditionalSagas } from 'modules/api/market/cart/metadataModule';
import snackbarSaga from 'modules/snackbar/sagas/sagas';
import { additionalSagas as cartAdminQuoteCreateAdditionalSagas } from 'modules/api/market/cart/admin/quote/createModule';
import { createCart as createCartSaga } from 'modules/cart/sagas';
import mergeOldCartWithNew from 'modules/cart/mergeOldCartWithNew';
import { cartDomainAdditionalSagas } from 'modules/api/market/cart/domainModule';
import { username as usernameSelector } from 'modules/auth/authSelectors';
import { getErrorStringHelper } from 'utility/redux/selectorHelperFunctions/apiModules';
import adminCartsActions from './actions';

function* handleAttachOpportunityId({ opportunityId }) {
	const uuid = yield select(cartDetailsSelectors.getUuid);
	const username = yield select(usernameSelector);

	const { data, error } = yield call(
		cartAdminQuoteCreateAdditionalSagas.fetchDirectly,
		{
			opportunity_id: opportunityId,
			cart_uuid: uuid,
			basket_admin_username: username,
		},
	);

	if (error) {
		yield call(snackbarSaga, {
			error: true,
			errorMessage: getErrorStringHelper(error),
		});
		return;
	}

	yield call(mergeOldCartWithNew, { newCartPortion: data });
}

/**
 * creates new salesforce quote, navigates user to marketplace with newly created quote as active basket
 */
function* handleCreateNewCart({
	opportunityId,
	name,
	description,
	domainToAutoAdd,
}) {
	const uuid = yield call(createCartSaga, opportunityId);

	if (!uuid) {
		return;
	}

	if (Boolean(name) || Boolean(description)) {
		const { data, error } = yield call(
			cartMetadataAdditionalSagas.fetchDirectly,
			{
				uuid,
				data: {
					name,
					description,
				},
			},
		);

		if (error) {
			yield call(snackbarSaga, {
				error,
				errorMessage: getErrorStringHelper(error),
			});
			return;
		}

		yield call(mergeOldCartWithNew, { newCartPortion: data });
	}

	if (domainToAutoAdd) {
		const { data, error } = yield call(
			cartDomainAdditionalSagas.fetchDirectly,
			{ uuid, domain: domainToAutoAdd, alsowith: ['metadata'] },
		);

		if (error) {
			yield call(snackbarSaga, {
				error,
				errorMessage: getErrorStringHelper(error),
			});
			return;
		}

		yield call(mergeOldCartWithNew, { newCartPortion: data });
	}

	yield call(snackbarSaga, {
		successMessage: 'Successfully created new cart.',
	});
}

export default function* adminCartsSagas() {
	yield takeLatest(
		adminCartsActions.ADMIN_CARTS_CREATE_NEW_CART,
		handleCreateNewCart,
	);
	yield takeLatest(
		adminCartsActions.ADMIN_CARTS_ATTACH_OPPORTUNITY_ID,
		handleAttachOpportunityId,
	);
}
