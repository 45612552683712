import accountAddressActions from './addressActions';

const initialState = {
	isValidateDialogOpen: false,
	address: {},
	submit: null,
	effect: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case accountAddressActions.ACCOUNT_ADDRESS_TOGGLE_DIALOG:
			return { ...state, isValidateDialogOpen: action.isValidateDialogOpen };
		case accountAddressActions.ACCOUNT_ADDRESS_SET_VALIDATE:
			return { ...state, address: action.address, submit: action.submit };
		case accountAddressActions.ACCOUNT_ADDRESS_SUBMIT_ORIGINAL:
			return { ...state, isValidateDialogOpen: false };
		case accountAddressActions.ACCOUNT_ADDRESS_SUBMIT_VALIDATED:
			return { ...state, isValidateDialogOpen: false };
		default:
			return state;
	}
};

export default reducer;
