import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'redux';

const connectAndWrap = ({
	mapStateToProps,
	mapDispatchToProps,
	form = 'testForm',
	enableReinitialize = true,
	onSubmit,
	...props
} = {}) =>
	compose(
		connect(mapStateToProps, mapDispatchToProps),
		reduxForm({
			form,
			enableReinitialize,
			onSubmit,
			...props,
		}),
	); // returns the function to which you pass the component.

export default connectAndWrap;
