import { createSelector } from 'reselect';

const getStateSlice = (state) => state.sideSheet;

const isOpen = createSelector(getStateSlice, (sidesheet) =>
	sidesheet.get('isOpen'),
);

const isLoading = createSelector(getStateSlice, (sidesheet) =>
	sidesheet.get('isLoading'),
);

const getTitle = createSelector(getStateSlice, (sidesheet) =>
	sidesheet.get('title'),
);

const getContentKey = createSelector(getStateSlice, (sidesheet) =>
	sidesheet.get('contentKey'),
);

const getContentProps = createSelector(getStateSlice, (sidesheet) =>
	sidesheet.get('contentProps'),
);

const getProductCode = createSelector(
	getContentProps,
	(contentProps) => contentProps?.productCode,
);

const cartPreviewOpen = createSelector(getStateSlice, (sidesheet) =>
	sidesheet.get('cartPreviewOpen'),
);

const marketplaceSidesheetIsOpen = createSelector(
	cartPreviewOpen,
	isOpen,
	(cartOpen, openInGeneral) => !cartOpen && openInGeneral,
);

// TODO: remove non-default exports.
export {
	isOpen,
	isLoading,
	getTitle,
	getContentKey,
	getContentProps,
	getProductCode,
	cartPreviewOpen,
};
const selectors = {
	isOpen,
	isLoading,
	getStateSlice,
	getTitle,
	getContentKey,
	getContentProps,
	getProductCode,
	cartPreviewOpen,
	marketplaceSidesheetIsOpen,
};

export default selectors;
