import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.api.network.firewall.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_FIREWALL_DETAILS',
	method: 'POST',
	url: '/network/firewall/details.json',
});

const selectors = {
	type: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'type'], ''),
	),
	rules: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'rules'], ImmutableList()),
	),
	allow: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'allow'], ImmutableList()),
	),
	ruleset: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'ruleset'], ''),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
