import { createSelector } from 'reselect';
import { selectors as routeSelectors } from 'modules/route';

const getStateSlice = (state) => state.server.list;

const getSort = createSelector(getStateSlice, (slice) => slice.sort);

const getOrderBy = createSelector(getSort, (sort) => ({
	field: 'domain',
	sort,
}));

const getPageSize = createSelector(getStateSlice, (slice) => slice.pageSize);

const getPageNum = createSelector(getStateSlice, (slice) => slice.pageNum);

const broadCategories = [
	'AutoDedicated',
	'StrictDedicated',
	'VirtualDedicated',
	'CloudSitesList',
	'CloudSitesListPlan',
	'ManagedApplication',
];

const getCategories = createSelector(
	getStateSlice,
	(slice) => (slice.categories.length ? slice.categories : broadCategories), // If no filters are active, use these broader categories to get everything.
);

const getSelectedCategories = createSelector(
	getStateSlice,
	(slice) => slice.categories,
);

const getSelectedProject = createSelector(
	getStateSlice,
	(slice) => slice.projectName,
);

const getSearchText = createSelector(
	getStateSlice,
	(slice) => slice.searchText || undefined, // don't pass an empty string to the back end.
);

const getDomainMatch = createSelector(getSearchText, (searchText) =>
	searchText ? 1 : undefined,
);

const headerKeys = {
	status: 'status',
	name: 'name',
	location: 'location',
	primaryIP: 'primaryIP',
	type: 'type',
	fileReplication: 'fileReplication',
	dBCluster: 'dBCluster',
	project: 'project',
};

const getHeaderKeys = createSelector(
	routeSelectors.getRootPathName,
	(rootPathName) => {
		switch (rootPathName.toLowerCase()) {
			case 'projects':
				return [
					headerKeys.status,
					headerKeys.name,
					headerKeys.primaryIP,
					headerKeys.type,
					headerKeys.fileReplication,
					headerKeys.dBCluster,
				];
			default:
				return [
					headerKeys.status,
					headerKeys.name,
					headerKeys.location,
					headerKeys.primaryIP,
					headerKeys.type,
					headerKeys.project,
				];
		}
	},
);

export { headerKeys, broadCategories };
const selectors = {
	getCategories,
	getDomainMatch,
	getHeaderKeys,
	getOrderBy,
	getPageNum,
	getPageSize,
	getSearchText,
	getSelectedCategories,
	getSelectedProject,
	getSort,
	getStateSlice,
};

export default selectors;
