import { createSelector } from 'reselect';

const getStateSlice = (state) => state.server.reboot;

const getIsDialogOpen = createSelector(
	getStateSlice,
	(reboot) => reboot.isDialogOpen,
);

const getDomain = createSelector(getStateSlice, (reboot) => reboot.domain);

const getUniqId = createSelector(getStateSlice, (reboot) => reboot.uniqId);

const getCanAutoReboot = createSelector(
	getStateSlice,
	(reboot) => reboot.canAutoReboot,
);

export {
	getStateSlice,
	getIsDialogOpen,
	getDomain,
	getUniqId,
	getCanAutoReboot,
};

const selectors = {
	getStateSlice,
	getIsDialogOpen,
	getDomain,
	getUniqId,
	getCanAutoReboot,
};

export default selectors;
