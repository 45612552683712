import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.vmware.vcloud.vdc.loginUrl;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'VMWARE_VCLOUD_VDC_LOGINURL',
	method: 'POST',
	url: '/vmware/vcloud/vdc/loginUrl.json',
});

const selectors = {
	getUrl: createSelector(defaultSelectors.getNativeData, (data) => data?.url),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
