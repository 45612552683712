import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Spinner from '@material-ui/core/CircularProgress';
import compose from 'utility/compose';
import { getIsStarting } from 'modules/chat/selectors';
import chatActions from 'modules/chat/actions';

export const styles = ({ palette, spacing }) => ({
	button: {
		'&&': {
			backgroundColor: palette.primary.main,
			textTransform: 'none',
			borderRadius: '360px',
			color: palette.secondary.contrastText,
			padding: `${spacing(1.5)}px ${spacing(3)}px`,
			fontSize: '18px',
			'&:hover': {
				background: palette.primary.main,
			},
		},
	},
	spinner: {
		marginLeft: '1em',
		color: palette.secondary.contrastText,
	},
});

const ChatButton = ({ classes, startChat, isStarting }) => (
	<Button onClick={startChat} className={classes.button}>
		Chat with us
		{isStarting && <Spinner size={18} className={classes.spinner} />}
	</Button>
);

ChatButton.propTypes = {
	classes: PropTypes.object.isRequired,
	startChat: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	isStarting: getIsStarting(state),
});

const mapDispatchToProps = (dispatch) => ({
	startChat: () => dispatch(chatActions.startChat()),
});

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(ChatButton);

export { ChatButton };
