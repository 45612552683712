import { call, put, select, takeLatest } from 'redux-saga/effects';
import { fromApiBoolean } from 'club-sauce/types/boolean';
import { domainPurchasePrivacyAdditionalSagas } from 'modules/api/network/dns/domain/purchasePrivacyModule';
import {
	selectors as domainDetailsSelectors,
	actions as domainDetailsActions,
} from 'modules/api/network/dns/domain/detailsModule';
import snackbarSaga from 'modules/snackbar/sagas/sagas';
import { getErrorStringHelper } from 'utility/redux/selectorHelperFunctions/apiModules';
import domainRegistrationActions from './actions';

function* handlePurchaseWHOIS({ uniqId }) {
	const { data, error } = yield call(
		domainPurchasePrivacyAdditionalSagas.fetchDirectly,
		{
			uniq_id: uniqId,
		},
	);

	if (error) {
		yield call(snackbarSaga, {
			error: true,
			errorMessage: getErrorStringHelper(error),
		});

		return;
	}

	if (!fromApiBoolean(data.success)) {
		yield call(snackbarSaga, {
			error: true,
			errorMessage:
				'Unable to add WHOIS privacy to domain. Please contact support if error persists',
		});

		return;
	}

	const domainDetails = yield select(domainDetailsSelectors.getNativeData);

	yield put(
		domainDetailsActions.override({
			...domainDetails,
			has_privacy: 1,
			privacy: 'On',
		}),
	);
}

export default function* domainRegistrationSagas() {
	yield takeLatest(
		domainRegistrationActions.DOMAIN_REGISTRATION_PURCHASE_WHOIS,
		handlePurchaseWHOIS,
	);
}
