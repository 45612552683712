import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import snackbarActions from 'modules/snackbar/snackbarActions';
import { selectors as targetPowerSelectors } from 'modules/server/targetPower';
import dialogActions from 'modules/dialogs/actions';
import { actions as taskSchedulerActions } from 'modules/server/taskScheduler';
import {
	isRebooting,
	isRunning,
	isShutdown,
	isShuttingDown,
	isStarting,
	POWER_ACTION,
} from 'utility/powerStatus';
import useServerStart from 'modules/queries/server/useStart';
import useServerShutdown from 'modules/queries/server/power/useShutdown';
import { SLWButton } from '../actionStyles';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const CloudActions = ({
	domain,
	uniq_id: uniqId,
	capabilities,
	status,
	powerStatus,
}) => {
	const dispatch = useDispatch();
	const { mutate: startServer, isLoading: startIsLoading } = useServerStart();
	const { mutate: shutdownServer, isLoading: shutdownIsLoading } =
		useServerShutdown();

	const handleConfirmStart = () => {
		startServer(
			{ uniq_id: uniqId },
			{
				onSuccess: () => {
					dispatch(snackbarActions.pushMessage({ message: 'Server started' }));
				},
			},
		);
	};

	const handleConfirmShutdown = () => {
		shutdownServer(
			{ uniq_id: uniqId },
			{
				onSuccess: () => {
					dispatch(snackbarActions.pushMessage({ message: 'Server shutdown' }));
				},
			},
		);
	};

	const canAutoReboot = !!capabilities?.autoReboot;

	const showReboot =
		Boolean(capabilities?.autoReboot) &&
		(isRunning(powerStatus) ||
			isShuttingDown(powerStatus) ||
			isRebooting(powerStatus));

	const showStart =
		Boolean(capabilities?.autoReboot) &&
		Boolean(capabilities?.uiCanStart) &&
		(isShutdown(powerStatus) || isStarting(powerStatus));

	const showShutdown =
		Boolean(capabilities?.autoShutdown) &&
		Boolean(capabilities?.uiCanShutdown) &&
		(isRunning(powerStatus) ||
			isShuttingDown(powerStatus) ||
			isRebooting(powerStatus));

	const targetPower = useSelector(targetPowerSelectors.getTargetPower);

	const targetPowerStatus = targetPower
		? targetPower[uniqId]?.status
		: undefined;

	const targetPowerAction = targetPower
		? targetPower[uniqId]?.action
		: undefined;

	const isPowerTransitioning = !targetPowerStatus
		? false
		: powerStatus !== targetPowerStatus;

	const openServerRebootDialog = () => {
		dispatch(
			taskSchedulerActions.openDialog({
				apiParams: { uniq_id: uniqId },
				hostname: domain,
				task: 'reboot',
				canAutoReboot,
			}),
		);
	};

	const openServerStartDialog = () => {
		dispatch(
			dialogActions.open({
				title: 'Confirm Start',
				contentKey: 'ServerStartDialogContents',
				contentProps: { uniqId, onConfirm: handleConfirmStart },
			}),
		);
	};

	const openServerShutdownDialog = () => {
		dispatch(
			dialogActions.open({
				title: 'Confirm Shutdown',
				contentKey: 'ServerShutdownDialogContents',
				contentProps: { uniqId, onConfirm: handleConfirmShutdown },
			}),
		);
	};

	return (
		<>
			{showReboot && (
				<SLWButton
					onClick={openServerRebootDialog}
					color="secondary"
					variant="outlined"
					isLoading={
						isRebooting(powerStatus) ||
						(targetPowerAction === POWER_ACTION.REBOOT && isPowerTransitioning)
					}
					disabled={
						status !== 'Active' ||
						isStarting(powerStatus) ||
						isPowerTransitioning
					}
				>
					Reboot
				</SLWButton>
			)}
			{showStart && (
				<SLWButton
					onClick={openServerStartDialog}
					color="secondary"
					variant="outlined"
					isLoading={
						startIsLoading ||
						isStarting(powerStatus) ||
						(targetPowerAction === POWER_ACTION.START && isPowerTransitioning)
					}
					disabled={
						isStarting(powerStatus) ||
						isRebooting(powerStatus) ||
						isPowerTransitioning
					}
				>
					Start
				</SLWButton>
			)}
			{showShutdown && (
				<SLWButton
					onClick={openServerShutdownDialog}
					color="secondary"
					variant="outlined"
					isLoading={
						shutdownIsLoading ||
						isShuttingDown(powerStatus) ||
						(targetPowerAction === POWER_ACTION.SHUTDOWN &&
							isPowerTransitioning)
					}
					disabled={
						isShuttingDown(powerStatus) ||
						isRebooting(powerStatus) ||
						isPowerTransitioning ||
						status !== 'Active'
					}
				>
					Shutdown
				</SLWButton>
			)}
		</>
	);
};

CloudActions.propTypes = {
	powerStatus: PropTypes.string,
};

export default CloudActions;
