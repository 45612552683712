import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import get from 'lodash/get';

const getStateSlice = (state) => state.api.network.ip.listPublic;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_IP_LISTPUBLIC',
	method: 'POST',
	url: '/network/ip/listPublic.json',
});

const selectors = {
	items: createSelector(defaultSelectors.getNativeState, (slice) =>
		get(slice, ['data', 'items'], []),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
