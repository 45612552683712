import { spawn, takeEvery, all, take, put, call } from 'redux-saga/effects';
import { actions as usertaskUpdateActions } from 'modules/api/usertask/updateModule';
import { actions as usertaskListActions } from 'modules/api/usertask/listModule';
import snackbarActions from 'modules/snackbar/snackbarActions';
import actions from './actions';

function* handleCancelSuccess({ uniq_id: uniqId }) {
	yield put(
		snackbarActions.pushMessage({
			message: 'Task successfully cancelled',
			variant: 'success',
		}),
	);

	yield put(usertaskListActions.clear());
	yield put(
		usertaskListActions.fetch({
			uniq_id: uniqId,
			status: 'pending',
			alsowith: ['action'],
		}),
	);
}

function* handleCancelSuccessScheduledTask() {
	yield put(
		snackbarActions.pushMessage({
			message: 'Task successfully cancelled',
			variant: 'success',
		}),
	);
	yield put(usertaskListActions.clear());
	yield put(
		usertaskListActions.fetch({
			alsowith: ['action', 'assetDetails'],
			order_by: { field: 'created_date' },
			page_size: 9999,
		}),
	);
}

function* handleCancelError() {
	yield put(
		snackbarActions.pushMessage({
			message: 'An error was encountered when cancelling a scheduled task.',
			variant: 'error',
		}),
	);
}

function* handleCancelTask({ payload: { code, taskTable } }) {
	const [{ type, payload }] = yield all([
		take([usertaskUpdateActions.setType, usertaskUpdateActions.errorType]),
		put(
			usertaskUpdateActions.fetch({
				code,
				status: 'cancelled',
			}),
		),
	]);

	yield put(actions.cancelTaskRequestFinished(code));

	if (type === usertaskUpdateActions.setType) {
		if (taskTable) {
			return yield call(handleCancelSuccessScheduledTask, payload);
		}
		return yield call(handleCancelSuccess, payload);
	}

	return yield call(handleCancelError, payload);
}

function* watchCancelTask() {
	yield takeEvery(actions.USERTASK_CANCEL_TASK, handleCancelTask);
	yield takeEvery(actions.USERTASK_SCHEDULEDTASK_CANCEL_TASK, handleCancelTask);
}

export default function* userTaskSagas() {
	yield spawn(watchCancelTask);
}
