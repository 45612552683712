import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.billing.paypal.agreement.createToken;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BILLING_PAYPAL_AGREEMENT_CREATETOKEN',
	url: '/billing/paypal/agreement/createToken.json',
});

const selectors = {
	approvalUrl: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'approval_url'], null),
	),
	token: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'token'], null),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
