import React from 'react';
import PropTypes from 'prop-types';
import LWDialog from 'components/common/LWDialog';

import ValidateContent from './ValidateContent';
import ValidateErrorContent from './ValidateErrorContent';

const ValidateAddressDialog = ({
	clearValidatedAddress,
	isLoading,
	isOpen,
	setIsOpen,
	submitValidated,
	submitOriginal,
	validatedAddress = null,
	originalAddress,
	hasError,
}) => {
	return (
		<LWDialog
			open={isOpen}
			title="Address Verification"
			onClose={() => {
				clearValidatedAddress();
				setIsOpen(false);
			}}
			isLoading={isLoading}
		>
			{hasError ? (
				<ValidateErrorContent
					isLoading={isLoading}
					setIsOpen={setIsOpen}
					submitOriginal={submitOriginal}
					addressData={originalAddress}
				/>
			) : (
				<ValidateContent
					isLoading={isLoading}
					setIsOpen={setIsOpen}
					submitValidated={submitValidated}
					submitOriginal={submitOriginal}
					addressData={validatedAddress}
				/>
			)}
		</LWDialog>
	);
};

ValidateAddressDialog.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	hasError: PropTypes.bool.isRequired,
	validatedAddress: PropTypes.shape({
		address: PropTypes.string,
		address2: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string,
		postal_code: PropTypes.string,
		state: PropTypes.string,
	}),
	originalAddress: PropTypes.shape({
		address: PropTypes.string,
		address2: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string,
		postal_code: PropTypes.string,
		state: PropTypes.string,
	}),
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	submitValidated: PropTypes.func.isRequired,
	submitOriginal: PropTypes.func.isRequired,
};

export default ValidateAddressDialog;
