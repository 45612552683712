import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Loading from 'components/common/Loading';
import { get } from 'lodash';

const bgColor = css`
	${({ $bgColor = 'common.white', theme }) =>
		`background-color: ${get(theme.palette, $bgColor)};`}
`;

// $borderColorHover is only needed for the default button, so it is declared in propsMap and has no effect if undefined.
const hoverAndFocus = css`
	&:hover,
	&:focus {
		border-width: 1px;
		${({ $hoverColor = 'secondary.washedOpaque', $borderColorHover, theme }) =>
			`background-color: ${get(theme.palette, $hoverColor)};
			${
				$borderColorHover
					? `border-color: ${get(theme.palette, $borderColorHover)};`
					: ''
			}
			`}
	}
`;

const rippleOpacityAnimation = keyframes`
	0% {
		transform: scale(0);
		opacity: 0.1;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
`;

// The classnames of the elements that need the color changed contain "child"
// This thread is helpful for understanding what's going on here: https://stackoverflow.com/questions/56169750/change-ripple-color-on-click-of-material-ui-button
const ripple = css`
	${({ theme, $ripplecolor = 'secondary.fadedOpaque' }) => {
		return `
			*[class*="child"] {
				background-color: ${get(theme.palette, $ripplecolor)};
			}
		`;
	}}
`;

const disabled = css`
	${({
		$disabledBgColor = 'grey.light',
		$disabledTextColor = 'text.disabled',
		$disabledBorderColor = 'grey.disabled',
		theme: { palette },
	}) =>
		`&:disabled, &[disabled] { 
				background-color: ${get(palette, $disabledBgColor)};
				color: ${get(palette, $disabledTextColor)};
				border-color: ${get(palette, $disabledBorderColor)};
				border-width: 2px;
			}`}
`;

const border = css`
	border-width: 1px;
	${({ $borderColor = 'secondary.main', theme }) =>
		`border-color: ${get(theme.palette, $borderColor)};`}
`;

const minWidth = css`
	${({ $minWidth = 16, theme }) => `min-width: ${theme.spacing($minWidth)}px;`}
`;

const SButton = styled(Button)`
	color: ${({ theme, $textColor }) => get(theme.palette, $textColor)};
	border-radius: 360px;
	font-weight: 500;
	text-transform: none;
	line-height: 16px;
	letter-spacing: 0.24px;
	font-size: 16px;
	${minWidth}
	& > :first-child {
		z-index: 1; /* puts label in front of ripple */
	}
	*[class*='rippleVisible'] {
		/* replace the keyframe opacity definition to be opaque. */
		opacity: 1;
		animation: ${rippleOpacityAnimation} 550ms
			${({ theme }) => theme.transitions.easing.easeInOut};
	}
	${bgColor}
	${hoverAndFocus}
	${({ $noDisabledStyle }) => ($noDisabledStyle ? null : disabled)};
	${border}
	${ripple}
`;

// passing in these props when the respective key is passed in as the variant.
const propsMap = {
	strong: {
		variant: 'contained',
		$bgColor: 'secondary.main',
		$hoverColor: 'secondary.alt',
		$ripplecolor: 'secondary.fadedOpaque',
		$disabledBgColor: 'grey.disabled',
		$textColor: 'primary.faint',
	},
	default: {
		$borderColorHover: 'secondary.alt',
		$textColor: 'secondary.alt',
	},
	subtle: {
		$bgColor: 'common.white',
		$borderColor: 'divider',
		$disabledBorderColor: 'grey.light',
		$disabledBgColor: 'grey.light',
	},
	negative: {
		variant: 'contained',
		$bgColor: 'error.main',
		$disabledBgColor: 'text.disabled',
		$hoverColor: 'error.dark',
		$textColor: 'common.white',
		$disabledTextColor: 'common.white',
		$ripplecolor: 'error.fadedOpaque',
	},
	subtlePrimary: {
		$bgColor: 'common.white',
		$borderColor: 'divider',
		$hoverColor: 'grey.light',
		$ripplecolor: 'text.disabled',
	},
	subtlePrimaryActive: {
		$bgColor: 'common.white',
		$borderColor: 'primary.main',
		$textColor: 'primary.main',
		$hoverColor: 'grey.light',
		$ripplecolor: 'text.disabled',
	},
	textPrimaryDark: {
		$minWidth: 9,
		$textColor: 'primary.dark',
		variant: 'text',
		$hoverColor: 'primary.faint',
		$ripplecolor: 'primary.washed',
		$bgColor: 'common.transparent',
	},
	transparentWashedHover: {
		$minWidth: 9,
		$textColor: 'primary.dark',
		variant: 'text',
		$hoverColor: 'primary.washed',
		$ripplecolor: 'primary.washed',
		$bgColor: 'common.transparent',
	},
};

// This can be deleted when we're confident that all buttons are using a supported variant.
const getBackwardsCompatibilityVariant = ({ variant = '', color = '' }) => {
	switch (`${variant}${color}`) {
		case 'defaulttertiary':
		case 'outlinedtertiary':
			return propsMap.subtle;
		case 'containedsecondary':
		case 'contained':
			return propsMap.strong;
		default:
			return propsMap[variant];
	}
};

/**
 * @typedef LWButtonCustomPropsI
 * @property {'strong' | 'default' | 'subtle' | 'negative' | 'subtlePrimary' | 'subtlePrimaryActive' | 'textPrimaryDark' | 'transparentWashedHover'} [variant]
 * @property {boolean} noDisabledStyle
 * @property {import('@material-ui/core').BoxProps} BoxProps
 * @property {boolean} isLoading
 */

/**
 * @typedef  {Omit<import('@material-ui/core').ButtonProps, 'variant' | 'color'> & LWButtonCustomPropsI} LWButtonPropsI
 */

/**
 * @param {LWButtonPropsI} props
 * */
const LWButton = ({
	BoxProps,
	isLoading,
	color,
	variant = 'default',
	disabled: isDisabled,
	noDisabledStyle,
	children,
	...ButtonProps
}) => {
	const propsMapProps = getBackwardsCompatibilityVariant({ variant, color });
	return (
		<Box
			display="inline-flex"
			position="relative"
			whiteSpace="nowrap"
			{...BoxProps}
		>
			<SButton
				{...{ variant: 'outlined', ...propsMapProps }}
				disabled={isLoading || isDisabled}
				$noDisabledStyle={noDisabledStyle}
				disableFocusRipple
				{...ButtonProps}
			>
				{children}
				{isLoading && (
					<Loading
						CircularProgressProps={{ size: 18 }}
						css={`
							top: 0;
							bottom: 0;
						`}
					/>
				)}
			</SButton>
		</Box>
	);
};

const variants = [
	'strong',
	'default',
	'subtle',
	'negative',
	'subtlePrimary',
	'subtlePrimaryActive',
	'textPrimaryDark',
	'transparentWashedHover',
	'outlined', // deprecated: backward compatibility only
	'contained', // deprecated: backward compatibility only
];
LWButton.propTypes = {
	BoxProps: PropTypes.object,
	isLoading: PropTypes.bool,
	/**
	 * @deprecated
	 * color is here for backward compatibility only. Use the propsMap to control color. */
	color: PropTypes.string,
	/** This is not passed in directly to the MUI prop, "variant". This is a key to the "propsMap", and the "propsMap" determines the MUI variant. */
	variant: PropTypes.oneOf(variants),
	disabled: PropTypes.bool,
	/** if true, a disabled button will not have any of the normal disabled styles */
	noDisabledStyle: PropTypes.bool,
};

export { variants };
export default LWButton;
