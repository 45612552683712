import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { isInvalid } from 'redux-form';
import { actions as billingDetailsActions } from 'modules/api/account/billingAddress/detailsModule';
import { actions as contactListActions } from 'modules/api/contact/listModule';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { actions as contactUpdateActions } from 'modules/api/contact/updateModule';
import { actions as updateOrCreateActions } from 'modules/api/account/billingAddress/updateOrCreateModule';
import {
	actions as validateAddressActions,
	selectors as validateAddressSelectors,
} from 'modules/api/contact/validateAddressModule';
import { formName as accountCreateAddressFormName } from 'containers/pages/account/create/AccountCreateAddressForm';
import { formName as accountCreateContactFormName } from 'containers/pages/account/create/AccountCreateContactForm';
import { selectors as paymentDetailsSelectors } from 'modules/api/billing/payment/profile/detailsModule';
import addressActions from './addressActions';
import { getAddress, getSubmit } from './addressSelectors';

/* Dispatches validate API and opens modal when the API returns */
function* setValidate(action) {
	const isContactFormInvalid = yield select(
		isInvalid(accountCreateContactFormName),
	);
	const isAddressFormInvalid = yield select(
		isInvalid(accountCreateAddressFormName),
	);

	if (isContactFormInvalid || isAddressFormInvalid) return;

	const { address, address2, city, state, country, postalCode } =
		action.address;

	yield put(
		validateAddressActions.fetch({
			address,
			address2,
			city,
			state,
			country,
			postal_code: postalCode,
		}),
	);
	yield take([
		validateAddressActions.setType,
		validateAddressActions.errorType,
	]);

	yield put(addressActions.toggleDialog(true));
	if (action.effect) {
		try {
			yield take(action.effect.waitFor?.setType);
			if (action.effect.cb) {
				yield call(action.effect.cb);
			}
			yield put(action.effect.waitFor.clearType);
		} catch {
			// waitFor not defined
		}
	}
}

/* Dispatches API call to submit original address, closes modal when this returns  */
function* submitOriginal() {
	const originalAddress = yield select(getAddress);
	const submit = yield select(getSubmit);
	yield call(submit, originalAddress);
}

/* Dispatches API call to submit validated address, closes modal when this returns */
function* submitValidated() {
	const validatedAddress = yield select(validateAddressSelectors.getNativeData);
	// rather than storing this in state, use contentKey pattern with setValidate
	const submit = yield select(getSubmit);
	if (validatedAddress?.address.validated === 0) {
		validatedAddress.address.validated = 1;
	}
	yield call(submit, validatedAddress?.address);
	yield put(validateAddressActions.clear());
}

function* billingUpdateResponse() {
	yield put(addressActions.toggleDialog(false));
	yield put(billingDetailsActions.fetch());
	if (yield select(paymentDetailsSelectors.getHasPaymentMethod))
		yield put(
			snackbarActions.pushMessage({
				message: 'Successfully saved billing address',
				variant: 'success',
			}),
		);
}

function* contactUpdateResponse() {
	yield put(contactListActions.fetch());
	if (yield select(paymentDetailsSelectors.getHasPaymentMethod))
		yield put(
			snackbarActions.pushMessage({
				message: 'Successfully saved contact information',
				variant: 'success',
			}),
		);
}

export default function* saga() {
	yield takeLatest(addressActions.ACCOUNT_ADDRESS_SET_VALIDATE, setValidate);
	yield takeLatest(
		addressActions.ACCOUNT_ADDRESS_SUBMIT_ORIGINAL,
		submitOriginal,
	);
	yield takeLatest(
		addressActions.ACCOUNT_ADDRESS_SUBMIT_VALIDATED,
		submitValidated,
	);
	yield takeLatest(updateOrCreateActions.setType, billingUpdateResponse);
	yield takeLatest(contactUpdateActions.setType, contactUpdateResponse);
}
