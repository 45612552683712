import React from 'react';
import DedicatedActions from './sideActions/DedicatedActions';
import CPanelActions from './sideActions/CPanelActions';
import BeyondHostingActions from './sideActions/BeyondHostingActions';
import OpenStackActions from './sideActions/OpenStackActions';
import CloudActions from './sideActions/CloudActions';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const SideActions = (props) => {
	const {
		assetType: serverType,
		capabilities,
		isPrivateParent,
		isBeyondHosting,
		isOpenStack,
		isVmware,
	} = props;
	return (
		<>
			{serverType === 'dedicated' && <DedicatedActions {...props} />}
			{capabilities?.cpanelLogin && <CPanelActions {...props} />}
			{isBeyondHosting && <BeyondHostingActions {...props} />}
			{isOpenStack && <OpenStackActions {...props} />}
			{['cloud', 'bare metal'].includes(serverType) &&
				!isVmware &&
				!isOpenStack &&
				!isBeyondHosting &&
				!isPrivateParent && <CloudActions {...props} />}
		</>
	);
};

export default SideActions;
