import { createSelector } from 'reselect';
import userManager from 'utility/openid/userManager';

const getAppConfig = (state) => state.appConfig;

const getBaseURL = createSelector(
	getAppConfig,
	(appConfig) => appConfig?.baseURL,
);

const getOpenIdConfig = createSelector(
	getAppConfig,
	(appConfig) => appConfig?.openId,
);

const getUserProfilePath = createSelector(
	getOpenIdConfig,
	(openIdConfig) =>
		openIdConfig?.authority &&
		openIdConfig?.client_id &&
		`${openIdConfig?.authority}/user/?client_id=${openIdConfig?.client_id}`,
);

const getKeroSignupLink = createSelector(
	getOpenIdConfig,
	(openIdConfig) =>
		openIdConfig?.authority &&
		openIdConfig?.client_id &&
		`${openIdConfig.authority}/signup?client_id=${openIdConfig?.client_id}&amp;response_type=token%20id_token&amp;scope=openid%20profile%20grants`,
);

const getHosts = createSelector(getAppConfig, (appConfig) => appConfig?.hosts);

const getWwwHostname = createSelector(
	getHosts,
	(hosts) => `https://${hosts?.www}`,
);

const getCartHostname = createSelector(
	getHosts,
	(hosts) => `https://${hosts?.cart}`,
);

const getSalesforceHostname = createSelector(
	getHosts,
	(hosts) => `https://${hosts?.salesforce}`,
);

const getCustomerCommunityHostname = createSelector(
	getHosts,
	(hosts) => `https://${hosts?.customer_community}`,
);

const getGatorHostname = createSelector(getHosts, (hosts) => `${hosts?.gator}`);

const getAuthorizeUrl = createSelector(
	getHosts,
	(hosts) => `https://${hosts?.authorize_net}`,
);

const getManageHostname = createSelector(
	getHosts,
	(hosts) => `https://${hosts?.manage}`,
);

const getManagedAppsHostname = createSelector(
	getHosts,
	(hosts) => `https://${hosts?.managed_apps}`,
);

const sessionLength = createSelector(
	getAppConfig,
	(appConfig) => appConfig?.session?.length,
);

const warningTime = createSelector(
	getAppConfig,
	(appConfig) => appConfig?.session?.warningTime,
);

const getGaTrackerId = createSelector(
	getAppConfig,
	(appConfig) => appConfig?.gaTrackerId,
);

const getGoogleTagManagerId = createSelector(
	getAppConfig,
	(appConfig) => appConfig?.gtmContainerId,
);

const getSiteId = createSelector(
	getAppConfig,
	(appConfig) => appConfig?.cartStack?.siteId,
);

const getTrackingScriptUrl = createSelector(
	getAppConfig,
	(appConfig) => appConfig?.cartStack?.trackingScriptUrl,
);

const getGuestCredentials = createSelector(
	getAppConfig,
	(appConfig) => appConfig?.guestAuth,
);

const getCartConfig = createSelector(
	getAppConfig,
	(appConfig) => appConfig?.cart,
);

const getChatConfig = createSelector(
	getAppConfig,
	(appConfig) => appConfig?.chatConfig,
);

const getUserManager = createSelector(getOpenIdConfig, userManager);

const getDeleteSessionCookieUrl = createSelector(
	getCustomerCommunityHostname,
	(customerCommunityHostname) =>
		// replace the '/s' at the end with the path
		customerCommunityHostname.replace(/\/s$/, '/secur/deleteSessionCookie.jsp'),
);

const appConfigSelectors = {
	getAppConfig,
	getBaseURL,
	getDeleteSessionCookieUrl,
	getHosts,
	getKeroSignupLink,
	getWwwHostname,
	getCartHostname,
	getSalesforceHostname,
	getCustomerCommunityHostname,
	getGatorHostname,
	getAuthorizeUrl,
	getManageHostname,
	getManagedAppsHostname,
	sessionLength,
	warningTime,
	getGaTrackerId,
	getGoogleTagManagerId,
	getSiteId,
	getTrackingScriptUrl,
	getGuestCredentials,
	getCartConfig,
	getChatConfig,
	getOpenIdConfig,
	getUserManager,
	getUserProfilePath,
};

export default appConfigSelectors;
