import { takeLatest, put, call, select } from 'redux-saga/effects';
import { actions as snackbarActions } from 'modules/snackbar';
import { push } from 'connected-react-router';
import snackbarSaga from 'modules/snackbar/sagas/sagas';
import { addToCartSaga } from 'modules/cart/sagas';
import {
	handleInitialize,
	retrieveConfigs,
	retrieveRegion,
} from '../productConfig/sagas';
import { handleSetDeployOnto } from '../productConfig/deployOntoSagas';
import actions from './actions';
import productConfigSelectors from '../productConfig/selectors';

const addForParentRedirectMessage = 'Adding child. You will be redirected.';

/**
 * Adds a child for a private parent to the basket.
 * @param {string} param0.uniqId - the uniqId of the parent to which we are adding the server.
 */
function* addForParent({ uniqId }) {
	yield put(
		snackbarActions.pushMessage({
			variant: 'info',
			message: addForParentRedirectMessage,
		}),
	);

	yield call(handleInitialize, { productCode: 'SS.VPS' });

	yield call(handleSetDeployOnto, { deployOnto: uniqId });

	const productCode = 'SS.VPS';

	const options = yield call(retrieveConfigs, productCode);
	const regionId = yield call(retrieveRegion, productCode);
	const properties = (yield select(
		productConfigSelectors.getProductProperties,
	))[productCode];

	const item = {
		code: productCode,
		options,
		properties,
		region_id: regionId,
	};
	const { error, newCart } = yield call(addToCartSaga, {
		cycle: 'monthly',
		item,
	});

	yield put(snackbarActions.popMessage()); // removes "Adding child..." message
	yield call(snackbarSaga, {
		error,
		successMessage: 'Child successfully added to cart.',
		errorMessage: 'Something when wrong. Please try again or contact support',
	});
	if (error) return; // will display snackbar error and NOT proceed to /cart

	const newItemUuid = newCart.items[newCart.items.length - 1].uuid;

	yield put(push(`/shop/config/${newItemUuid}`));
}

export { addForParentRedirectMessage };
export default function* addItemForParentSagas() {
	yield takeLatest(actions.BASKET_ADD_ITEM_FOR_PARENT, addForParent);
}
