import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import LabeledIcon from 'components/atoms/LabeledIcon';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import SortIcon from 'components/atoms/SortIcon';
import PropTypes from 'prop-types';
import LWTypography from 'components/common/LWTypography';
import RowActions from 'components/common/table/RowActions';
import styled, { css } from 'styled-components';
import { startCase } from 'lodash';
import ContentFromKey from './ContentFromKey';

const Head = ({ headers, widthFit, orderBy, onSort }) =>
	headers.length > 0 && (
		<TableHead>
			<TableRow>
				{headers.map(
					(
						{
							display,
							value,
							padding,
							align,
							key,
							sortable,
							isSortIconInverted,
							datatestid,
							contentKey,
							contentProps,
							...rest
						},
						idx,
					) => (
						<TableCell
							key={`${key}header`}
							padding={padding}
							align={align || 'left'}
							// Last cell takes up remaining width if widthFit is true
							width={
								idx === headers.length - 1 ? '' : (widthFit && '1px') || ''
							}
						>
							{!(orderBy && sortable) ? (
								display || (
									<ContentFromKey
										contentKey={contentKey}
										contentProps={
											// add default header props if we arent using content key
											!contentKey
												? {
														bold: true,
														variant: 'subtitle2',
														...contentProps,
													}
												: contentProps
										}
										value={value}
									/>
								)
							) : (
								<LabeledIcon
									whiteSpace={widthFit ? 'nowrap' : 'inherit'}
									display="flex"
									text={display || value}
									textProps={{ bold: true, variant: 'subtitle2' }}
									flexDirection="row-reverse"
									justifyContent="flex-end"
									data-testid={datatestid}
									icon={
										<SortIcon
											$active={orderBy?.field === key}
											$isSortIconInverted={isSortIconInverted}
											$sort={orderBy.sort}
											onClick={() => onSort(key)}
										/>
									}
									{...rest}
								/>
							)}
						</TableCell>
					),
				)}
			</TableRow>
		</TableHead>
	);

const NoDataRow = ({ colSpan, noDataText, isLoading }) => (
	<TableRow>
		<TableCell colSpan={colSpan}>
			<LWTypography
				isLoading={isLoading}
				BoxProps={
					!isLoading && {
						display: 'flex',
						justifyContent: 'center',
					}
				}
				SkeletonProps={{ width: '60%' }}
			>
				{noDataText}
			</LWTypography>
		</TableCell>
	</TableRow>
);

const Loader = () => <LWTypography isLoading BoxProps={{ pl: 2 }} />;

// TODO: rounded corners; It's apparently not simple. :'(
export const highlighted = css`
	background-color: ${({ theme }) => theme.palette.primary.faint};
	& > td {
		border-top: 0.125rem solid ${({ theme }) => theme.palette.primary.main};
		border-bottom: 0.125rem solid ${({ theme }) => theme.palette.primary.main};
		height: calc(100% - 0.25rem);
	}
	& > :first-child {
		border-left: 0.125rem solid ${({ theme }) => theme.palette.primary.main};
	}
	& > :last-child {
		border-right: 0.125rem solid ${({ theme }) => theme.palette.primary.main};
	}
`;

const STableRow = styled(TableRow)`
	${({ $highlighted }) => ($highlighted ? highlighted : '')}
`;

const CellContent = ({
	isLoading,
	display,
	contentKey,
	contentProps = {},
	value,
}) => {
	if (isLoading) return <Loader />;
	if (display) return display;
	return (
		<ContentFromKey
			contentKey={contentKey}
			contentProps={contentProps}
			value={value}
		/>
	);
};

const Body = ({ headerKeys, widthFit, data, noDataText, isLoading }) => (
	<TableBody>
		{data.length > 0 ? (
			data.slice(0, isLoading ? 3 : undefined).map((elem) => (
				<STableRow
					key={elem.key?.value}
					$highlighted={Boolean(elem.highlighted?.value)}
				>
					{headerKeys.map((key) => {
						const {
							display,
							padding,
							colSpan,
							value,
							contentProps,
							contentKey,
						} = elem[key] || {};
						switch (key) {
							case 'rowActions':
								return (
									<TableCell
										key="rowAction"
										padding={padding || 'none'}
										label="Actions"
									>
										<RowActions
											rowActions={elem.rowActions}
											data-testid={`RowAction__${elem.key?.value}`}
											BoxProps={{
												px: 2,
												display: 'flex',
												justifyContent: 'flex-end',
											}}
										/>
									</TableCell>
								);
							default:
								return (
									<TableCell
										key={`${value}${key}`}
										padding={padding}
										colSpan={colSpan}
										label={startCase(key)}
									>
										<Box whiteSpace={widthFit ? 'nowrap' : 'inherit'}>
											<CellContent
												isLoading={isLoading}
												display={display}
												contentKey={contentKey}
												contentProps={contentProps}
												value={value}
											/>
										</Box>
									</TableCell>
								);
						}
					})}
				</STableRow>
			))
		) : (
			<NoDataRow
				isLoading={isLoading}
				colSpan={headerKeys.length}
				noDataText={noDataText}
			/>
		)}
	</TableBody>
);

const NoBorderCell = styled(TableCell)`
	border-bottom: none;
`;
const Foot = ({ colSpan, padding, children }) => {
	return (
		Boolean(children) && (
			<TableFooter>
				<TableRow>
					<NoBorderCell colSpan={colSpan} padding={padding}>
						{children}
					</NoBorderCell>
				</TableRow>
			</TableFooter>
		)
	);
};

const Component = ({ children, border, padding, testId }) =>
	border ? (
		<Paper
			variant="outlined"
			component={Box}
			p={padding || 0}
			data-testid={testId}
		>
			{children}
		</Paper>
	) : (
		<Box p={padding || 0} data-testid={testId}>
			{children}
		</Box>
	);

const STable = styled(Table)`
	@media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
		& thead {
			display: none;
		}
		& tr {
			border-bottom: solid ${({ theme }) => theme.palette.divider} 2px;
		}
		& td {
			display: flex;
			padding: ${({ theme }) => theme.spacing(1)}px
				${({ theme }) => theme.spacing(2)}px;
			justify-content: space-between;
		}

		& td::before {
			content: attr(label);
			font-weight: bold;
			display: flex;
			align-items: center;
		}

		& td:first-child {
			padding-top: ${({ theme }) => theme.spacing(4)}px;
			padding-bottom: ${({ theme }) => theme.spacing(2)}px;
		}

		& td:last-child {
			padding-left: ${({ theme }) => theme.spacing(2)}px;
			padding-top: ${({ theme }) => theme.spacing(1)}px;
			padding-bottom: ${({ theme }) => theme.spacing(1)}px;
		}

		table-layout: fixed;
	}
`;

/**
 * @param {object} props
 * @param {Record<string, CCCTableDataI>[]} props.data
 * @param {CCCTableHeaderI[]} props.headers
 */
const CCCTable = ({
	headers: headersProp = [],
	data = [],
	footer,
	widthFit,
	noDataText = 'No data to display',
	isLoading,
	padding,
	upperLeftContent,
	upperRightContent,
	border = true,
	'data-testid': testId,
	orderBy,
	onSort,
}) => {
	const hasRowActions = !!data.find(({ rowActions }) => rowActions);
	const headers = [
		...headersProp,
		...(hasRowActions ? [{ display: <Box />, key: 'rowActions' }] : []),
	];

	return (
		<TableContainer
			component={Component}
			border={border}
			padding={padding}
			testId={testId}
		>
			{(upperLeftContent || upperRightContent) && (
				<Grid container>
					{upperLeftContent && (
						<Grid item xs>
							{upperLeftContent}
						</Grid>
					)}
					{upperRightContent && (
						<Grid item container justifyContent="flex-end" xs>
							{upperRightContent}
						</Grid>
					)}
				</Grid>
			)}
			<STable>
				<Head
					headers={headers}
					widthFit={widthFit}
					orderBy={orderBy}
					onSort={onSort}
				/>
				<Body
					headerKeys={headers.map(({ key }) => key)}
					widthFit={widthFit}
					data={data}
					noDataText={noDataText}
					isLoading={isLoading}
				/>
				<Foot colSpan={headers.length} padding={footer?.padding}>
					{footer?.display}
				</Foot>
			</STable>
		</TableContainer>
	);
};

CCCTable.propTypes = {
	/** An array of objects containing keys. These keys will be used to place the display objects from the data object.
	The display node is what will actualy be shown to the user: [{key: 'definesDataSchema', display: <JSXNode />, padding: 'default'}]
	 */
	headers: PropTypes.arrayOf(
		PropTypes.shape({
			/** The string used to access the correct key within each data object. */
			key: PropTypes.string.isRequired,
			/** the JSX element that will be placed into a TableCell within the TableHead */
			display: PropTypes.node,
			/** Fowarded to the Cell containing the display */
			padding: PropTypes.oneOf(['default', 'none']),
		}),
	),
	/** Each object in the array must have keys analogous to the headers' values that were passed in along with a jsx key:
    [{ key: { value: 'somethingUnique', display: null }, header1: { display: <JSXNode /> } header2: { display: <JSXNode /> } }]
	This can also be used to add row level options including: ["highlighted"]. Syntax is the same as "key": [{ highlighted: { value: true, display: null } }] */
	data: PropTypes.arrayOf(
		PropTypes.objectOf(
			PropTypes.oneOfType([
				PropTypes.shape({
					/** the JSX element that will be placed into a TableCell within the TableBody underneath the header with the key to access it. */
					display: PropTypes.node,
					/** The value associated with the data  */
					value: PropTypes.oneOfType([
						PropTypes.string,
						PropTypes.bool,
						PropTypes.number,
					]),
					/** Fowarded to the Cell containing the display */
					padding: PropTypes.oneOf(['default', 'none']),
					colSpan: PropTypes.string,
					contentProps: PropTypes.object,
				}),
				// TODO: only allow arrays if the property is rowActions.
				PropTypes.array,
			]),
		),
	),
	/** Single element that take up entire bottom row. Primary use case: pagination. */
	footer: PropTypes.shape({
		display: PropTypes.node,
		padding: PropTypes.oneOf(['default', 'none']),
	}),
	/** Set to true to make last cell take up remaining space. */
	widthFit: PropTypes.bool,
	/** Text that displays when there is no data. */
	noDataText: PropTypes.string,
	/** Gets passed to LWTypography in NoDataRow to show skeleton loader. */
	isLoading: PropTypes.bool,
	/** padding of the table inside the paper */
	padding: PropTypes.number,
	/** content that appears in the upper-left of the tabel */
	upperLeftContent: PropTypes.node,
	/** content that appears in the upper-right of the tabel */
	upperRightContent: PropTypes.node,
	/** Should there be a paper container which makes the outline? */
	border: PropTypes.bool,
	/** Field and direction of column to sort by */
	orderBy: PropTypes.shape({
		/* key of column to sort by */
		field: PropTypes.string,
		/* what direction to sort data in */
		sort: PropTypes.oneOf(['asc', 'desc']),
	}),
	/** function to call when clicking sortable headers */
	onSort: PropTypes.func,
};

export default CCCTable;
