import './fonts.css';

import React from 'react';
import propTypes from 'prop-types';
import AppConfigGate from 'utility/appConfig/AppConfigGate';
import { ConnectedRouter } from 'connected-react-router';
import {
	StylesProvider,
	ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import OpenIDProviderWrapper from 'utility/openid/OpenIDProviderWrapper';
import MaintenanceModeGate from 'containers/structural/MaintenanceModeGate';
import ErrorBoundary from 'containers/structural/ErrorBoundary';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SnackbarProvider from 'components/common/Snackbar/SnackbarProvider';
import RouteHandler from 'components/routers/RouteHandler';
import useSetAuth from 'modules/queries/useSetAuth';
import { history, store } from './store';
import theme from './theme';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { refetchOnWindowFocus: false, retry: false },
	},
});

const ReactQueryProvider = ({ children }) => {
	useSetAuth();

	return (
		<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
	);
};

export function Providers({ children }) {
	return [
		(child) => <ReduxProvider store={store}>{child}</ReduxProvider>,
		(child) => <ReactQueryProvider>{child}</ReactQueryProvider>,
		(child) => (
			<MuiPickersUtilsProvider utils={MomentUtils}>
				{child}
			</MuiPickersUtilsProvider>
		),
		(child) => <MuiThemeProvider theme={theme}>{child}</MuiThemeProvider>,
		(child) => <StylesProvider injectFirst>{child}</StylesProvider>,
		(child) => <ThemeProvider theme={theme}>{child}</ThemeProvider>,
		(child) => <AppConfigGate>{child}</AppConfigGate>,
		(child) => <ConnectedRouter history={history}>{child}</ConnectedRouter>,
		(child) => <OpenIDProviderWrapper>{child}</OpenIDProviderWrapper>,
		(child) => <MaintenanceModeGate>{child}</MaintenanceModeGate>,
		(child) => <ErrorBoundary fullScreen>{child}</ErrorBoundary>,
		(child) => <SnackbarProvider>{child}</SnackbarProvider>,
	]
		.reverse()
		.reduce((child, provider) => provider(child), children);
}

Providers.propTypes = {
	children: propTypes.node.isRequired,
};

const App = () => (
	<Providers>
		<RouteHandler />
	</Providers>
);

export default App;
