import React from 'react';
import LWTypography from 'components/common/LWTypography';
import LWSelect from 'components/atoms/LWSelect';
import Grid from '@material-ui/core/Grid';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';

const DefaultContent = ({
	categoriesData,
	projectName = 'Project Name',
	category,
	changeCategory,
	availableAssetsData,
	asset,
	assetCurrentProject,
	changeAsset,
}) => (
	<Grid container direction="column" spacing={3}>
		<Grid item>
			<LWTypography>
				Select the asset you would like to add to {projectName}.
			</LWTypography>
		</Grid>
		<Grid item>
			<LWSelect
				label="Category"
				isLoading={categoriesData.isLoading}
				value={category}
				onChange={changeCategory}
				options={
					categoriesData.categories?.map((categoryName) => ({
						label: capitalize(categoryName),
						value: categoryName,
					})) || []
				}
			/>
		</Grid>
		<Grid item>
			<LWSelect
				label={availableAssetsData.hasData && category ? `My ${category}` : ''}
				isLoading={availableAssetsData.isLoading}
				disabled={!availableAssetsData.hasData}
				value={asset.uniq_id}
				onChange={changeAsset}
				options={
					availableAssetsData.assets
						? availableAssetsData.assets
								?.map((assetData) => ({
									value: assetData.get('uniq_id'),
									label: assetData.get('domain'),
								}))
								.toJS()
						: []
				}
				error={!!assetCurrentProject.uniqId}
				helperText={
					assetCurrentProject.uniqId
						? `${asset.domain} already belongs to ${assetCurrentProject.name}.`
						: ''
				}
			/>
		</Grid>
	</Grid>
);

DefaultContent.propTypes = {
	categoriesData: PropTypes.object.isRequired,
	availableAssetsData: PropTypes.object.isRequired,
	projectName: PropTypes.string,
	category: PropTypes.string.isRequired,
	changeCategory: PropTypes.func.isRequired,
	asset: PropTypes.object.isRequired,
	assetCurrentProject: PropTypes.object.isRequired,
	changeAsset: PropTypes.func.isRequired,
};

export default DefaultContent;
