import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { actions as dialogActions } from 'modules/dialogs';
import loadBalancerConstants from 'utility/constants/loadBalancers';
import useCreateLoadBalancer from 'modules/queries/network/loadbalancer/useCreate';
import Box from '@material-ui/core/Box';
import { Form } from 'react-final-form';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import Loading from 'components/common/Loading';
import LWButton from 'components/common/LWButton';
import LWTypography from 'components/common/LWTypography';
import usePossibleNodes from 'modules/queries/network/loadbalancer/usePossibleNodes';
import useLoadBalancerStrategies from 'modules/queries/network/loadbalancer/useStrategies';
import useNetworkZoneList from 'modules/queries/network/zone/useList';

import Context from './context';

import ServiceInput from './ServiceInput';
import NameInput from './NameInput';
import RegionInput from './RegionInput';
import NodesInput from './NodesInput';
import MethodInput from './MethodInput';
import SessionPersistenceInput from './SessionPersistenceInput';
import SSLInputs from './SSLInputs';
import {
	formatIPs,
	getZoneRegions,
	makeCreateLoadBalancerApiParams,
} from './helpers';

const CreateLoadBalancer = () => {
	// strategies
	const { data: strategyData, isLoading: strategiesLoading } =
		useLoadBalancerStrategies();
	// possible nodes
	const { data: nodeList, isLoading: possibleNodesLoading } =
		usePossibleNodes();
	const nodes = useMemo(() => formatIPs(nodeList?.items), [nodeList]);
	// zone/regions
	const { data: zones, isLoading: networkZoneListLoading } =
		useNetworkZoneList();
	const regions = getZoneRegions(zones?.items);

	const isLoading = useMemo(
		() => networkZoneListLoading || possibleNodesLoading || strategiesLoading,
		[networkZoneListLoading, possibleNodesLoading, strategiesLoading],
	);

	return isLoading ? (
		<Box display="flex" justifyContent="center" alignItems="center">
			<Loading position="static" />
		</Box>
	) : (
		<CreateForm
			nodes={nodes}
			regions={regions}
			strategies={strategyData?.strategies}
		/>
	);
};

const CreateForm = ({ nodes = [], regions, strategies }) => {
	const dispatch = useDispatch();

	const [destinationPort, setDestinationPort] = useState('');
	const [enableSSLTermination, setEnableSSLTermination] = useState(false);
	const [includeIntermediateCertificate, setIncludeIntermediateCertificate] =
		useState(false);
	const [ips, setIps] = useState([]);
	const [name, setName] = useState('');
	const [regionId, setRegionId] = useState(regions?.[0]?.id || '');
	const [service, setService] = useState(loadBalancerConstants.SERVICE.WEB);
	const [sessionPersistence, setSessionPersistence] = useState(false);
	const [sourcePort, setSourcePort] = useState('');
	const [sslCertificate, setSslCertificate] = useState('');
	const [sslIntermediateCertificate, setSslIntermediateCertificate] =
		useState('');
	const [sslPrivateKey, setSslPrivateKey] = useState('');
	const [strategy, setStrategy] = useState(strategies?.[0]?.strategy || '');

	const [errors, setErrors] = useState({});

	const sslCertificateError = useMemo(
		() =>
			sslCertificate === errors?.sslCertificate?.value
				? errors?.sslCertificate?.formattedError
				: undefined,
		[errors, sslCertificate],
	);
	const sslIntermediateCertificateError = useMemo(
		() =>
			sslIntermediateCertificate === errors?.sslIntermediateCertificate?.value
				? errors?.sslIntermediateCertificate?.formattedError
				: undefined,
		[errors, sslIntermediateCertificate],
	);
	const sslPrivateKeyError = useMemo(
		() =>
			sslPrivateKey === errors?.sslPrivateKey?.value
				? errors?.sslPrivateKey?.formattedError
				: undefined,
		[errors, sslPrivateKey],
	);
	const { mutate: create, isLoading: isSubmitting } = useCreateLoadBalancer();
	const closeDialog = () => dispatch(dialogActions.close());
	const onSubmitError = (response) => {
		if (response.status === 400) {
			const fieldMap = {
				ssl_cert: 'sslCertificate',
				ssl_int: 'sslIntermediateCertificate',
				ssl_key: 'sslPrivateKey',
			};
			const extractError = (msg) =>
				msg?.substring((msg?.indexOf('[') || 0) + 1, msg?.indexOf(']'));

			const formattedErrors = response.errors.reduce((result, item) => {
				const newResult = { ...result };
				const newItem = { ...item };
				if (newItem.field === 'ssl_key') {
					newItem.value = sslPrivateKey;
				}
				newItem.formattedError = extractError(newItem.error);
				newResult[fieldMap[item?.field]] = newItem;
				return newResult;
			}, {});
			setErrors(formattedErrors);
		}
	};

	const submit = () => {
		create(
			makeCreateLoadBalancerApiParams({
				destinationPort,
				enableSSLTermination,
				includeIntermediateCertificate,
				ips,
				name,
				regionId,
				service,
				sessionPersistence,
				sourcePort,
				sslCertificate,
				sslIntermediateCertificate,
				sslPrivateKey,
				strategy,
			}),
			{
				onSuccess: () => closeDialog(),
				onError: onSubmitError,
			},
		);
	};

	const isSubmittable = useMemo(
		() =>
			Boolean(name) &&
			Boolean(regionId) &&
			(service === loadBalancerConstants.SERVICE.WEB ||
				(Boolean(sourcePort) &&
					Boolean(destinationPort) &&
					sourcePort > 0 &&
					destinationPort > 0)) &&
			ips.length > 0 &&
			(!enableSSLTermination ||
				(Boolean(sslCertificate) &&
					!sslCertificateError &&
					Boolean(sslPrivateKey) &&
					!sslPrivateKeyError &&
					(!includeIntermediateCertificate ||
						(Boolean(sslIntermediateCertificate) &&
							!sslIntermediateCertificateError)))) &&
			!isSubmitting,
		[
			destinationPort,
			enableSSLTermination,
			includeIntermediateCertificate,
			ips,
			isSubmitting,
			name,
			regionId,
			service,
			sourcePort,
			sslCertificate,
			sslCertificateError,
			sslIntermediateCertificate,
			sslIntermediateCertificateError,
			sslPrivateKey,
			sslPrivateKeyError,
		],
	);

	return (
		<Form
			onSubmit={submit}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<Context.Provider
						value={{
							destinationPort: [destinationPort, setDestinationPort],
							enableSSLTermination: [
								enableSSLTermination,
								setEnableSSLTermination,
							],
							includeIntermediateCertificate: [
								includeIntermediateCertificate,
								setIncludeIntermediateCertificate,
							],
							ips: [ips, setIps],
							isSubmitting,
							name: [name, setName],
							regionId: [regionId, setRegionId],
							service: [service, setService],
							sessionPersistence: [sessionPersistence, setSessionPersistence],
							sourcePort: [sourcePort, setSourcePort],
							sslCertificate: [sslCertificate, setSslCertificate],
							sslCertificateError,
							sslIntermediateCertificate: [
								sslIntermediateCertificate,
								setSslIntermediateCertificate,
							],
							sslIntermediateCertificateError,
							sslPrivateKey: [sslPrivateKey, setSslPrivateKey],
							sslPrivateKeyError,
							strategy: [strategy, setStrategy],
						}}
					>
						<Box px={1} py={2}>
							<Box pb={4}>
								<LWTypography variant="body2">
									The Liquid Web Cloud Load Balancer allows users to create
									Virtual IP addresses (VIPs) to intelligently distribute
									traffic across multiple Cloud Servers. This means that traffic
									can be shared across multiple resources to increase
									performance during times of high activity. The Cloud Server
									Load Balancer can greatly increase the reliability of your web
									application, because if one of your server nodes fails, the
									traffic is automatically shifted to another server node
									without any interruption of service.
								</LWTypography>
								<Box display="flex" alignItems="center" py={2}>
									<LWTypography variant="body2">
										Load Balancers carry a base charge of{' '}
										<strong>
											<u>$50.00 / mo</u>
										</strong>{' '}
										plus{' '}
										<strong>
											<u>$10.00 / mo</u>
										</strong>{' '}
										per node. Additional fees may apply.
									</LWTypography>
								</Box>
								<LWTypography variant="body2">
									In order to maintain proper logging and access control with a
									load-balanced configuration, customers utilizing the Apache
									web server are strongly encouraged to use the mod_zeus Apache
									module. If you are utilizing a web server other than Apache,
									please refer to the mod_zeus documentation for information on
									how similar functionality can be achieved in your web server.
									All Liquid Web managed images have mod_zeus included in the
									Apache configuration and no further action is needed on those
									templates.
								</LWTypography>
							</Box>
							<Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
								<Box flex={1} mb={{ xs: 2, md: 0 }}>
									<Grid spacing={2} container>
										<Grid item xs={12}>
											<NameInput />
										</Grid>
										{regions && (
											<Grid item xs={12}>
												<RegionInput regions={regions} />
											</Grid>
										)}
										<Grid item xs={12}>
											<ServiceInput />
										</Grid>
										<Grid item xs={12}>
											<NodesInput nodes={nodes} />
										</Grid>
									</Grid>
								</Box>
								<Box flex={1} ml={{ md: 2 }}>
									<Grid spacing={2} container>
										<Grid item xs={12}>
											<MethodInput strategies={strategies} />
										</Grid>
										<Grid item xs={12}>
											<SessionPersistenceInput />
										</Grid>
										<Grid item xs={12}>
											<SSLInputs />
										</Grid>
									</Grid>
								</Box>
							</Box>
							<Box mt={4}>
								<Grid spacing={2} container justifyContent="flex-end">
									<Grid item>
										<LWButton
											variant="subtle"
											onClick={closeDialog}
											disabled={isSubmitting}
										>
											Close
										</LWButton>
									</Grid>
									<Grid item>
										<FormControl variant="outlined">
											<LWButton
												color="secondary"
												variant="contained"
												type="submit"
												disabled={!isSubmittable}
												isLoading={isSubmitting}
											>
												Create
											</LWButton>
										</FormControl>
									</Grid>
								</Grid>
							</Box>
						</Box>
					</Context.Provider>
				</form>
			)}
		/>
	);
};

export default CreateLoadBalancer;
