import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { Map as ImmutableMap } from 'immutable';

const getStateSlice = (state) => state.api.contact.validateAddress;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'CONTACT_VALIDATEADDRESS',
	method: 'POST',
	url: '/contact/validateAddress.json',
});

const address = createSelector(defaultSelectors.getStateSlice, (slice) =>
	slice.getIn(['data', 'address'], ImmutableMap()),
);

const selectors = {
	result: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'result']),
	),
	address: createSelector(address, (slice) => slice.get('address')),
	address2: createSelector(address, (slice) => slice.get('address2')),
	city: createSelector(address, (slice) => slice.get('city')),
	state: createSelector(address, (slice) => slice.get('state')),
	postalCode: createSelector(address, (slice) => slice.get('postal_code')),
	country: createSelector(address, (slice) => slice.get('country')),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
