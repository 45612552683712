import { createSelector } from 'reselect';
import { isBasketAdmin as getIsBasketAdmin } from 'modules/auth';
import { createMapSelectorArgs } from 'utility/redux/selectorHelperFunctions/common';
import { stormConfigListSelectors } from 'modules/api/storm/config/listModule';
import { selectors as networkZoneListSelectors } from 'modules/api/network/zone/listModule';
import { flatDetailsSelectors } from 'modules/api/product/flatDetailsModule';
import cartSelectors from 'modules/cart/selectors';
import { selectors as templateSelectors } from 'modules/api/storm/template/listModule';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import { selectors as imageListSelectors } from 'modules/api/storm/image/listModule';
import { selectors as backupListSelectors } from 'modules/api/storm/backup/listModule';
import {
	getFallbackPtov,
	getNeedsZone,
	pickDefaultRegion,
	pickGeo,
} from 'utility/redux/selectorHelperFunctions/productConfig';
import isUUID from 'validator/es/lib/isUUID';
import isNumEqualIsh from 'utility/tools/isNumEqualIsh';
import { cloneDeep } from 'lodash';
import { uniqueList } from 'utility/array';
import { configValueStrings } from 'utility/constants/baskets';
import isOneIsh from 'utility/tools/isOneIsh';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';

/**
 * @returns {ProductConfigState}
 */
const getStateSlice = (state) => state.basket.productConfig;

const getQuickaddLoadingMap = createSelector(
	getStateSlice,
	(slice) => slice.quickaddLoadingMap,
);

const getGroupsExpanded = createSelector(
	getStateSlice,
	(slice) => slice.groupsExpanded,
);

const getActiveProductKey = createSelector(
	getStateSlice,
	(slice) => slice.activeProductKey,
);

const getChangedProductCodes = createSelector(
	getStateSlice,
	(slice) => slice.changedProductCodes,
);

const getRevealedHiddenOptions = createSelector(
	getStateSlice,
	(slice) => slice.revealedHiddenOptions,
);

const getTemplatesShowValue = createSelector(
	getStateSlice,
	(slice) => slice.templatesShowValue,
);

const isLoading = createSelector(
	flatDetailsSelectors.isLoading,
	cartSelectors.getCartIsInitialized,
	networkZoneListSelectors.getIsIniting,
	templateSelectors.getIsIniting,
	stormConfigListSelectors.getIsIniting,
	imageListSelectors.getIsIniting,
	backupListSelectors.getIsIniting,
	(
		flatDetailsLoading,
		cartIsInitialized,
		zoneIsIniting,
		templatesIsIniting,
		configsIsIsIniting,
		imageListIsIniting,
		backupListIsIniting,
	) =>
		flatDetailsLoading ||
		!cartIsInitialized ||
		zoneIsIniting ||
		templatesIsIniting ||
		configsIsIsIniting ||
		imageListIsIniting ||
		backupListIsIniting,
);

const getRawProductsData = createSelector(
	getStateSlice,
	(slice) => slice.productsData,
);

/**
 * Gets a map of zones to default value
 * @param {ValuesData} valuesData
 * @param {DefaultValuesByGeo} rawDefaultValuesByRegion
 * @param {Object.<string, number} zoneToRegionMap
 * @returns {Object.<string, number}
 */
const getZoneToDefaultValue = (
	valuesData,
	rawDefaultValuesByRegion,
	zoneToRegionMap,
) => {
	const zoneToDefaultValue = {};

	Object.keys(zoneToRegionMap).forEach((zone) => {
		const initialDefaultValue = rawDefaultValuesByRegion[zoneToRegionMap[zone]];
		const initialDefaultValueHasPrice =
			valuesData[initialDefaultValue]?.pricesByGeo?.[zone] !== undefined;

		if (initialDefaultValueHasPrice) {
			zoneToDefaultValue[zone] = initialDefaultValue;
		} else {
			zoneToDefaultValue[zone] = getFallbackPtov(valuesData, zone);
		}
	});

	return zoneToDefaultValue;
};

/**
 *
 * @param {Object.<string, number} zoneAvailability
 * @param {PricesByGeo} rawPricesByRegion
 * @param {Object.<string, number} zoneToRegionMap
 * @returns {PricesByGeo}
 */
const getAccuratePricesByGeo = (
	zoneAvailability,
	rawPricesByRegion,
	zoneToRegionMap,
) => {
	const newPricesByGeo = {};

	Object.keys(zoneAvailability).forEach((zone) => {
		newPricesByGeo[zone] = rawPricesByRegion[zoneToRegionMap[zone]];
	});

	return newPricesByGeo;
};

/**
 * Filters out values that are unavailable in all zones.
 * Also, adjusts the `pricesByGeo` object to be an accurate map of zone to price.
 * If the value is not available in a zone, that zone will be undefined in `pricesByGeo`.
 * @param {ValuesData} rawValuesData
 * @param {Object.<string, ZoneAvailabilty>} valueStringToZoneAvailablity
 * @param {Object.<string, string>} zoneToRegionMap
 * @param {Object.<string, Object>} [valueStringToExtraData]
 * @returns {ValuesData}
 */
const getAccurateValuesData = (
	rawValuesData,
	valueStringToZoneAvailablity,
	zoneToRegionMap,
	valueStringToExtraData,
) => {
	const accurateValuesData = {};

	Object.entries(rawValuesData).forEach(([ptovId, valueDataDetails]) => {
		const rawPricesByRegion = valueDataDetails.pricesByGeo;
		const zoneAvailability =
			valueStringToZoneAvailablity[valueDataDetails.valueString];
		const extraData = valueStringToExtraData?.[valueDataDetails.valueString];

		if (zoneAvailability && rawPricesByRegion) {
			const newPricesByGeo = getAccuratePricesByGeo(
				zoneAvailability,
				rawPricesByRegion,
				zoneToRegionMap,
			);

			accurateValuesData[ptovId] = {
				...valueDataDetails,
				pricesByGeo: newPricesByGeo,
				extraData,
			};
		}
	});

	return accurateValuesData;
};

const getProductsData = createSelector(
	getRawProductsData,
	stormConfigListSelectors.getConfigIdToZoneAvailablity,
	stormConfigListSelectors.getConfigIdToExtraData,
	networkZoneListSelectors.getZoneToRegionMap,
	templateSelectors.getTemplateToZoneAvailablity,
	templateSelectors.getTemplateToExtraData,
	/** @returns {ProductsData} */
	(
		rawProductsData,
		configIdToZoneAvailablity,
		configIdToExtraData,
		zoneToRegionMap,
		templateToZoneAvailablity,
		templateToExtraData,
	) => {
		const productsData = { ...rawProductsData };

		/**
		 * Clone a single product key to avoid mutations
		 * @param {string} productCode
		 */
		const cloneProduct = (productCode) => {
			productsData[productCode] = cloneDeep(productsData[productCode]);
		};

		// Store zoneAvailability map to process keys later
		const zoneAvailabilityMap = {
			ConfigId: configIdToZoneAvailablity,
			Template: templateToZoneAvailablity,
		};
		const extraDataMap = {
			ConfigId: configIdToExtraData,
			Template: templateToExtraData,
		};

		Object.entries(rawProductsData).forEach(
			([productCode, productDataDetails]) => {
				Object.entries(productDataDetails.ptokData).forEach(
					([ptokId, ptokDataDetails]) => {
						const valueStringToZoneAvailablity =
							zoneAvailabilityMap[ptokDataDetails.key];
						const valueStringToExtraData = extraDataMap[ptokDataDetails.key];
						if (!valueStringToZoneAvailablity) return;

						cloneProduct(productCode);

						const accurateValuesData = getAccurateValuesData(
							ptokDataDetails.values,
							valueStringToZoneAvailablity,
							zoneToRegionMap,
							valueStringToExtraData,
						);

						const zoneToDefaultValue = getZoneToDefaultValue(
							accurateValuesData,
							ptokDataDetails.defaultValuesByGeo,
							zoneToRegionMap,
						);

						productsData[productCode].ptokData[ptokId].values =
							accurateValuesData;
						productsData[productCode].ptokData[ptokId].defaultValuesByGeo =
							zoneToDefaultValue;
					},
				);
			},
		);

		return productsData;
	},
);

const getSelectedProductOptions = createSelector(
	getStateSlice,
	(slice) => slice.selectedProductOptions,
);

const getProductProperties = createSelector(
	getStateSlice,
	/** @return {ProductProperties} */
	(slice) => slice.productProperties || {},
);

const getSelectedProductRegions = createSelector(
	getStateSlice,
	(slice) => slice.selectedProductRegions,
);

/**
 * Details about a ptov
 * @typedef PtovDetails
 * @property {Number} ptokId - the ptokId the value is associated with.
 * @property {String} displayValue
 * @property {Boolean} isUnavailable
 * @property {Object} extraData
 */
const getPtovToDetailsMap = createSelector(
	getProductsData,
	/** @returns {Object.<string, PtovDetails>} */
	(productsData) => {
		const ptovToPtokMap = {};
		Object.values(productsData).forEach(({ ptokData }) =>
			Object.entries(ptokData).forEach(([ptokId, { values }]) => {
				Object.entries(values).forEach(
					([ptovId, { displayValue, isUnavailable, extraData }]) => {
						ptovToPtokMap[ptovId] = {
							ptokId: Number(ptokId),
							displayValue,
							isUnavailable,
						};

						if (extraData) {
							ptovToPtokMap[ptovId].extraData = extraData;
						}
					},
				);
			}),
		);
		return ptovToPtokMap;
	},
);

/**
 * Combines regions of items already in the basket with the selectedProductRegions object. The order matters here - the selectedProductRegions object should overwrite the basketItemRegionsMap since a user may edit the region of an item that is currently in the basket.
 */
const getAllSelectedProductRegions = createSelector(
	cartDetailsSelectors.getProductItemsToRegion,
	getSelectedProductRegions,
	getProductsData,
	networkZoneListSelectors.getDefaultRegion,
	/** @return {SelectedProductRegions} */
	(
		basketItemRegionsMap,
		selectedProductRegions,
		productsData,
		userDefaultRegion,
	) => {
		const allProductRegions = {
			...basketItemRegionsMap,
			...selectedProductRegions,
		};

		const productCodes = Object.keys(productsData);

		productCodes.forEach((productCode) => {
			if (!(productCode in allProductRegions)) {
				const defaultRegion = pickDefaultRegion({
					productCode,
					userDefaultRegion,
					regionIdPrices: productsData[productCode].regionIdPrices,
				});
				allProductRegions[productCode] = defaultRegion;
			}
		});

		return allProductRegions;
	},
);

/**
 * gets an array of the selected product option keys by combining productsData and selectedProductOptions.
 *
 * Combining the two is needed since resetOptionsToDefaults is used to initialize selectedProductOptions when pulling product data from the api. So in that case, productsData fills in the gap until resetOptionsToDefaults has completed.
 *
 * While the theory is more complex, it does make this selector reliable during initialization.
 */
const getSelectedProductOptionKeys = createSelector(
	getSelectedProductOptions,
	getProductsData,
	(productOptions, productsData) =>
		uniqueList([...Object.keys(productOptions), ...Object.keys(productsData)]),
);

/**
 * gets a map of all product keys in state to their respective product codes
 */
const getProductKeyToCodeMap = createSelector(
	...createMapSelectorArgs({
		itemsSelector: getSelectedProductOptionKeys,
		getValue: (productKey, productCodeMap, changedProductCodes) => {
			const uuid = isUUID(productKey) ? productKey : undefined;

			let productCode = productKey;

			if (uuid) {
				// If a changed product code is stored, use that. Otherwise, use what's in the basket.
				productCode = changedProductCodes[uuid] || productCodeMap[uuid];
			}

			return productCode;
		},
		forwardedSelectors: [
			cartDetailsSelectors.getCodesByUuid,
			getChangedProductCodes,
		],
	}),
);

/** gets a map of all options keys and ties them to all productCodes with their respsective ptokId */
const getKeyStringToProductCodeToPtok = createSelector(
	getProductsData,
	/** @return {Object.<string, Object.<string, String | Number>>} */
	(productsData) => {
		const ptokTransposeMap = {};
		Object.entries(productsData).forEach(([productCode, data]) => {
			Object.entries(data.ptokData).forEach(([ptokId, { key }]) => {
				if (!ptokTransposeMap[key]) {
					ptokTransposeMap[key] = {};
				}
				ptokTransposeMap[key][productCode] = Number(ptokId);
			});
		});
		return ptokTransposeMap;
	},
);

/** gets a map of all ptokIds and ties them to their respective options keys. */
const getPtokToKey = createSelector(getProductsData, (productsData) => {
	const ptokToKeyMap = {};
	Object.values(productsData).forEach((data) => {
		Object.entries(data.ptokData).forEach(([ptokId, { key }]) => {
			ptokToKeyMap[ptokId] = key;
		});
	});
	return ptokToKeyMap;
});

/** gets a map of all value strings and ties them to all productCodes with their respsective ptovId. */
const getKeyToValueToProductCodeToPtov = createSelector(
	getRawProductsData,
	/** @returns {Object.<string, Object.<string, Object.<string, String | Number >>>} */
	(productsData) => {
		const keyToValueToProductCodeToPtov = {};
		Object.entries(productsData).forEach(([productCode, data]) => {
			if (!data.ptokData) return;
			Object.values(data.ptokData).forEach(({ values, key }) => {
				Object.entries(values).forEach(([ptovId, { valueString }]) => {
					if (!keyToValueToProductCodeToPtov[key]) {
						keyToValueToProductCodeToPtov[key] = {};
					}
					if (!keyToValueToProductCodeToPtov[key][valueString]) {
						keyToValueToProductCodeToPtov[key][valueString] = {};
					}
					keyToValueToProductCodeToPtov[key][valueString][productCode] =
						Number(ptovId);
				});
			});
		});
		return keyToValueToProductCodeToPtov;
	},
);

/** A list of config keyStrings linked to valueStrings. These settings are ignored by getNewSelectionsObj */
const allowList = {
	ConfigId: configValueStrings.dynamicConfigId, // This is the setting that must be used when deploying a VPS onto a private parent.
};

/** gets a map linking product codes to which selections (ptok to ptov) should be left uncorrected when auditing a user's selections. */
const getProductCodeToAllowList = createSelector(
	getProductsData,
	getKeyStringToProductCodeToPtok,
	getKeyToValueToProductCodeToPtov,
	(
		productsData,
		keyStringToProductCodeToPtok,
		keyToValueToProductCodeToPtov,
	) => {
		const productCodeToAllowList = {};
		Object.keys(productsData).forEach((productCode) => {
			const allowListForProductCode = {};
			Object.entries(allowList).forEach(([keyString, valueString]) => {
				const ptok = keyStringToProductCodeToPtok[keyString]?.[productCode];
				const ptov =
					keyToValueToProductCodeToPtov[keyString]?.[valueString]?.[
						productCode
					];
				if (!ptok || !ptov) return; // This is what will happen in most cases.
				allowListForProductCode[
					keyStringToProductCodeToPtok[keyString][productCode]
				] = keyToValueToProductCodeToPtov[keyString][valueString][productCode];
			});
			productCodeToAllowList[productCode] = allowListForProductCode;
		});
		return productCodeToAllowList;
	},
);

/** gets a map of all ptovIds and ties them to their respective value strings. */
const getPtovToValueStringMap = createSelector(
	getProductsData,
	(productsData) => {
		const ptovToStringValueMap = {};
		Object.values(productsData).forEach((data) => {
			Object.values(data.ptokData).forEach(({ values }) => {
				Object.entries(values).forEach(([ptovId, { valueString }]) => {
					ptovToStringValueMap[ptovId] = valueString;
				});
			});
		});
		return ptovToStringValueMap;
	},
);

const getActiveProductCode = createSelector(
	getProductKeyToCodeMap,
	getActiveProductKey,
	(productKeyToCodeMap, activeProductKey) =>
		productKeyToCodeMap[activeProductKey],
);

const getActiveProductData = createSelector(
	getProductsData,
	getActiveProductCode,
	(data, productCode) => data?.[productCode],
);

const getActiveProductOptions = createSelector(
	getSelectedProductOptions,
	getActiveProductKey,
	(selectedProductOptions, activeProductKey) =>
		selectedProductOptions[activeProductKey] || {},
);

const getActiveProductRegion = createSelector(
	getAllSelectedProductRegions,
	getActiveProductKey,
	(selectedProductRegions, activeProductKey) =>
		selectedProductRegions[activeProductKey],
);

const getActiveProductSelectedBasePrice = createSelector(
	getActiveProductData,
	getActiveProductRegion,
	(data, region) => Number(data?.regionIdPrices?.[region]),
);

const getActiveProductIsInBasket = createSelector(
	getActiveProductKey,
	(activeProductKey) => isUUID(activeProductKey || ''),
);

const getActiveProductProperties = createSelector(
	getProductProperties,
	getActiveProductKey,
	(productProperties, activeProductKey) =>
		productProperties[activeProductKey] || {},
);

const getActiveProductSelectedZone = createSelector(
	getActiveProductProperties,
	(activeProperties) => activeProperties.zone,
);

/** retrives an object of ptoks to whether or not each has been revealed */
const getActiveRevealedOptions = createSelector(
	getRevealedHiddenOptions,
	getActiveProductKey,
	/** @returns {object.<string, boolean>} */
	(revealedHiddenOptions, productKey) => revealedHiddenOptions[productKey],
);

const getProductCodesToNeedsZone = createSelector(
	getProductsData,
	(productsData) => {
		const productCodesToNeedsZone = {};

		Object.entries(productsData).forEach(
			([productCode, productDataDetails]) => {
				productCodesToNeedsZone[productCode] = getNeedsZone(productDataDetails);
			},
		);

		return productCodesToNeedsZone;
	},
);

const getProductKeysToOptionKeysToGeo = createSelector(
	getProductKeyToCodeMap,
	getProductsData,
	getAllSelectedProductRegions,
	getProductProperties,
	/** @returns {ProductKeysToOptionKeysToGeo} */
	(
		productKeyToCodeMap,
		productsData,
		productKeysToRegion,
		productKeysToProperties,
	) => {
		const productKeysToOptionKeysToGeo = {};

		Object.keys(productKeyToCodeMap).forEach((productKey) => {
			productKeysToOptionKeysToGeo[productKey] = {};

			const region = productKeysToRegion[productKey];
			const zone = productKeysToProperties[productKey]?.zone;
			const productCode = productKeyToCodeMap[productKey];
			const { ptokData = {} } = productsData[productCode] || {};

			Object.values(ptokData).forEach(({ key }) => {
				const geo = pickGeo(key, { region, zone });

				productKeysToOptionKeysToGeo[productKey][key] = geo;
			});
		});

		return productKeysToOptionKeysToGeo;
	},
);

const getActiveOptionKeysToGeo = createSelector(
	getProductKeysToOptionKeysToGeo,
	getActiveProductKey,
	/** @return {OptionKeysToGeo} */
	(productKeysToOptionKeysToGeo, activeProductKey) =>
		productKeysToOptionKeysToGeo[activeProductKey],
);

/** gets a map linking itemUuids -> ptokIds -> whether or not the given ptok exists on the item */
const getProductKeyToPtokToHasValueInBasket = createSelector(
	cartDetailsSelectors.getNativeItems,
	/** @returns {Object.<string, Object.<string, boolean>>} */
	(items) => {
		const productKeyToPtokToHasValueInBasket = {};
		if (!items) return productKeyToPtokToHasValueInBasket;
		items.forEach(({ details: { options }, uuid }) => {
			if (!options) return;
			productKeyToPtokToHasValueInBasket[uuid] = Object.fromEntries(
				options.map(({ ptok_id: ptok }) => [ptok, true]),
			);
		});
		return productKeyToPtokToHasValueInBasket;
	},
);

/**
 * details specific to the ptok for a given product
 * @typedef PtokLocalDetails
 * @property {boolean} isDefaultSelected - is the default option of the ptok selected within the product?
 * @property {boolean} canBeVisible - can this option be visible even if the parentPtok is active and parentPtov selected?
 * @property {boolean} hasValueInBasket - does this option already have an assigned value in the basket?
 */

/** gets a map linking productCodes -> ptokIds -> details specific to the ptok for each given product */
const getProductKeyToPtokToLocalDetails = createSelector(
	getProductsData,
	getProductKeysToOptionKeysToGeo,
	getSelectedProductOptions,
	getProductKeyToCodeMap,
	getIsBasketAdmin,
	getProductKeyToPtokToHasValueInBasket,
	/** @returns {Object.<string, Object.<string PtokLocalDetails>>} */
	(
		productsData,
		productKeysToOptionKeysToGeo,
		selectedOptions,
		keyToCodeMap,
		isAdmin,
		productKeyToPtokToHasValueInBasket,
	) => {
		const productKeyToPtokToLocalDetails = {};
		Object.entries(selectedOptions).forEach(([productKey, options]) => {
			if (!productKeyToPtokToLocalDetails[productKey]) {
				productKeyToPtokToLocalDetails[productKey] = {};
			}
			const ptokToHasValueInBasket =
				productKeyToPtokToHasValueInBasket[productKey];
			const optionKeysToGeo = productKeysToOptionKeysToGeo[productKey];
			const productCode = keyToCodeMap[productKey];
			if (!productsData[productCode]) return;

			const { ptokData } = productsData[productCode];
			if (!ptokData) return;

			Object.entries(ptokData).forEach(([ptokId, data]) => {
				const hasValueInBasket = Boolean(ptokToHasValueInBasket?.[ptokId]);
				const geo = optionKeysToGeo[data.key];
				const selectedValue = options[ptokId]?.value;
				// Is the default value selected?
				const defaultValue = data?.defaultValuesByGeo?.[geo];
				const isDefaultSelected =
					selectedValue !== undefined
						? isNumEqualIsh(defaultValue, selectedValue)
						: true; // undefined counts as being default since that is what would happen.

				// Can this ptok be visible by selecting the right things?
				let canBeVisible = false; // is left false unless any condition is met.
				const isPublic = data?.isPublic;

				// public options can always be visible.
				if (isPublic) canBeVisible = true;
				// Hidden options can be visible if the user is an admin.
				if (isAdmin) canBeVisible = true;
				// Even if something is hidden and the user is not an admin, we still want to display the option if something already has value in the basket.
				// It is common for an admin to select a hidden option for a customer. The customer should be able to see the option if the selection is not the default.
				if (hasValueInBasket) canBeVisible = true;

				productKeyToPtokToLocalDetails[productKey][ptokId] = {
					isDefaultSelected,
					canBeVisible,
					hasValueInBasket,
				};
			});
		});
		return productKeyToPtokToLocalDetails;
	},
);

const getActivePtokToLocalDetails = createSelector(
	getProductKeyToPtokToLocalDetails,
	getActiveProductKey,
	/** @returns {Object.<string, PtokLocalDetails>} */
	(ptokToDefaultSelectedMap, activeProductKey) =>
		ptokToDefaultSelectedMap[activeProductKey],
);

/** gets a map of ptoks to whether or not they're hidden options. */
const getPtokToIsHidden = createSelector(
	getProductsData,
	/** @returns {object.<string boolean>} */
	(productsData) => {
		const ptokToIsPublic = {};
		if (!productsData) return ptokToIsPublic;
		Object.values(productsData).forEach(({ ptokData }) => {
			Object.entries(ptokData).forEach(([ptokId, { isPublic }]) => {
				if (!isPublic) ptokToIsPublic[ptokId] = true;
			});
		});
		return ptokToIsPublic;
	},
);

/**
 * @param {String} processorValueString
 * @example parseCoresAndSockets(Intel.E31230.v5.4cores.1socket) -> { cores: 4, sockets: 1 }
 */
const parseCoresAndSockets = (processorValueString) => {
	const [, cores, sockets] =
		processorValueString.match(/\.(\d+)core(?:s)?\.(\d+)socket(?:s)?$/) || [];

	return { cores: Number(cores), sockets: Number(sockets) };
};

const selectMsSqlNumUnits = (hasMsSql, amount) => {
	if (!hasMsSql) {
		return undefined;
	}

	if (!Number(amount)) {
		return 4;
	}

	return Math.max(amount, 4);
};

/** gets a map of product keys to special config options */
const getProductKeyToSpecialConfigs = createSelector(
	getProductsData,
	getSelectedProductOptions,
	getKeyStringToProductCodeToPtok,
	getProductKeyToCodeMap,
	getKeyToValueToProductCodeToPtov,
	getProductKeysToOptionKeysToGeo,
	/** @returns {Object.<string, {msSqlNumUnits: number, windowsLicensePtovId: number | string} >} */
	(
		productsData,
		selectedProductOptions,
		keyStringToProductCodeToPtok,
		productKeyToCode,
		keyToValueToProductCodeToPtov,
		productKeysToOptionKeysToGeo,
	) => {
		const productKeyToSepecialConfigs = {};
		const windowsLicenseValueStringsToProductCodeToPtov =
			keyToValueToProductCodeToPtov.WindowsLicense;

		Object.keys(selectedProductOptions).forEach((productKey) => {
			const productCode = productKeyToCode[productKey];
			if (!productsData[productCode]) return;
			const ptokDataDetails = productsData[productCode].ptokData;
			const hasMsSql = Boolean(
				keyStringToProductCodeToPtok.MsSQL?.[productCode],
			);

			const configIdPtokId =
				keyStringToProductCodeToPtok.ConfigId?.[productCode];

			// Should AssignedCores need be be synced with MsSQL, try pulling feature/sync-assigned-cores-to-mssql.
			if (configIdPtokId) {
				const selectedConfigIdValue =
					selectedProductOptions[productKey][configIdPtokId]?.value;
				const configIdPtokDataDetails = ptokDataDetails[configIdPtokId];
				const selectedVcpus =
					configIdPtokDataDetails.values[selectedConfigIdValue]?.extraData
						?.vcpu;
				const selectedCores =
					configIdPtokDataDetails.values[selectedConfigIdValue]?.extraData
						?.cores;

				let windowsLicensePtovId;
				const windowsLicensePtokId =
					keyStringToProductCodeToPtok.WindowsLicense?.[productCode];

				if (windowsLicensePtokId) {
					const selectedWindowsLicenseGeo =
						productKeysToOptionKeysToGeo[productKey].WindowsLicense;
					const defaultWindowsLicensePtovId =
						ptokDataDetails[windowsLicensePtokId]?.defaultValuesByGeo[
							selectedWindowsLicenseGeo
						];

					const [, productCodeToWindowsLicensePtovId] =
						Object.entries(windowsLicenseValueStringsToProductCodeToPtov).find(
							([windowsLicenseValueString]) => {
								const [cores] =
									windowsLicenseValueString.split('CoreWin') || [];
								return Number(cores) === selectedCores;
							},
						) || [];

					windowsLicensePtovId =
						productCodeToWindowsLicensePtovId?.[productCode] ||
						defaultWindowsLicensePtovId;
				}

				productKeyToSepecialConfigs[productKey] = {
					msSqlNumUnits: selectMsSqlNumUnits(hasMsSql, selectedVcpus),
					windowsLicensePtovId,
				};
			}

			const processorPtokId =
				keyStringToProductCodeToPtok.Processor?.[productCode];

			if (processorPtokId) {
				const selectedProcessorValue =
					selectedProductOptions[productKey][processorPtokId].value;
				const processorPtokDataDetails = ptokDataDetails[processorPtokId];

				const processorValueString =
					processorPtokDataDetails.values[selectedProcessorValue].valueString;

				const { cores, sockets } = parseCoresAndSockets(processorValueString);

				const totalCores = cores * sockets;

				productKeyToSepecialConfigs[productKey] = {
					msSqlNumUnits: selectMsSqlNumUnits(hasMsSql, totalCores),
				};
			}

			const vCpuPtokId = keyStringToProductCodeToPtok.VMvCPU?.[productCode];

			if (vCpuPtokId) {
				const vcpus = selectedProductOptions[productKey][vCpuPtokId]?.numUnits;

				productKeyToSepecialConfigs[productKey] = {
					msSqlNumUnits: selectMsSqlNumUnits(hasMsSql, vcpus),
				};
			}
		});

		return productKeyToSepecialConfigs;
	},
);

const getActiveSpecialConfigs = createSelector(
	getProductKeyToSpecialConfigs,
	getActiveProductKey,
	(productKeyToSpecialConfigs, activeKey) =>
		productKeyToSpecialConfigs[activeKey],
);

/** determines whether or not a server can be cloned */
const getCannotClone = createSelector(
	assetDetailsSelectors.getFeatureDetails,
	getPtovToDetailsMap,
	(featureDetails, ptovToDetailsMap) =>
		Object.values(featureDetails).some((details) => {
			if (ptovToDetailsMap?.[details.ptov_id]?.isUnavailable) {
				return true;
			}

			if ('active' in details && !isOneIsh(details.active)) {
				return true;
			}

			return false;
		}),
);

/** returns true is the product has the property, `uniq_id_for_clone` */
const getActiveIsClone = createSelector(
	getActiveProductProperties,
	(properties) => Boolean(properties?.uniq_id_for_clone),
);

/** returns true if two options depend upon each other. This would cause stack overflow. */
const getProductCodeToHasCircularRef = createSelector(
	getProductsData,
	(productsData) => {
		if (!productsData) return false;
		const productKeyToHasCircularRef = {};
		Object.entries(productsData).forEach(([productCode, { ptokChildMap }]) => {
			const searchForSelfInChildren = ({ ancestor, parent }) => {
				const children = ptokChildMap[parent];
				if (!children) return false;
				return children.some((child) => {
					// raise the alarm, this product will ruin everything!
					if (isNumEqualIsh(child, ancestor)) return true;
					return searchForSelfInChildren({ ancestor, parent: child });
				});
			};

			productKeyToHasCircularRef[productCode] = Object.entries(
				ptokChildMap,
			).some(([parent, children]) => {
				return children.some((child) => {
					return searchForSelfInChildren({ ancestor: parent, parent: child });
				});
			});
		});
		return productKeyToHasCircularRef;
	},
);

const getActiveProductHasCircularRef = createSelector(
	getProductCodeToHasCircularRef,
	getActiveProductCode,
	(productCodeToHasCircularRef, activeProductCode) =>
		productCodeToHasCircularRef[activeProductCode],
);

/**
 * Data describing a given ptok's rootPtok
 * @typedef AncestralData
 * @property {string|number} ptokId - The ptokId of a given ptok's ancestor.
 * @property {string} group - the group name of a given ptok's ancestor.
 */

/**
 * @param {Object} param0
 * @param {string|number} param0.ptokId
 * @param {PtokData} param0.ptokData
 * @return {AncestralData}
 */
const ancestralDataCalc = ({ ptokId, ptokData }) => {
	if (!ptokData[ptokId]) {
		// eslint-disable-next-line no-console
		console.error(`${ptokId} could not be found in ptokData`);
		return undefined;
	}
	const { parentPtokId, group } = ptokData[ptokId];
	if (parentPtokId)
		return ancestralDataCalc({
			ptokId: parentPtokId,
			ptokData,
		});
	return { group, ptokId };
};

/** Returns a map linking productCodes to ptoks to their ancestral data. */
const getProductCodeToPtokToAncestralData = createSelector(
	getRawProductsData,
	getProductCodeToHasCircularRef,
	/** @returns {Object.<string, Object.<string, AncestralData>>} */
	(productsData, productCodeToHasCircularRef) => {
		const productCodeToPtokToAncestralData = {};
		Object.entries(productsData).forEach(([productCode, { ptokData }]) => {
			productCodeToPtokToAncestralData[productCode] = {};
			if (productCodeToHasCircularRef[productCode]) {
				// this will not go well
				return;
			}
			Object.keys(ptokData).forEach((ptokId) => {
				if (productCodeToPtokToAncestralData[productCode][ptokId]) return;
				productCodeToPtokToAncestralData[productCode][ptokId] =
					ancestralDataCalc({
						ptokId,
						ptokData,
					});
			});
		});
		return productCodeToPtokToAncestralData;
	},
);

const getActivePtokToAncestralData = createSelector(
	getProductCodeToPtokToAncestralData,
	getActiveProductCode,
	(productKeyToPtokToAncestralData, activeProductCode) =>
		productKeyToPtokToAncestralData[activeProductCode],
);

/**
 * Each ptok that is a descendant of a revealed ancestor is included in this map.
 */
const getProductKeyToPtokToIsRevealed = createSelector(
	getProductCodeToPtokToAncestralData,
	getRevealedHiddenOptions,
	getProductsData,
	getProductKeyToCodeMap,
	/** @returns {Object.<string, Object.<string, boolean>>} */
	(
		productCodeToPtokToAncestralData,
		revealedOptions,
		productData,
		keyToCodeMap,
	) => {
		const productCodeToPtokToIsRevealed = {};
		if (!productData) return productCodeToPtokToIsRevealed;
		Object.keys(revealedOptions).forEach((productKey) => {
			const productCode = keyToCodeMap[productKey];
			const { ptokData } = productData[productCode];
			productCodeToPtokToIsRevealed[productKey] = {};
			Object.keys(ptokData).forEach((ptokId) => {
				if (
					revealedOptions[productKey]?.[
						productCodeToPtokToAncestralData[productCode][ptokId].ptokId
					]
				) {
					productCodeToPtokToIsRevealed[productKey][ptokId] = true;
				}
			});
		});
		return productCodeToPtokToIsRevealed;
	},
);

const getActivePtokToIsRevealed = createSelector(
	getProductKeyToPtokToIsRevealed,
	getActiveProductKey,
	(productCodetoPtokToIsRevealed, activeProductkey) =>
		productCodetoPtokToIsRevealed[activeProductkey],
);

/** Gets a map of product keys to option key strings to the options' selected ptovs. Does not include inactivated options */
const getProductKeyToKeyStringToSelectedValue = createSelector(
	getSelectedProductOptions,
	getPtokToKey,
	/** @return {Object.<string, Object.<string, String | Number >>} { [productKey] : { [keyString]: [ptovId] } } */
	(selectedProductOptions, ptokToKeyString) => {
		const productKeyToKeyStringToSelectedValue = {};

		Object.entries(selectedProductOptions).forEach(
			([productKey, productOptions]) => {
				Object.entries(productOptions).forEach(
					([ptokId, { active, value }]) => {
						const keyString = ptokToKeyString[ptokId];

						if (!productKeyToKeyStringToSelectedValue[productKey]) {
							productKeyToKeyStringToSelectedValue[productKey] = {};
						}

						if (active) {
							productKeyToKeyStringToSelectedValue[productKey][keyString] =
								value;
						}
					},
				);
			},
		);

		return productKeyToKeyStringToSelectedValue;
	},
);

const productConfigSelectors = {
	getProductsData,
	getActiveOptionKeysToGeo,
	getActiveRevealedOptions,
	getActiveProductKey,
	getActiveProductCode,
	getActiveProductData,
	getActiveProductHasCircularRef,
	getProductCodeToHasCircularRef,
	getActiveIsClone,
	getActiveProductProperties,
	getActiveProductRegion,
	getActiveProductIsInBasket,
	getActiveProductOptions,
	getActiveProductSelectedBasePrice,
	getActiveProductSelectedZone,
	getActivePtokToAncestralData,
	getActivePtokToIsRevealed,
	getActivePtokToLocalDetails,
	getActiveSpecialConfigs,
	getAllSelectedProductRegions,
	getCannotClone,
	getChangedProductCodes,
	getPtokToIsHidden,
	getPtokToKey,
	getKeyStringToProductCodeToPtok,
	getProductKeyToSpecialConfigs,
	getProductCodeToPtokToAncestralData,
	getProductKeyToPtokToIsRevealed,
	getPtovToValueStringMap,
	getKeyToValueToProductCodeToPtov,
	getProductCodesToNeedsZone,
	getProductCodeToAllowList,
	getProductKeysToOptionKeysToGeo,
	getProductProperties,
	getProductKeyToCodeMap,
	getProductKeyToPtokToLocalDetails,
	getProductKeyToKeyStringToSelectedValue,
	getPtovToDetailsMap,
	getRawProductsData,
	getTemplatesShowValue,
	getRevealedHiddenOptions,
	getSelectedProductOptions,
	getStateSlice,
	getGroupsExpanded,
	isLoading,
	getQuickaddLoadingMap,
};

export default productConfigSelectors;
