import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.account.chatpassword.set;

const {
	actions,
	reducer,
	sagas,
	selectors: moduleSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_CHATPASSWORD_SET',
	url: '/account/chatpassword/set.json',
});

const selectors = {
	supportPassphrase: createSelector(moduleSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'password']),
	),
	...moduleSelectors,
};

export { actions, reducer, sagas, selectors };
