import {
	cartDetailsActions,
	cartDetailsSelectors,
} from 'modules/api/market/cart/detailsModule';
import { put, select } from 'redux-saga/effects';

/** @returns {import('club-sauce/public/market/cart/index.raw').LWApiPublicMarketCartDetailsResultRawI} */
function* mergeOldCartWithNew({ newCartPortion }) {
	const oldCartDetails = yield select(cartDetailsSelectors.getNativeData);
	const newCartDetails = { ...oldCartDetails, ...newCartPortion };

	yield put(cartDetailsActions.override(newCartDetails));

	return newCartDetails;
}

export default mergeOldCartWithNew;
