import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import LWTypography from 'components/common/LWTypography';
import QuickAddButton from 'components/connectedMolecules/QuickAddButton';

import { perMonth, currency } from 'utility/format';
import getItemPricing from 'utility/products/getItemPricing';

const Price = ({
	itemPriceTotal,
	itemPrepayTotal,
	itemDiscountTotal,
	upsellDiscount, // from drillToContent
	isLoading = false,
	productCode, // from drillToContent
	variant, // from drillToContent
}) => {
	const skeletonWidths = {
		main: '80px',
	};

	const itemPricing = getItemPricing({
		itemPriceTotal,
		itemPrepayTotal,
		itemDiscountTotal,
	});

	const { monthlyPrice, onetimePrice, isMonthly, itemDiscountAmount } =
		itemPricing;

	const upsellIsMonthly = !!upsellDiscount?.fullPrice?.month;
	const upsellIsLifetime = !!upsellDiscount?.lifetime;
	const upsellDiscountContent = `${upsellDiscount?.percentOff}% OFF${
		upsellIsLifetime ? ' Lifetime Discount' : ''
	}`;

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="flex-end"
			flexGrow={1}
		>
			<Box display="flex">
				{!!upsellDiscount && (
					<>
						<LWTypography
							color="palette.secondary.main"
							isLoading={isLoading}
							bold
							BoxProps={{
								marginY: 0.5,
								textAlign: 'right', // makes sure that if the price wraps, it still aligns right.
							}}
							SkeletonProps={{ width: skeletonWidths.main }}
						>
							{upsellDiscountContent}
						</LWTypography>
						{!upsellIsLifetime && (
							<LWTypography
								color="palette.text.disabled"
								isLoading={isLoading}
								strikeThrough
								BoxProps={{
									marginLeft: 3,
									marginY: 0.5,
									textAlign: 'right', // makes sure that if the price wraps, it still aligns right.
								}}
								SkeletonProps={{ width: skeletonWidths.main }}
							>
								{upsellIsMonthly
									? perMonth(upsellDiscount?.fullPrice?.month)
									: currency(upsellDiscount?.fullPrice?.['one-time'])}
							</LWTypography>
						)}
					</>
				)}
				<LWTypography
					variant="subtitle1"
					semiBold
					isLoading={isLoading}
					BoxProps={{
						marginLeft: 3,
						marginY: 0.5,
						textAlign: 'right', // makes sure that if the price wraps, it still aligns right.
					}}
					SkeletonProps={{ width: skeletonWidths.main }}
				>
					{isMonthly ? perMonth(monthlyPrice) : currency(onetimePrice)}
				</LWTypography>
			</Box>
			{!!itemDiscountAmount && (
				<Box display="flex" alignItems="center">
					<LWTypography variant="body1" color="palette.text.disabled">
						Discount
					</LWTypography>
					<LWTypography
						color="palette.success.main"
						variant="subtitle1"
						semiBold
						isLoading={isLoading}
						BoxProps={{
							marginY: 0.5,
							marginLeft: 1,
						}}
					>
						-
						{isMonthly
							? perMonth(itemDiscountAmount)
							: currency(itemDiscountAmount)}
					</LWTypography>
				</Box>
			)}
			{!isLoading && variant === 'upsell' && (
				<QuickAddButton
					showText="bottom"
					productCode={productCode}
					BoxProps={{ mt: 1 }}
				/>
			)}
		</Box>
	);
};

Price.propTypes = {
	itemPriceTotal: PropTypes.shape({
		month: PropTypes.number,
		'static-month': PropTypes.number,
		'one-time': PropTypes.number,
	}),
	itemPrepayTotal: PropTypes.number,
	itemDiscountTotal: PropTypes.shape({
		month: PropTypes.number,
		'static-month': PropTypes.number,
		'one-time': PropTypes.number,
	}),
	isLoading: PropTypes.bool,
};

export default Price;
