const getId = (state, props) => props.id;
const getUniqId = (state, props) => props.uniqId;

/**
 * Creates a key/item map using a itemsSelector and a getValue function.
 *
 * After `itemsSelector` executes, the resultant array is then iterated over to produce a map of the results. The key is the value of the property in the item specified by the `keyProp`. The value is the result of the `getValue` function.
 *
 * `getValue` can also receive additional args from `forwardedSelectors`. These args end up being the results of those additional selectors.
 *
 * @example
 * const args = createSelector({
	...createMapSelectorArgs({
		itemsSelector: getBasketItems,
		keyProp: 'uuid',
		forwardedSelectors: [getItemAuxPropsMap],
		getValue: ({ uuid, productInfo }, auxPropsMap) => {
			const itemAuxProps = auxPropsMap[uuid];
			const { product_description: productDescription, title } =
				productInfo || {};
			return {
				title: itemAuxProps?.title || title || productDescription || '',
			};
		},
	}),
});
 * @param {function} param0.itemsSelector - a selector that returns an array to iterate over
 * @param {function} param0.getValue - a function that receives each item and the "forwardedValues" from the "forwardedSelectors". It then returns the value
 * @param {function[]} [param0.forwardedSelectors] - selectors which pass on their values to getValue.
 * @param {string} [param0.keyProp=uniq_id] - the property to use for a key if itemsSelector returns objects.
 * @returns {array} array of args design to be passed into createSelector using the expand operator (...)
 */
const createMapSelectorArgs = ({
	itemsSelector,
	getValue,
	forwardedSelectors = [],
	keyProp = 'uniq_id',
}) => [
	itemsSelector,
	...forwardedSelectors,
	(items, ...forwardedValues) =>
		Object.fromEntries(
			items.map((item) => {
				// if item is a string, we just want to return the string.
				const key = typeof item === 'object' ? item[keyProp] : item;
				return [key, getValue(item, ...forwardedValues)];
			}),
		),
];

export { getId, getUniqId, createMapSelectorArgs };
