import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.billing.paypal.payment.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BILLING_PAYPAL_PAYMENT_DETAILS',
	url: '/billing/paypal/payment/details.json',
});

const selectors = {
	requestedAmount: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'requested_amount']),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
