import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import LWDialog from 'components/common/LWDialog';
import unblockIPDialogActions from 'modules/unblockIPDialog/unblockIPDialogActions';
import unblockIPDialogSelectors from 'modules/unblockIPDialog/selectors';
import {
	actions as unblockIPActions,
	selectors as unblockIPSelectors,
} from 'modules/api/server/WHM/firewall/unblockipModule';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { selectors as listSelectors } from 'modules/api/asset/listModule';
import UnblockIPForm from './UnblockIpForm';
import UnblockIPSupportForm from './UnblockIpSupportForm';

const styles = () => ({
	root: {},
});

/**
 * @param {object} prop
 * @param {import('modules/queries/asset/useDetails').AssetDetails} prop.serverDetails
 */
const UnblockIPDialog = ({
	classes,
	closeDialog,
	isOpen,
	serverDetails,
	submitUnblockIP,
	data = null,
	isLoading,
	hasError,
	error,
	openSnackbar,
	cPanelServerList,
}) => {
	const [unblockIPSupportDialogIsOpen, setunblockIPSupportDialogIsOpen] =
		useState(false);
	const [supportIPDetails, setSupportIPDetails] = useState(null);
	const [errorMsg, setErrorMsg] = useState(null);
	const [showTextField, setShowTextField] = useState(false);
	const onDialogClose = useCallback(() => {
		setunblockIPSupportDialogIsOpen(false);
		setShowTextField(false);
		closeDialog();
	}, [setunblockIPSupportDialogIsOpen, setShowTextField, closeDialog]);

	useEffect(() => {
		if (data) {
			onDialogClose();
			openSnackbar(data.data);
		}
	}, [data, onDialogClose, openSnackbar, error]);

	useEffect(() => {
		if (error && hasError) {
			setunblockIPSupportDialogIsOpen(true);
			if (typeof error === 'string') {
				setErrorMsg(error);
			} else {
				setErrorMsg(error.getIn(['data', 'full_message']));
			}
		}
	}, [hasError, error]);

	const handleSubmitUnblockIPSetValues = (values) => {
		if (!values.serverUniqId) {
			//
			// eslint-disable-next-line no-param-reassign
			values.serverUniqId = serverDetails.uniq_id;
		}
		const serverDetailsForSupportForm = cPanelServerList.find(
			(x) => x.uniq_id === values.serverUniqId,
		);

		const serverDetailsForSupportDialog = {
			ip: values.ip,
			server: serverDetailsForSupportForm || serverDetails,
		};
		setSupportIPDetails(serverDetailsForSupportDialog);
		submitUnblockIP(values);
	};

	return (
		<LWDialog
			title="Unblock IPs"
			onClose={onDialogClose}
			maxWidth="sm"
			fullWidth
			open={isOpen}
			className={classes.root}
		>
			{unblockIPSupportDialogIsOpen ? (
				<UnblockIPSupportForm
					serverDetails={supportIPDetails}
					errorMsg={errorMsg}
					onClose={onDialogClose}
					showTextField={showTextField}
					setShowTextField={setShowTextField}
				/>
			) : (
				<UnblockIPForm
					serverDetails={serverDetails}
					onClose={onDialogClose}
					onSubmit={(values) => handleSubmitUnblockIPSetValues(values)}
					submitPending={isLoading}
				/>
			)}
		</LWDialog>
	);
};

UnblockIPDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	closeDialog: PropTypes.func.isRequired,
	submitUnblockIP: PropTypes.func.isRequired,
	hasError: PropTypes.bool,
	data: PropTypes.object,
	openSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	isOpen: unblockIPDialogSelectors.isOpen(state),
	serverDetails: unblockIPDialogSelectors.serverDetails(state),
	data: unblockIPSelectors.getNativeData(state),
	isLoading: unblockIPSelectors.isLoading(state),
	hasError: unblockIPSelectors.hasError(state),
	error: unblockIPSelectors.getError(state),
	cPanelServerList: listSelectors.getcPanelServers(state),
});

const mapDispatchToProps = (dispatch) => ({
	closeDialog: () => dispatch(unblockIPDialogActions.toggleDialog(false)),
	submitUnblockIP: ({ ip, serverUniqId, whitelist }) =>
		dispatch(unblockIPActions.fetch({ ip, uniq_id: serverUniqId, whitelist })),
	openSnackbar: (message, color) =>
		dispatch(snackbarActions.pushMessage({ message, variant: color })),
});

export { UnblockIPDialog };

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
)(UnblockIPDialog);
