import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.account.details;

const moduleKeys = {
	MRC: 'mrc',
	contact: 'contact',
};

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_DETAILS',
	url: '/account/details.json',
});

const mrcSelectors = createSelectors(getStateSlice, false, moduleKeys.MRC);
const contactSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.contact,
);

const getAccountMRC = createSelector(
	mrcSelectors.getData,
	(data) => data && parseFloat(data.get('mrc')),
);

const getHighlights = createSelector(defaultSelectors.getStateSlice, (slice) =>
	slice.getIn(['data', 'highlights']),
);

const getAccountStatus = createSelector(
	defaultSelectors.getStateSlice,
	(slice) => slice.getIn(['data', 'account_status']),
);

const getCustomerLifecycle = createSelector(
	defaultSelectors.getNativeData,
	(accountDetails) => accountDetails?.customerLifecycle,
);

const selectors = {
	getAccnt: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'accnt']) || '',
	),
	accountContact: createSelector(
		contactSelectors.getNativeData,
		(data) => data && data.accountContact,
	),
	getIsBeyondHosting: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'businessUnit']) === 'beyondhosting',
	),
	managementPortal: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'managementPortal']),
	),
	getAccountMRC,
	getAccountStatus,
	getHighlights,
	getCustomerLifecycle,
	getSecureNotesCount: createSelector(getHighlights, (highlights) => {
		if (highlights) {
			const found = highlights.find((highlight) => {
				return highlight.get('key') === 'secure-notes';
			});
			if (found) {
				return Number(found.get('count'));
			}
		}
		return null;
	}),
	referAFriendCode: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'referAFriend']),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors, moduleKeys };
