const uat = {
	baseURL: 'https://api-public.newman.dev.liquidweb.com/',
	session: {
		length: 900,
		warningTime: 150,
	},
	hosts: {
		www: 'dev-marketing.liquidweb.com',
		cart: 'cart.newman.dev.liquidweb.com',
		manage: 'manage.newman.dev.liquidweb.com',
		salesforce: 'liquidweb--developer.sandbox.lightning.force.com/lightning/r',
		customer_community: 'liquidweb--developer.sandbox.my.site.com/customer/s',
		managed_apps: 'app.dev.liquidweb.com',
		authorize_net: 'test.authorize.net',
		gator: 'gator.dev.liquidweb.com',
	},
	gaTrackerId: 'UA-106560627-3',
	gtmContainerId: 'GTM-KJQGDTD',
	cartStack: {
		siteId: 'k5FaX1lK',
		trackingScriptUrl:
			'https://api.cartstack.com/js/customer-tracking/cart.msmallwood.dev.liquidweb.com:20250_e26c5744de270f74b9fb65dd70ae774c.js',
	},
	guestAuth: {
		username: 'remote-signup',
		password: 'r3M0t3s|gnup',
	},
	cart: {
		checkoutURL: 'https://dev-marketing.liquidweb.com/cart/checkout',
	},
	chatConfig: {
		analyticsActionContext: 'demo',
		storageDomain: 'dev.liquidweb.com',
		baseCoreURL: 'https://liquidweb--developer.sandbox.my.salesforce.com',
		communityEndpointURL:
			'https://liquidweb--developer.sandbox.my.site.com/public',
		gslbBaseURL: 'https://liquidweb--developer.sandbox.lightning.force.com',
		orgId: '00DWB000001GBYP',
		eswConfigDevName: 'Liquidweb',
		settings: {
			baseLiveAgentContentURL:
				'https://c.la2s-core1.sfdc-8tgtt5.salesforceliveagent.com/content',
			deploymentId: '5720c0000004aCZ',
			buttonId: '5730c0000004aJB',
			baseLiveAgentURL:
				'https://d.la2s-core1.sfdc-8tgtt5.salesforceliveagent.com/chat',
			eswLiveAgentDevName:
				'EmbeddedServiceLiveAgent_Parent04I3a000000GmbvEAC_17ce2e46f8c',
			isOfflineSupportEnabled: false,
			chatbotAvatarImgURL:
				'https://media.liquidweb.com/salesforce/img/liquid-web-logo.jpg',
		},
	},
	openId: {
		client_id: 'Pnxy9HvmTjqjaXofYfTweg',
		authority: 'https://login.dev.liquidweb.com',
		accessTokenExpiringNotificationTime: 1200,
	},
};

export default uat;
