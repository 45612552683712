import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import { getId } from 'utility/redux/selectorHelperFunctions/common';

const getStateSlice = (state) => state.api.storm.image.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORM_IMAGE_LIST',
	method: 'POST',
	url: '/storm/image/list.json',
});

const getAllImagesSorted = createSelector(
	defaultSelectors.getNativeItems,
	(images) =>
		[...images].sort(
			({ time_taken: a }, { time_taken: b }) => new Date(b) - new Date(a),
		),
);

const getListForCurrentAsset = createSelector(
	defaultSelectors.getNativeItems,
	assetDetailsSelectors.uniqId,
	(items, uniqId) =>
		items?.filter(({ source_uniq_id: srcId }) => srcId === uniqId) || [],
);

const sortedByDate = createSelector(getListForCurrentAsset, (slice) =>
	[...slice].sort(
		({ time_taken: a }, { time_taken: b }) => new Date(a) - new Date(b),
	),
);

const selectedGenerator = (id) =>
	createSelector(
		defaultSelectors.getNativeItems,
		(slice) => slice.find((image) => image.id === id) || {},
	);

const selectors = {
	getAllImagesSorted,
	totalSize: createSelector(getListForCurrentAsset, (slice) =>
		slice.reduce((total, { size }) => total + Number(size), 0).toFixed(2),
	),
	itemTotal: createSelector(
		getListForCurrentAsset,
		assetDetailsSelectors.uniqId,
		(list) => list.length,
	),
	oldest: createSelector(sortedByDate, (slice) => (slice ? slice[0] : null)),
	newest: createSelector(sortedByDate, (slice) =>
		slice ? slice.slice(-1)[0] : null,
	),
	selected: createSelector(
		defaultSelectors.getNativeItems,
		getId,
		(slice, id) => slice.find((image) => image.id === id),
	),
	selectedGenerator,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
