/* The goal with storyOrTest is to exclusively use mock data for explicitly
determined domains in explicitly determined modules.

So if there's an api you want mock in our dev environment, you'd still need to
pass true explicitly. The only thing this code will change is modules that call
this function when they are called from storybook or a test. */

const testing = process.env.NODE_ENV === 'test';
export { testing };
const storyOrTest = () => {
	const { hostname, port } = new URL(window.location.href);
	if (
		port === '6006' || // local storybook
		hostname === 'techdev.lwpages.com' // production storybook
	) {
		return 'storybook';
	}
	if (testing) {
		return 'test';
	}
	return false;
};

export default storyOrTest;
