import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectors as routeSelectors } from 'modules/route';
import { chatSelectors, chatActions } from 'modules/chat';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import ChatIcon from '@material-ui/icons/QuestionAnswerRounded';

import LWTypography from 'components/common/LWTypography';
import StatusSpinner from 'components/atoms/StatusSpinner';

const SDiv = styled.div`
	position: fixed;
	z-index: ${(p) => p.theme.zIndex.chatLoader};
	bottom: ${({ $isMobile }) => ($isMobile ? '24px' : 0)};
	right: ${({ theme }) => theme.spacing(3)}px;
	cursor: ${({ $isLoading }) => ($isLoading ? 'auto' : 'pointer')};
`;

const getChatRoot = () => document.querySelector('.embeddedServiceSidebar');
const getChatWindow = () => getChatRoot()?.querySelector('[role=dialog]');
const getChatButton = () => getChatRoot()?.querySelector('button');

const chatButtonTestIds = { ChatButton__container: 'ChatButton__container' };
const ChatButton = () => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const chatContainerRef = useRef();

	// selectors
	const chatIsActive = useSelector(chatSelectors.getIsActive);
	const isLoading = useSelector(chatSelectors.getIsStarting);
	const showSimplifiedFooter = useSelector(routeSelectors.showSimplifiedFooter);

	// Fires callback via observer when width changes.
	useEffect(() => {
		const callback = () => {
			const { clientWidth, childNodes } = chatContainerRef.current;
			const width = childNodes.length ? clientWidth + theme.spacing(6) : 0;
			dispatch(chatActions.setWidth(width));
		};
		const observer = new ResizeObserver(callback);
		observer.observe(chatContainerRef.current);
		callback();
		return () => observer.disconnect();
	}, [dispatch, theme]);

	useEffect(() => {
		const callback = () => {
			dispatch(chatActions.setActive(Boolean(getChatRoot())));
		};
		const observer = new MutationObserver(callback);
		observer.observe(document.body, { childList: true });
		callback();
		return () => observer.disconnect();
	}, [dispatch]);

	// Sets the chat with according to the now opened window when change becomes active.
	useEffect(() => {
		const callback = () => {
			const chatWindow = getChatWindow();
			const chatButton = getChatButton();
			const width = Math.max(
				chatWindow?.clientWidth || 0,
				chatButton?.clientWidth || 0,
			);
			dispatch(chatActions.setWidth(Number(width) + theme.spacing(6)));
		};

		const observer = new ResizeObserver(callback);

		if (chatIsActive) {
			observer.observe(getChatWindow());
			callback();
		}

		return () => {
			if (chatIsActive) {
				observer.disconnect();
			}
		};
	}, [chatIsActive, dispatch, theme]);

	return (
		<SDiv
			$isMobile={isMobile}
			$isLoading={isLoading}
			onClick={
				isLoading
					? () => {}
					: () => {
							dispatch(chatActions.startChat());
						}
			}
			data-testid={chatButtonTestIds.ChatButton__container}
			ref={chatContainerRef}
		>
			<Slide
				direction="up"
				in={(showSimplifiedFooter || isLoading) && !chatIsActive}
				mountOnEnter
				unmountOnExit
			>
				<Box
					px={2}
					py={1.25}
					color="common.white1"
					bgcolor="secondary.main"
					borderRadius={isMobile ? '24px' : '8px 8px 0 0'}
					display="flex"
					alignItems="center"
				>
					{isLoading ? (
						<StatusSpinner
							color="palette.secondary.main"
							backgroundColor="palette.common.white1"
						/>
					) : (
						<ChatIcon />
					)}

					<LWTypography color="palette.common.white1" BoxProps={{ ml: 1 }}>
						{isLoading ? 'Loading Chat...' : 'Chat'}
					</LWTypography>
				</Box>
			</Slide>
		</SDiv>
	);
};

export { chatButtonTestIds };
export default ChatButton;
