import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import LWLink from 'components/common/LWLink';
import LWTypography from 'components/common/LWTypography';
import StatusLed from 'components/atoms/StatusLed';
import routeActions from 'modules/route/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Status from 'components/Asset/Status';
import useAssetConnections from 'modules/queries/asset/useConnections';
import { actions as sideSheetActions } from 'modules/sideSheet';
import createAvailableSecurityAddonsDetails from './createAvailableSecurityAddonsDetails';
import createActiveSecurityAddonsDetails from './createActiveSecurityAddonsDetails';

/** @param {import('modules/queries/asset/useDetails').AssetDetails} props */
const SecurityProducts = ({ uniq_id: uniqId, capabilities, domain }) => {
	const dispatch = useDispatch();
	const { data: connectionsData, isLoading } = useAssetConnections({
		uniq_id: uniqId,
	});

	const smartNavigate = ({ path }) => {
		dispatch(routeActions.smartNavigate({ path }));
	};

	// array of products allowed on this server that SecurityProducts can consume
	const availableSecurityAddons =
		createAvailableSecurityAddonsDetails(capabilities);
	const activeSecurityAddons =
		createActiveSecurityAddonsDetails(connectionsData);

	const addonAggregateArray = Object.values(
		// merging these objects ensures the an existing addon will overwrite the coorelating capability.
		{ ...availableSecurityAddons, ...activeSecurityAddons },
	);

	const handleClick = ({ listUrl, status, productCode, sideSheetTitle }) => {
		if (status) {
			smartNavigate({ path: listUrl });
		} else {
			dispatch(
				sideSheetActions.open({
					title: sideSheetTitle,
					contentKey: 'HookedProductData',
					contentProps: {
						domain,
						productCode,
						relatedSubaccnt: uniqId,
						showConfigure: false,
					},
				}),
			);
		}
	};

	if (isLoading) {
		return <CircularProgress size={16} />;
	}

	return (
		<>
			<Grid display="flex" container spacing={1}>
				<Grid item>
					<LWTypography
						color="textSecondary"
						variant="caption"
						BoxProps={{ pb: 1 }}
					>
						Security Products
					</LWTypography>
				</Grid>
			</Grid>
			{addonAggregateArray.map(
				({
					displayName,
					listUrl,
					productCode,
					status,
					uniqId: addonUniqId,
				}) => {
					return (
						<Grid container spacing={1} key={productCode}>
							<Grid item>
								{status ? (
									<Status
										subaccntStatus={status}
										uniqId={addonUniqId}
										iconOnly
									/>
								) : (
									<StatusLed color="palette.text.disabled" />
								)}
							</Grid>
							<Grid item>
								<LWLink
									onClick={() =>
										handleClick({
											listUrl,
											status,
											productCode,
											sideSheetTitle: displayName,
										})
									}
								>
									{displayName}
								</LWLink>
							</Grid>
						</Grid>
					);
				},
			)}
		</>
	);
};

SecurityProducts.propTypes = {
	uniq_id: PropTypes.string.isRequired,
};

export default SecurityProducts;
