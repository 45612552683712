import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SubNavRoutes from 'components/routers/SubNavRoutes';
import { initialized } from 'modules/gtm/selectors';
import { roles } from 'utility/constants/roles';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { Switch, useLocation, useHistory } from 'react-router-dom';

const InvoiceDetails = lazy(
	() => import('containers/pages/billing/InvoiceDetails'),
);

const BillingRouter = (props) => {
	const location = useLocation();
	const history = useHistory();
	const gtmInitialized = useSelector(initialized);
	useEffect(() => {
		// can't do navigation events until GTM has been initialized
		if (gtmInitialized && location.pathname === '/billing') {
			history.replace('/account/billing');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, gtmInitialized]);

	const { navData } = props;
	return (
		<Switch>
			<RoleRestrictedRoute
				exact
				path={navData?.path}
				component={navData?.component}
				whitelistedRoles={[
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.SUSPENDED_OWNER,
					roles.PURCHASER,
				]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${navData?.path}/invoice/:invoiceId`}
				component={InvoiceDetails}
				whitelistedRoles={[
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.SUSPENDED_OWNER,
					roles.PURCHASER,
				]}
			/>
			<SubNavRoutes
				subNavs={navData?.subNav}
				whitelistedRoles={[
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.SUSPENDED_OWNER,
					roles.PURCHASER,
				]}
			/>
		</Switch>
	);
};

BillingRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};

export default BillingRouter;
