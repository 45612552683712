import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';

const SUCCESS_INDICATOR_TIMEOUT_MS = 3000;

const CopyButton = ({ text, disabled = false }) => {
	const [didCopy, setDidCopy] = useState(false);
	const theme = useTheme();

	useEffect(() => {
		if (!didCopy) return undefined;

		const timeoutID = setTimeout(
			() => setDidCopy(false),
			SUCCESS_INDICATOR_TIMEOUT_MS,
		);

		return () => clearTimeout(timeoutID);
	}, [didCopy]);

	const handleClick = () => {
		navigator.clipboard.writeText(text);
		setDidCopy(true);
	};

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			minWidth={theme.spacing(4)}
			minHeight={theme.spacing(4)}
		>
			{didCopy ? (
				<CheckCircleIcon />
			) : (
				<IconButton
					color="primary"
					size="small"
					onClick={handleClick}
					disabled={disabled}
				>
					<FileCopyIcon />
				</IconButton>
			)}
		</Box>
	);
};

export default CopyButton;
