import React from 'react';
import PropTypes from 'prop-types';
import StyledBox from 'components/atoms/StyledBox';
import LWTypography from 'components/common/LWTypography';

const InfoBox = ({ details, isLoading, ...rest }) => (
	<StyledBox variant="grey" {...rest}>
		{details
			.filter((detail) => detail)
			.map((detail, i) => {
				if (isLoading) {
					return <LWTypography key={`${detail.key || detail}`} isLoading />;
				}
				if (typeof detail !== 'string') {
					return detail;
				}

				if (i === 0) {
					return (
						<LWTypography bold variant="body2" key={`${detail.key || detail}`}>
							{detail}
						</LWTypography>
					);
				}

				return (
					<LWTypography key={`${detail.key || detail}`} variant="body1">
						{detail}
					</LWTypography>
				);
			})}
	</StyledBox>
);
InfoBox.propTypes = {
	/** array of strings or React elements to be displayed - first item in array gets different styling */
	details: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	).isRequired,
	/** if true, displays skeleton loader for each instance of item in details instead of text */
	isLoading: PropTypes.bool,
};

export default InfoBox;
