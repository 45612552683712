import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import LWButton from 'components/common/LWButton';
import LWTypography from 'components/common/LWTypography';

import AddressDataCard from './AddressDataCard';

const ValidateErrorContent = ({
	isLoading,
	setIsOpen,
	submitOriginal,
	addressData,
}) => {
	return (
		<>
			<LWTypography variant="body1">
				<strong>We were unable to find a match for your address.</strong> Please
				check for any missing information or typos.
			</LWTypography>

			<AddressDataCard title="My Address" addressData={addressData} />
			<Grid container justifyContent="flex-end" spacing={2}>
				<Grid item>
					<LWButton
						color="secondary"
						isLoading={isLoading}
						onClick={() => setIsOpen(false)}
						variant="outlined"
					>
						Go Back
					</LWButton>
				</Grid>
				<Grid item>
					<LWButton
						data-testid="ValidateErrorContent__SubmitButton"
						color="secondary"
						isLoading={isLoading}
						onClick={submitOriginal}
						variant="outlined"
					>
						Continue Anyways
					</LWButton>
				</Grid>
			</Grid>
		</>
	);
};

ValidateErrorContent.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	addressData: PropTypes.shape({
		address: PropTypes.string,
		address2: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string,
		postal_code: PropTypes.string,
		state: PropTypes.string,
	}),
	setIsOpen: PropTypes.func.isRequired,
	submitOriginal: PropTypes.func.isRequired,
};

export default ValidateErrorContent;
