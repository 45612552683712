import React from 'react';

import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
	useProductDataContext,
	useSelectionsContext,
} from 'banana-stand/productConfig';
import LWTypography from 'components/common/LWTypography';
import dataCenterMap from 'containers/pages/shop/ConfigPackage/dataCenterMap';
import DetermineCountryFlag from 'containers/pages/shop/ConfigPackage/DetermineCountryFlag';
import useInitialProduct from 'containers/pages/shop/ConfigPackage/useInitialProduct';
import packageConfigActions from 'modules/basket/packageConfig/actions';
import { formatCurrency } from 'banana-stand/format';
import { useDispatch } from 'react-redux';

const createLabel = ({ baseLabel, amountProducts, productTitle }) => {
	if (amountProducts > 1) {
		return `${baseLabel} - ${productTitle}`;
	}

	return baseLabel;
};

const SimpleConfigProduct = ({ amountProducts }) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	useInitialProduct();
	const {
		dataCenterOptions,
		packageProductId,
		productTitle,
		productCode,
		priceTimeUnit,
	} = useProductDataContext();
	const { changeDataCenter, selectedDataCenter } = useSelectionsContext();

	if (dataCenterOptions.length < 2) {
		return null;
	}

	const handleDataCenterChange = (region) => {
		dispatch(
			packageConfigActions.setSelectedRegion({
				selectedRegion: region,
				packageProductId,
			}),
		);
		changeDataCenter(region);
	};

	const label = createLabel({
		baseLabel: 'Data Center',
		amountProducts,
		productTitle: productTitle || productCode,
	});

	const timeUnit = priceTimeUnit === 'month' ? 'mo' : priceTimeUnit;

	return (
		<TextField
			select
			size="small"
			label={label}
			value={selectedDataCenter}
			onChange={(event) => handleDataCenterChange(event.target.value)}
			variant="outlined"
		>
			{dataCenterOptions.map((option) => {
				const {
					label: optionLabel,
					key,
					value,
					country,
					zone,
				} = dataCenterMap[option.value];
				return (
					<MenuItem key={key} value={value}>
						<Box
							style={{
								display: 'flex',
								gap: `${theme.spacing(1.5)}px`,
								alignItems: 'center',
								width: '100%',
							}}
						>
							<DetermineCountryFlag country={country} />
							<Box>
								<LWTypography inline variant="body1">
									{optionLabel}{' '}
								</LWTypography>
								<LWTypography
									variant="body1"
									color="palette.primary.alt"
									inline
								>
									{zone}
								</LWTypography>
							</Box>
							{option?.priceDifference && (
								<LWTypography
									variant="caption"
									style={{
										fontSize: '10px',
										marginLeft: 'auto',
									}}
								>
									{formatCurrency(option?.priceDifference, {
										timeUnit,
										plusSign: true,
									})}
								</LWTypography>
							)}
						</Box>
					</MenuItem>
				);
			})}
		</TextField>
	);
};

export default SimpleConfigProduct;
