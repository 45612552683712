// Use the moduleKey, 'ACRONIS' to access these via the acronisSelectors of the detailsModule.

import { createSelector } from 'reselect';

import { acronisProductCodes } from 'utility/acronisProductMap';
import { perMonth } from 'utility/format';
import { selectors as productDetailsSelectors } from 'modules/api/product/detailsModule';
import {
	lwLabel,
	cloudLabel,
} from 'components/molecules/AcronisRadio/AcronisRadio';
import { selectors as parentSelectors } from '.';

const { acronisSelectors } = parentSelectors;

const regionId = createSelector(
	acronisSelectors.getNativeState,
	(slice) => slice?.data?.region_id,
);

const getProductCode = createSelector(
	acronisSelectors.getNativeState,
	(slice) => slice?.data?.product?.product_code,
);

// adds a `destination` and `quota` key to the root of the object
const getAcronis = createSelector(acronisSelectors.getNativeState, (slice) => {
	if (acronisProductCodes.includes(slice?.data?.type)) {
		const featureDetails = slice.data?.featureDetails;
		const toReturn = {};
		toReturn.destination = featureDetails?.AcronisStorageDestination;

		if (featureDetails?.AcronisStorageQuotaCloud) {
			toReturn.quota = featureDetails?.AcronisStorageQuotaCloud;
		}
		if (featureDetails?.AcronisStorageQuotaLiquidWeb) {
			toReturn.quota = featureDetails?.AcronisStorageQuotaLiquidWeb;
		}
		return toReturn;
	}
	return {};
});

const getAcronisDestination = createSelector(
	acronisSelectors.getNativeState,
	(slice) => {
		const value = slice?.data?.featureDetails?.AcronisStorageDestination?.value;
		switch (value) {
			case 'AcronisLiquidWebStorage':
				return lwLabel;
			case 'AcronisCloudStorage':
				return cloudLabel;
			default:
				return slice?.data?.featureDetails?.AcronisStorageDestination
					?.description;
		}
	},
);

const getMonthlyCost = createSelector(
	getAcronis,
	productDetailsSelectors.getAcronisBasePrice,
	(data, acronisBasePrice) =>
		perMonth(Number(data?.quota?.price) + Number(acronisBasePrice)),
);

const getDescription = createSelector(
	getAcronis,
	(acronisDetails) => acronisDetails?.quota?.description,
);

const selectors = {
	regionId,
	getAcronis,
	getAcronisDestination,
	getMonthlyCost,
	getProductCode,
	getDescription,
	...acronisSelectors,
};

export default selectors;
