import { call, takeLatest } from 'redux-saga/effects';
import { updateList as updateContactList } from 'modules/contact/sagas';
import snackbarSaga from 'modules/snackbar/sagas';
import actions from './actions';

function* handleSnackbar({ sectionTitleString, isError }) {
	yield call(snackbarSaga, {
		error: isError,
		errorMessage: 'Failed to save your changes. Please try again.',
		successMessage: `Your changes to ${sectionTitleString} have been saved`,
	});
}

function* handleUpdateContactInfo({
	sectionTitleString,
	payload,
	newMainEmail = undefined,
	newMainPhone = undefined,
}) {
	const isError = yield call(updateContactList, {
		payload,
		newMainEmail,
		newMainPhone,
	});
	yield call(handleSnackbar, { sectionTitleString, isError });
}

// function* handleUpdateAddresses({
// 	newMailingAddress = undefined,
// 	newBillingAddress = undefined,
// }) {
// 	if (newMailingAddress) {
// 		yield put(validateActions.setValidate({ address: newMailingAddress }));
// 	}
// 	if (newBillingAddress) {
// 		yield put(validateActions.setValidate({ address: newBillingAddress }));
// 	}
// 	yield call(handleSnackbar, { sectionTitleString: 'Addresses', isError });
// }

export default function* updateSagas() {
	yield takeLatest(
		actions.ACCOUNT_UPDATE_CONTACT_INFO,
		handleUpdateContactInfo,
	);
}
