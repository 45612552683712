import { connect } from 'react-redux';

import actions from 'modules/dialogs/actions';
import Dialogs from './Dialogs';

const mapStateToProps = (state) => ({
	...state.dialogs,
});

const mapDispatchToProps = (dispatch) => ({
	onClose: () => dispatch(actions.close()),
	initDialog: () => {
		dispatch(actions.reset());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(Dialogs);
