import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.salesforce.chat.auth.issuejwt;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'SALESFORCE_CHAT_AUTH_ISSUEJWT',
	url: '/salesforce/chat/auth/issuejwt.json',
});

const selectors = {
	getJWT: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'jwt']),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
