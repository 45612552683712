import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.api.network.loadbalancer.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_LOADBALANCER_LIST',
	url: '/network/loadbalancer/list.json',
});

const selectors = {
	items: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'items'], ImmutableList()),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
