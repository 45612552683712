import { createSelector } from 'reselect';
import getIsDeployingToPrivateParent from 'banana-stand/parsers/getIsDeployingToPrivateParent';
import {
	getIsCloudDedicated,
	getIsStorm,
	getIsVps,
	getIsStormCloudStudio,
	getHasRelatedStormProduct,
} from 'banana-stand/parsers';
import { selectors as parentListSelectors } from 'modules/api/storm/private/parent/listModule';
import { parseSupportLevel } from 'utility/redux/selectorHelperFunctions/stormHelpers';
import { configKeys } from 'utility/constants/baskets';
import selectors from './selectors';

const getOptions = createSelector(
	parentListSelectors.deployOntoOptions,
	selectors.getActiveProductCode,
	selectors.getActiveIsClone,
	(parentOptions, productCode, isClone) => {
		const isVpsOrClone = getIsVps(productCode) || isClone;
		const isVmOrClone = getIsCloudDedicated(productCode) || isClone;
		const isStormCloudStudio = getIsStormCloudStudio(productCode);

		const options = [];

		if (isStormCloudStudio) {
			if (isVpsOrClone) {
				options.push({ value: 'vps', label: 'Cloud VPS' });
			}

			if (isVmOrClone) {
				options.push({ value: 'cloudDedicated', label: 'Cloud Metal' });
			}

			return options;
		}

		if (isVpsOrClone) {
			options.push({ value: 'vps', label: 'VPS' });
			options.push(...parentOptions);
		}

		if (isVmOrClone) {
			options.push({ value: 'cloudDedicated', label: 'Cloud Dedicated' });
		}

		return options;
	},
);

/** Since the selector is plural, this will return false if there is less than 2. */
const getHasOptions = createSelector(
	getOptions,
	(options) => options.length > 1,
);

const getDeployOnto = createSelector(
	selectors.getStateSlice,
	(slice) => slice.deployOnto,
);

const getIsPrivateParent = createSelector(
	getDeployOnto,
	getIsDeployingToPrivateParent,
);

// Sometime we have options inserted that use properties instead of regular configs. We adjust those valueDescriptions as needed here.
const getHeadersMap = createSelector(
	selectors.getActiveProductCode,
	getIsPrivateParent,
	(activeProductCode, isPrivateParent) => {
		const isStorm = getIsStorm(activeProductCode);
		if (isStorm && isPrivateParent)
			return {
				'Server Type': 'Allocation from Private Parent',
			};
		return {};
	},
);

const generateGetNeedsSwap = (value) => {
	return createSelector(selectors.getActiveProductCode, (activeProductCode) => {
		const isCloudDedicated = getIsCloudDedicated(activeProductCode);
		const isVps = getIsVps(activeProductCode);
		return (
			(isCloudDedicated && value !== 'cloudDedicated') ||
			(isVps && value === 'cloudDedicated')
		);
	});
};

const getDisplayDeployOnto = createSelector(
	selectors.getActiveProductCode,
	getHasOptions,
	(productCode, hasOptions) => {
		if (getHasRelatedStormProduct(productCode)) return hasOptions;

		return false;
	},
);

const getDeployOntoInitValue = createSelector(
	selectors.getActiveProductCode,
	selectors.getActiveProductProperties,
	(productCode, properties) => {
		const isVps = getIsVps(productCode);
		const isCloudDedicated = getIsCloudDedicated(productCode);
		const parent = properties?.parent;
		if (parent) {
			return parent;
		}
		if (isVps) {
			return 'vps';
		}
		if (isCloudDedicated) {
			return 'cloudDedicated';
		}
		return undefined;
	},
);

const getSelectedTemplatePtov = createSelector(
	selectors.getProductKeyToKeyStringToSelectedValue,
	selectors.getActiveProductCode,
	(productKeyToKeyStringToSelectedValue, activeProductCode) => {
		return productKeyToKeyStringToSelectedValue?.[activeProductCode]?.[
			configKeys.Template
		];
	},
);

const getSelectedTemplateExtraData = createSelector(
	selectors.getPtovToDetailsMap,
	getSelectedTemplatePtov,
	(ptovToDetails, templatePtov) => ptovToDetails?.[templatePtov]?.extraData,
);

const getDynamicChildType = createSelector(
	getSelectedTemplateExtraData,
	(extraData) => {
		if (!extraData) {
			return '';
		}

		if (extraData.os === 'Windows') {
			return 'Windows';
		}

		return parseSupportLevel(extraData.support_level);
	},
);

const getDisableRegionDropdown = createSelector(
	getDisplayDeployOnto,
	getIsPrivateParent,
	(displayDeployOnto, isPrivateParent) => displayDeployOnto && isPrivateParent,
);

const deployOntoSelectors = {
	generateGetNeedsSwap,
	getDeployOnto,
	getDeployOntoInitValue,
	getDisableRegionDropdown,
	getDisplayDeployOnto,
	getHasOptions,
	getSelectedTemplatePtov,
	getSelectedTemplateExtraData,
	getDynamicChildType,
	getHeadersMap,
	getOptions,
};

export default deployOntoSelectors;
