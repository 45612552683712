const categoryMap = {
	AutoDedicated: 'bare metal',
	StrictDedicated: 'dedicated',
	CloudVPS: 'cloud',
	CloudBareMetal: 'cloud',
	PrivateParent: 'cloud',
	BeyondHostingVPS: 'cloud',
	DNS: 'dns_zone',
	SSL: 'ssl',
	NocworxOpenstack: 'open stack',
	WordPress: 'wordpress',
	VirtualDedicated: 'cloud',
};

export default categoryMap;
