import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import Close from '@material-ui/icons/Close';
import LWIconButton from 'components/atoms/LWIconButton';
import LWTypography from 'components/common/LWTypography';

const styles = (theme) => ({
	root: {
		marginBottom: '1em',
	},
	content: {},
	errorIcon: {
		fontSize: '2em',
	},
	title: {
		padding: '0.5em',
	},
	titleContent: {
		display: 'flex',
		alignItems: 'center',
	},
	titleTypography: {
		fontSize: '1.2em',
		paddingLeft: '1em',
		flexGrow: 2,
		color: theme.palette.text.main,
	},
	contentTypography: {
		fontSize: '16px',
	},
});

const LWError = ({
	classes,
	children = 'An error has occurred processing your request. Please retry. If the error persists, please contact support.',
	title = '',
	handleDismiss = null,
	type = 'error',
	shown = false,
	...otherProps
}) => {
	const [isShown, setShown] = useState(false);

	const handleDismissError = () => {
		if (handleDismiss) handleDismiss();
		setShown(false);
	};
	return !shown && !isShown ? null : (
		<Card className={classes.root} square {...otherProps}>
			<CardHeader
				className={classNames(classes.title, classes[type])}
				color=""
				title={
					<div className={classes.titleContent}>
						<RemoveCircle className={classes.errorIcon} />
						<LWTypography className={classes.titleTypography}>
							{title}
						</LWTypography>
						<LWIconButton onClick={() => handleDismissError()}>
							<Close />
						</LWIconButton>
					</div>
				}
				disableTypography
			/>
			<CardContent className={classes.content}>
				<LWTypography
					className={classes.contentTypography}
					color="textPrimary"
					component="div"
				>
					{children}
				</LWTypography>
			</CardContent>
		</Card>
	);
};

LWError.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	title: PropTypes.string,
	type: PropTypes.string,
	handleDismiss: PropTypes.func,
	shown: PropTypes.bool,
};

export { LWError };

export default withStyles(styles)(LWError);
