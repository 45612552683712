import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import LWTypography from 'components/common/LWTypography';

const SLoading = styled((props) => (
	<Grid
		container
		direction="column"
		justifyContent="center"
		alignItems="center"
		{...props}
	/>
))`
	top: 65px;
	width: 100%;
	position: ${({ $position }) => $position};
	display: flex;
	justify-content: center;
	z-index: 1;
	.MuiTypography-root {
		margin-top: ${(p) => p.theme.spacing(2)}px;
	}

	${(p) => p.css}
`;

const Loading = ({
	CircularProgressProps,
	message,
	children,
	position = 'absolute',
	...rest
}) => (
	<SLoading $position={position} {...rest} data-testid="Loading">
		<CircularProgress color="primary" size={25} {...CircularProgressProps} />
		{message && <LWTypography>{message}</LWTypography>}
		<React.Fragment>{children}</React.Fragment>
	</SLoading>
);

Loading.propTypes = {
	position: PropTypes.string,
	CircularProgressProps: PropTypes.any,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	message: PropTypes.string,
};

export default Loading;
