import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.api.network.dns.zone.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_ZONE_DETAILS',
	method: 'POST',
	url: '/network/dns/zone/details.json',
});

/**
 * Gets delegation status of a DNS Zone
 * @returns {string} delegation status of the DNS Zone
 */
const getDelegationStatus = createSelector(
	defaultSelectors.getNativeData,
	(data) => data?.delegation_status,
);

/**
 * Uses delegation status to determine whether to display external name servers warning
 * @returns {boolean} Indicates whether delegation status is "CORRECT"
 */
const getIsDelegationStatusNotCorrect = createSelector(
	getDelegationStatus,
	(delegationStatus) => delegationStatus !== 'CORRECT',
);

/**
 * Gets name of loaded dns zone
 * @returns {string}
 */
const getName = createSelector(
	defaultSelectors.getNativeData,
	(data) => data?.name,
);

const selectors = {
	delegation: createSelector(
		defaultSelectors.getStateSlice,
		(slice) => slice.getIn(['data', 'delegation'], []) || [],
	),
	getDelegationStatus,
	getName,
	getIsDelegationStatusNotCorrect,
	zoneId: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'id']),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
