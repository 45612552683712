import { push } from 'connected-react-router';
import {
	actions as signupActions,
	selectors as signupSelectors,
} from 'modules/api/account/signupModule';
import { selectors as updateSelectors } from 'modules/api/contact/updateModule';
import { actions as validateAddressActions } from 'modules/api/contact/validateAddressModule';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import { hasGroup as getHasGroup } from 'modules/auth/authSelectors';
import snackbarSaga from 'modules/snackbar/sagas';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';

import cartActions from 'modules/cart/actions';
import { updateList } from 'modules/contact/sagas';

import { contactCreateAdditionalSagas } from 'modules/api/contact/createModule';
import {
	contactListAdditionalSagas,
	selectors as contactListSelectors,
} from 'modules/api/contact/listModule';
import basketCheckoutActions from 'modules/basket/checkout/actions';
import internalOnlyActions from 'modules/internalOnly/actions';
import { getErrorStringHelper } from 'utility/redux/selectorHelperFunctions/apiModules';
import createAccountActions from './actions';

function* initCreatePage() {
	yield put(internalOnlyActions.check({ guest: 1 }));
}

function* handleResponse({ errorString } = {}) {
	yield put(validateAddressActions.clear());
	if (errorString) {
		yield call(snackbarSaga, {
			error: true,
			errorMessage: errorString,
		});
		return;
	}

	// success
	const cartHasItems = yield select(cartDetailsSelectors.getHasItems);
	if (cartHasItems) yield put(basketCheckoutActions.takeToNextStep());
	else yield put(push('/shop/marketplace'));
}

function* handleSignup({ payload }) {
	yield put(signupActions.fetch(payload));
	const res = yield take([signupActions.errorType, signupActions.setType]);
	const error = res.type === signupActions.errorType;

	if (!error) {
		// Update token
		const userManager = yield select(appConfigSelectors.getUserManager);
		yield call([userManager, userManager.signinSilent]);

		// init basket
		yield put(cartActions.initialize());
	}

	yield call(handleResponse, {
		errorString: error ? yield select(signupSelectors.getErrorString) : false,
	});
}

function* handleUpdate({ payload }) {
	// Transform the signup call data into update data.
	const { address, contact, identity } = payload || {};
	const {
		company: organization,
		first_name: fname,
		last_name: lname,
	} = identity || {};
	const { email, phone } = contact;
	// the id is inserted by the saga.
	const isError = yield call(updateList, {
		payload: {
			...address,
			zip: undefined, // replacing zip with postal_code
			postal_code: address.zip,
			organization,
			fname,
			lname,
		},
		newMainEmail: email,
		newMainPhone: phone,
	});
	yield call(handleResponse, {
		errorString: isError ? yield select(updateSelectors.getErrorString) : false,
	});
}

function* handleCreate({ payload }) {
	const { address: addressObj, contact, identity } = payload;
	const { zip, ...addressData } = addressObj;
	const {
		first_name: fname,
		last_name: lname,
		company: organization,
	} = identity;

	const { error } = yield call(contactCreateAdditionalSagas.fetchDirectly, {
		...addressData,
		emails: [{ address: contact.email, type: 'main' }],
		fname,
		lname,
		organization,
		phones: [{ number: contact.phone }],
		postal_code: zip,
	});

	yield call(handleResponse, { errorString: getErrorStringHelper(error) });
}

function* signupPut({ payload }) {
	const hasGroup = yield select(getHasGroup);
	yield call(contactListAdditionalSagas.fetchDirectly, {}, undefined, {
		init: true,
	});
	const contacts = yield select(contactListSelectors.getNativeItems);
	const hasContact = contacts.length > 0;
	if (hasGroup && hasContact) {
		yield call(handleUpdate, { payload });
	} else if (hasGroup) {
		yield call(handleCreate, { payload });
	} else {
		yield call(handleSignup, { payload }); // schema was originally structured for the signup endpoint.
	}
}

export default function* saga() {
	yield takeLatest(createAccountActions.ACCOUNT_CREATE_SIGNUP_PUT, signupPut);
	yield takeLatest(createAccountActions.CREATE_PAGE_INIT, initCreatePage);
}
