import React from 'react';
import Ok from '@material-ui/icons/CheckCircle';
import Down from '@material-ui/icons/Cancel';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import styled from 'styled-components';
import LWTypography from 'components/common/LWTypography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMonitoringServiesStatus from 'modules/queries/monitoring/services/useStatus';

const SService = styled(Grid)`
	margin: 0.4em 0;
	.MuiTypography-root {
		display: inline;
		padding-left: 1rem;
		text-transform: uppercase;
	}
`;
const SOk = styled(Ok)`
	fill: ${(p) => p.theme.palette.common.green3};
`;
const SDown = styled(Down)`
	fill: ${(p) => p.theme.palette.common.red};
`;
const Icon = ({ value }) => {
	switch (value) {
		case 'Ok':
			return <SOk />;
		case 'Down':
			return <SDown />;
		case 'Unknown':
			return <RemoveCircle />;
		default:
			return null;
	}
};

const Services = ({ uniqId }) => {
	const { data, isLoading } = useMonitoringServiesStatus({ uniq_id: uniqId });

	if (isLoading) {
		return <CircularProgress />;
	}

	const dataList = Object.entries(data || {});

	if (!data || !dataList.length) {
		return (
			<LWTypography>No services are currently being monitored</LWTypography>
		);
	}

	return (
		<Grid container>
			{dataList.map(([key, value]) => {
				if (value === 'Unmonitored') return null;
				return (
					<SService
						key={`icon${key}`}
						container
						item
						alignItems="center"
						xs={6}
						sm={4}
						md={2}
						title={value}
					>
						<Icon value={value} />
						<LWTypography>{key}</LWTypography>
					</SService>
				);
			})}
		</Grid>
	);
};

export default Services;
