import {
	take,
	actionChannel,
	select,
	spawn,
	put,
	all,
	takeLatest,
	call,
	delay,
	race,
} from 'redux-saga/effects';
import {
	selectors as listForKeySelectors,
	actions as listForKeyActions,
	moduleKeys as listForKeyModuleKeys,
} from 'modules/api/account/user/stateData/listForKeyModule';
import { actions as addActions } from 'modules/api/account/user/stateData/addModule';
import { actions as updateActions } from 'modules/api/account/user/stateData/updateModule';
import { actions as replaceActions } from 'modules/api/account/user/stateData/replaceModule';
import { isLoggedInAndNotBasketAdmin as isLoggedInAndNotBasketAdminSelector } from 'modules/auth/authSelectors';

import uniqBy from 'lodash/uniqBy';
import remove from 'lodash/remove';
import moment from 'moment';
import actions from './actions';

let trackEventChannel;
const EVENT_DEBOUNCE_TIME_IN_SECONDS = 10;
const MAX_NAVIGATION_DATE_HISTORY = 5;
const EXPIRED_MONTHS = 15;

export const removeDuplicates = (duplicates) =>
	uniqBy(duplicates, (e) => {
		return e.path;
	});

export const removeOld = (duplicates, fromDate) =>
	remove(duplicates, (currentObj) => {
		const months = fromDate.diff(
			moment(currentObj.navigatedOn[currentObj.navigatedOn.length - 1]),
			'months',
		);
		return months > EXPIRED_MONTHS;
	});

function* fetchStateData() {
	const { action } = yield all({
		action: take([listForKeyActions.setType, listForKeyActions.errorType]),
		fetch: put(
			listForKeyActions.fetch(
				{
					key: listForKeyModuleKeys.NAVTRACKING,
				},
				listForKeyModuleKeys.NAVTRACKING,
			),
		),
	});
	if (action.type === listForKeyActions.errorType) {
		// eslint-disable-next-line no-console
		console.error(
			'Unable to fetch stateData, ceasing to track for commonly viewed',
		);
		return action.payload;
	}
	return null;
}

function* bufferNavTrackerEvents() {
	const eventBuffer = [];
	while (true) {
		const { event } = yield race({
			event: take(trackEventChannel),
			delay: delay(EVENT_DEBOUNCE_TIME_IN_SECONDS * 1000),
		});

		// queue event
		if (event) eventBuffer.push(event.payload);
		// its delayed 10 sec, now return buffer
		else if (eventBuffer.length > 0) return eventBuffer;
		// nothing no events have occured
	}
}

const aggregateEvent = (event, aggregations) => {
	const aggregate = aggregations[event.path];

	aggregate.count += 1;
	aggregate.displayName = event?.displayName;
	aggregate.navigatedOn.push(new Date());
	if (aggregate.navigatedOn.length > MAX_NAVIGATION_DATE_HISTORY) {
		aggregate.navigatedOn.shift();
	}
	return aggregations;
};

const findTrackingDataFromPath = (path) => (trackingData) =>
	trackingData.get('path') === path;

const aggregateEventsWithStateData =
	(trackingStateData) => (reduced, event) => {
		const aggregations = reduced;
		const { path, displayName, assetId, isAssetDomain } = event;

		// second time getting same event so aggregate
		if (aggregations[path]) return aggregateEvent(event, aggregations);

		// check if we already have an event in the tracking data from the api
		const trackingSet = trackingStateData.get('data');
		const trackingData = trackingSet.find(findTrackingDataFromPath(path));
		if (trackingData) {
			// if so then add it and aggregate
			aggregations[event.path] = trackingData.toJS();
			return aggregateEvent(event, aggregations);
		}

		// First time tracking page
		const newTrackingData = {
			path,
			displayName,
			navigatedOn: [new Date()],
			count: 1,
		};

		if (assetId) {
			newTrackingData.assetId = assetId;
			newTrackingData.isAssetDomain = isAssetDomain;
		}

		aggregations[event.path] = newTrackingData;
		return aggregations;
	};

const mapEventToAction = (aggregatedEvent) => {
	if (aggregatedEvent.uuid) {
		return put(
			updateActions.fetch({
				data: JSON.stringify(aggregatedEvent),
				key: listForKeyModuleKeys.NAVTRACKING,
			}),
		);
	}
	return put(
		addActions.fetch({
			data: JSON.stringify(aggregatedEvent),
			key: listForKeyModuleKeys.NAVTRACKING,
		}),
	);
};

function* listenForNavTrackerEvents() {
	while (true) {
		const events = yield call(bufferNavTrackerEvents);
		if (events) {
			const trackingStateData = yield select(
				listForKeySelectors.navTrackingSelectors.getData,
			);

			const aggregatedEvents = events.reduce(
				aggregateEventsWithStateData(trackingStateData),
				{},
			);

			const requests = Object.values(aggregatedEvents).map(mapEventToAction);

			// wait for all of the updates to flush out.
			yield all([take(updateActions.setType), all(requests)]);
		}
		const isLoggedInAndNotBasketAdmin = yield select(
			isLoggedInAndNotBasketAdminSelector,
		);
		if (isLoggedInAndNotBasketAdmin) {
			const possibleError = yield call(fetchStateData);
			if (possibleError) return;
		}
	}
}

export {
	bufferNavTrackerEvents,
	aggregateEvent,
	findTrackingDataFromPath,
	aggregateEventsWithStateData,
	mapEventToAction,
	listenForNavTrackerEvents,
};

function* removeDuplicateAndOldCommonlyViewed() {
	const navTracker = yield select((state) =>
		listForKeySelectors.navTrackingSelectors.getData(state),
	);
	if (navTracker) {
		const viewedPages = navTracker.get('data');
		const viewedPageJS = viewedPages.toJS();

		// Remove duplicates
		const uniqArr = removeDuplicates(viewedPageJS);

		const today = moment();
		// Remove old
		removeOld(uniqArr, today);

		if (uniqArr.length !== viewedPageJS.length) {
			yield put(
				replaceActions.fetch({
					data: JSON.stringify(uniqArr),
					key: 'navTracking',
				}),
			);
		}
	}
}

export default function* rootSaga() {
	yield takeLatest(
		listForKeyActions.setType,
		removeDuplicateAndOldCommonlyViewed,
	);
	// start queueing tracking events asap
	yield takeLatest(
		listForKeyActions.setType,
		removeDuplicateAndOldCommonlyViewed,
	);
	trackEventChannel = yield actionChannel(actions.NAV_TRACKING_EVENT);
	const isLoggedInAndNotBasketAdmin = yield select(
		isLoggedInAndNotBasketAdminSelector,
	);
	if (isLoggedInAndNotBasketAdmin) {
		const possibleError = yield call(fetchStateData);
		if (possibleError) return;
	}
	yield spawn(listenForNavTrackerEvents);
}
