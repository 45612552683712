import { call, delay, select, take, put } from 'redux-saga/effects';
import { isMasquerade as getIsMasquerade } from 'modules/auth';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import { initialized as gtmInitialized } from 'modules/gtm/selectors';
import gtmActions from 'modules/gtm/actions';

import AttributionData from './AttributionData';
import attributionActions from './actions';

const TIMEOUT = 5; // seconds

export function* waitForGaReady() {
	// Need GTM initialized for GA attribution to setup properly
	if (!(yield select(gtmInitialized))) {
		take(gtmActions.INITIALIZED);
	}
	let timeout = 0;
	while (typeof window.ga.getAll !== 'function' && timeout <= TIMEOUT * 1000) {
		// Google Analytics adds a stub function while it's initializing, getAll will exist once it's ready
		yield delay(500);
		timeout += 500;
	}
}

/** Gets the attribution data complete with gaTrackerId from the app config. We can't use reselect here since the logic relies on cookies. */
export function* getAttributionData() {
	const isMasquerade = yield select(getIsMasquerade);
	if (isMasquerade) return false;

	const gaTrackerId = yield select(appConfigSelectors.getGaTrackerId);
	const attributionDataCreator = new AttributionData({ gaTrackerId });

	const attributionData = attributionDataCreator.all;
	if (!attributionData.referral && !attributionData.attribution) return false;

	return attributionData;
}

export function* gaSendAttributionData(basketDetails) {
	yield call(waitForGaReady);

	const attributionData = yield call(getAttributionData);
	if (attributionData) {
		yield put(
			attributionActions.sendPurchaseGaData({ attributionData, basketDetails }),
		);
	}
}
