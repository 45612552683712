/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import storyOrTest from 'utility/tools/storyOrTest';

let defaultState;

// We are importing the config like this to avoid inapplicable configs from being included in the builds.
// If you change this, do a couple of builds to verify that we are not exposing our dev endpoints to the public!
try {
	defaultState = require(
		`appConfigs/${process.env.REACT_APP_CONFIG}.js`,
	).default;
} catch (e1) {
	// don't throw errors. Just pick a config or set it to null.
	if (storyOrTest() === 'test') {
		defaultState = require(`appConfigs/prod.js`).default;
	} else if (storyOrTest() === 'storybook') {
		defaultState = null;
	} else {
		try {
			defaultState = require(`appConfigs/local.js`).default;
		} catch (e2) {
			// eslint-disable-next-line no-console
			console.error('Unable to load src/appConfigs/local.js');
			defaultState = null;
		}
	}
}

const reducer = (state = defaultState) => state;

export default reducer;
