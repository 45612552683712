import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ErrorText from 'components/molecules/ErrorText';
import FullScreenError from 'components/molecules/FullScreenError';
import storyOrTest from 'utility/tools/storyOrTest';

import { actions as gatorActions } from 'modules/reactGator';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error) {
		// Log error to gator-client.
		const { logError } = this.props;
		logError(error);
	}

	render() {
		const { state, props } = this;
		const { fullScreen = false } = props;

		if (state.hasError && !storyOrTest()) {
			return fullScreen ? (
				<FullScreenError subHeaderText="An error occurred when displaying the page." />
			) : (
				<ErrorText />
			);
		}

		return props.children;
	}
}

ErrorBoundary.propTypes = {
	/** Determines if the error should use the fullscreen display */
	fullScreen: PropTypes.bool,
	/** Child content nodes to display if no error */
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

const mapDispatchToProps = (dispatch) => ({
	logError: (error) => {
		dispatch(gatorActions.logError(error));
	},
});

export default connect(null, mapDispatchToProps)(ErrorBoundary);
