import React, { useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';
import LWTypography from 'components/common/LWTypography';
import AssetStatus from 'components/Asset/Status';
import isTouchDevice from 'is-touch-device';
import DismissButton from './DismissButton';

const SSnackbarContent = styled(SnackbarContent)`
	padding: 0;
	color: ${({ theme }) => theme.palette.text.primary};
	background-color: ${({ theme }) => theme.palette.common.white};
	border: 2px solid ${({ theme }) => theme.palette.primary.main};
	border-radius: 22px;
	.MuiSnackbarContent-message {
		padding: 0;
		width: 100%;
	}
`;

// This needs to be a pixel perfect 44px tall per kkaminski, so need to hard code in that specific padding
const SHeader = styled(Grid)`
	padding-top: 3px;
	padding-right: ${({ theme }) => theme.spacing(0.5)}px;
	padding-bottom: 3px;
	padding-left: ${({ theme }) => theme.spacing(2)}px;
`;

const SArrowIcon = styled(IconButton)`
	color: ${({ theme }) => theme.palette.secondary.dark};
`;

const SCloseIconButton = styled(IconButton)`
	color: ${({ theme }) => theme.palette.text.primary};
	border: 1px solid ${({ theme }) => theme.palette.divider};
`;

const SStatusContainer = styled(Box)`
	border-top: 1px solid ${({ theme }) => theme.palette.divider};
`;

const Content = ({
	showNotifications,
	toggleShowNotifications,
	notificationCount,
	pendingNotifications,
	closeSheet,
}) => (
	<Box>
		<SHeader container alignItems="center" justifyContent="space-between">
			<Grid item container alignItems="center" xs={10}>
				<Grid item>
					<LWTypography variant="body2" bold>
						{`${notificationCount} Asset${
							notificationCount === 1 ? '' : 's'
						} Updating`}
					</LWTypography>
				</Grid>
				<Grid item>
					<SArrowIcon onClick={toggleShowNotifications}>
						{showNotifications ? <DownIcon /> : <UpIcon />}
					</SArrowIcon>
				</Grid>
			</Grid>

			<Grid item>
				<SCloseIconButton onClick={closeSheet}>
					<CloseIcon />
				</SCloseIconButton>
			</Grid>
		</SHeader>
		{showNotifications && (
			<Box width="100%">
				{pendingNotifications.map((notification) => (
					<Notification
						key={notification.get('uniq_id')}
						notification={notification}
						closeSheet={closeSheet}
						notificationCount={notificationCount}
					/>
				))}
			</Box>
		)}
	</Box>
);

const Notification = ({ notification, closeSheet, notificationCount }) => {
	const [showDismiss, setShowDismiss] = useState(false);
	return (
		<SStatusContainer
			paddingX={1}
			paddingY={0.5}
			onMouseOver={() => setShowDismiss(true)}
			onFocus={() => setShowDismiss(true)}
			onMouseOut={() => setShowDismiss(false)}
			onBlur={() => setShowDismiss(false)}
		>
			<Grid container direction="row" justifyContent="space-between">
				<AssetStatus
					uniqId={notification.get('uniq_id')}
					title={notification.get('domain')}
				/>
				<DismissButton
					id={notification.get('id')}
					visible={isTouchDevice() || showDismiss}
					closeSheet={closeSheet}
					notificationCount={notificationCount}
				/>
			</Grid>
		</SStatusContainer>
	);
};

const StatusSheet = ({
	pendingNotifications,
	isOpen,
	closeSheet,
	setHeight,
}) => {
	const [showNotifications, setShowNotifications] = useState(false);
	const statusSheetRef = useRef(null);

	const toggleShowNotifications = () => {
		setShowNotifications(!showNotifications);
	};

	const notificationCount = pendingNotifications.size;

	useLayoutEffect(() => {
		// Need to run this on every render as the height changes during the render process
		// But only if open to help with any performance concerns
		if (statusSheetRef && statusSheetRef.current && isOpen) {
			setHeight(statusSheetRef.current.clientHeight);
		}
	});

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={isOpen}
			ref={statusSheetRef}
		>
			<SSnackbarContent
				message={
					<Content
						showNotifications={showNotifications}
						toggleShowNotifications={toggleShowNotifications}
						notificationCount={notificationCount}
						pendingNotifications={pendingNotifications}
						closeSheet={closeSheet}
					/>
				}
			/>
		</Snackbar>
	);
};

StatusSheet.propTypes = {
	pendingNotifications: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	closeSheet: PropTypes.func.isRequired,
};

export default StatusSheet;
