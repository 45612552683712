import { call } from 'redux-saga/effects';
import assertMetadataSaga from 'modules/cart/assertMetadataSaga';
import { getAttributionData, waitForGaReady } from './sagas';

/** sends attribution data to google analytics via redux becon, but only for regular users who have the cookies. This saga should always come after a uuid is established. */
function* updateGaAttribution() {
	yield call(waitForGaReady);
	const attributionData = yield call(getAttributionData);
	if (attributionData) {
		yield call(assertMetadataSaga, { metadata: attributionData });
	}
}

export default updateGaAttribution;
