// itemPriceTotal comes from timeUnitTotals/item_price_total in basket/details items data
// itemPrepayTotal comes from totals/item_prepay_once in basket/detaisls item data
// itemDiscountTotal comes from timeUnitTotals/item_discount_total in basket/detaisls item data
const getItemPricing = ({
	itemPriceTotal,
	itemPrepayTotal,
	itemDiscountTotal,
}) => {
	const monthlyPrice =
		Number(itemPriceTotal?.month || 0) +
		Number(itemPriceTotal?.['static-month'] || 0);
	const onetimePrice =
		Number(itemPriceTotal?.['one-time'] || 0) + Number(itemPrepayTotal);

	const isMonthly = !!monthlyPrice;

	let itemDiscountAmount = null;
	if (itemDiscountTotal) {
		itemDiscountAmount = isMonthly
			? Number(itemDiscountTotal?.month || 0) +
				Number(itemDiscountTotal?.['static-month'] || 0)
			: itemDiscountTotal?.['one-time'] || 0;
	}
	return { monthlyPrice, onetimePrice, isMonthly, itemDiscountAmount };
};

export default getItemPricing;
