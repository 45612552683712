import { createSelector } from 'reselect';
import { selectors as dynamicChildSelectors } from 'modules/api/account/limits/dynamicChildModule';
import { selectors as optionsSelectors } from 'modules/server/resize/options';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import { selectors as routeSelectors } from 'modules/route';
import { middleValue } from 'utility/middleValue';
import { formValueSelector } from 'redux-form';
import { sliderLabels } from 'components/forms/PrivateParentSliders/copy';
import prodConfigDeployOntoSelectors from 'modules/basket/productConfig/deployOntoSelectors';

const formName = 'PrivateParentSliders';
const formValuesSelector = (state) => {
	const selector = formValueSelector(formName);
	return selector(state, 'ram', 'cpu', 'disk');
};

const isLoading = createSelector(
	assetDetailsSelectors.isLoading,
	dynamicChildSelectors.isLoading,
	optionsSelectors.isLoading,
	(asset, dynamic, options) => asset || dynamic || options,
);

const getDynamicChildLimits = createSelector(
	dynamicChildSelectors.getNativeData,
	assetDetailsSelectors.childType,
	prodConfigDeployOntoSelectors.getDynamicChildType,
	routeSelectors.getPathName,
	(
		dynamicChildData,
		assetDetailsChildType,
		productConfigChildType,
		pathName,
	) => {
		const isOnConfig = /\/shop\/config/.test(pathName);
		if (isOnConfig) {
			return dynamicChildData?.[productConfigChildType];
		}

		return dynamicChildData?.[assetDetailsChildType];
	},
);

const getInitialCpu = createSelector(
	optionsSelectors.selectedPrivateParent,
	getDynamicChildLimits,
	assetDetailsSelectors.CPUint,
	(privateParent, limits, current) =>
		middleValue([privateParent?.vcpu, limits?.Cpu, current]) || 0,
);

const getInitialRam = createSelector(
	optionsSelectors.selectedPrivateParent,
	getDynamicChildLimits,
	assetDetailsSelectors.RAM,
	(privateParent, limits, current) =>
		middleValue([
			privateParent?.resources?.memory?.free,
			limits?.Memory,
			current,
		]) || 0,
);

const getInitialDisk = createSelector(
	optionsSelectors.selectedPrivateParent,
	getDynamicChildLimits,
	assetDetailsSelectors.storageSize,
	assetDetailsSelectors.getPreventResizeDown,
	(privateParent, limits, current, preventResizeDown) => {
		const minValue = preventResizeDown ? current : limits?.Disk;

		return (
			middleValue([
				privateParent?.resources?.diskspace?.free,
				minValue,
				current,
			]) || 0
		);
	},
);

const getMaxDisk = createSelector(
	optionsSelectors.selectedPrivateParent,
	assetDetailsSelectors.storageSize,
	assetDetailsSelectors.getPrivateParent,
	(selectedPrivateParent_, storageSize, privateParent) => {
		if (privateParent?.uniq_id === selectedPrivateParent_?.uniq_id) {
			return (
				(selectedPrivateParent_?.resources?.diskspace?.free || 0) + storageSize
			);
		}
		return selectedPrivateParent_?.resources?.diskspace?.free;
	},
);

const getMaxRam = createSelector(
	optionsSelectors.selectedPrivateParent,
	assetDetailsSelectors.RAM,
	assetDetailsSelectors.getPrivateParent,
	(selectedPrivateParent_, RAM, privateParent) => {
		if (privateParent?.uniq_id === selectedPrivateParent_?.uniq_id) {
			return (selectedPrivateParent_?.resources?.memory?.free || 0) + RAM;
		}
		return selectedPrivateParent_?.resources?.memory?.free;
	},
);

const selectionValidationError = createSelector(
	formValuesSelector,
	getDynamicChildLimits,
	optionsSelectors.selectedPrivateParent,
	getMaxRam,
	getMaxDisk,
	(
		{ cpu: selectedVcpu, ram: selectedRam, disk: selectedDisk },
		{ Cpu, Disk, Memory },
		{ vcpu },
		maxRam,
		maxDisk,
	) => {
		const invalidValue = [
			[sliderLabels.vcpu, selectedVcpu >= Cpu && selectedVcpu <= vcpu],
			[sliderLabels.ram, selectedRam >= Memory && selectedRam <= maxRam],
			[sliderLabels.disk, selectedDisk >= Disk && selectedDisk <= maxDisk],
		].find(([, isValid]) => !isValid);
		if (invalidValue) return `${invalidValue[0]} not in range.`;
		return false;
	},
);

const getSliderProps = createSelector(
	getInitialCpu,
	getInitialRam,
	getInitialDisk,
	getMaxRam,
	getMaxDisk,
	assetDetailsSelectors.CPU,
	assetDetailsSelectors.RAMdisplay,
	assetDetailsSelectors.storageSize,
	assetDetailsSelectors.storageSizeDisplay,
	getDynamicChildLimits,
	optionsSelectors.selectedPrivateParent,
	assetDetailsSelectors.getPreventResizeDown,
	(
		initialCpu,
		initialRam,
		initialDisk,
		maxRam,
		maxDisk,
		currentCpu,
		currentRam,
		currentStorage,
		currentStorageDisplay,
		dynamicLimits,
		selectedParent,
		preventResizeDown,
	) => ({
		cpu: {
			min: dynamicLimits?.Cpu,
			max: selectedParent?.vcpu,
			current: currentCpu,
		},
		ram: {
			min: dynamicLimits?.Memory,
			max: maxRam,
			current: currentRam,
		},
		disk: {
			min: preventResizeDown ? currentStorage : dynamicLimits?.Disk,
			max: maxDisk,
			current: currentStorageDisplay,
		},
		initialValues: {
			cpu: initialCpu,
			cpuText: initialCpu,
			ram: initialRam,
			ramText: initialRam,
			disk: initialDisk,
			diskText: initialDisk,
		},
	}),
);

const getParentRegion = createSelector(
	optionsSelectors.selectedPrivateParent,
	(selectedParent) => selectedParent?.region_id,
);

const privateParentSelectors = {
	getDynamicChildLimits,
	selectionValidationError,
	getParentRegion,
	getInitialCpu,
	getInitialDisk,
	getInitialRam,
	getSliderProps,
	getMaxDisk,
	getMaxRam,
	formValuesSelector,
	isLoading,
};

export { privateParentSelectors as selectors, formName };
export default privateParentSelectors;
