import { getIsStorm } from 'banana-stand/parsers';
import { configKeys } from 'utility/constants/baskets';
import { abbrevToRegion } from 'utility/constants/regions';
import { currency } from 'utility/format';

/**
 * given ValuesData, returns the the ptov with the lowest display order and the lowest price
 * @param {ValuesData} valuesData
 * @param {number} geo - the selected region/zone
 */
const getFallbackPtov = (valuesData, geo) => {
	const { fallbackPtov } = Object.entries(valuesData).reduce(
		(
			prev,
			[currentPtovId, { displayOrder, pricesByGeo, extraData, isUnavailable }],
		) => {
			const valueExistsInGeo = pricesByGeo[geo] !== undefined;
			if (!valueExistsInGeo || extraData?.isDeprecated || isUnavailable) {
				return prev;
			}

			if (displayOrder < prev.displayOrder) {
				return {
					fallbackPtov: Number(currentPtovId),
					displayOrder,
					price: pricesByGeo[geo],
				};
			}

			if (displayOrder === prev.displayOrder && pricesByGeo[geo] < prev.price) {
				return {
					fallbackPtov: Number(currentPtovId),
					displayOrder,
					price: pricesByGeo[geo],
				};
			}

			return prev;
		},
		{ fallbackPtov: 0, displayOrder: Infinity, price: Infinity },
	);

	return fallbackPtov;
};

const getLowestRegionId = (regionIdPrices) => {
	if (!regionIdPrices) {
		return 1;
	}

	return Math.min(...Object.keys(regionIdPrices));
};

/**
 * Parses a product code for a region identifier at the end of the product code and returns the corresponding region number. If no region identifier exists, it assumes the product is for region 1.
 * @param {String} productCode
 */
const pickRegionFromProductCode = (productCode) => {
	const productCodeSplit = productCode.split('.');
	const regionAbbrev = productCodeSplit[productCodeSplit.length - 1];
	const region = abbrevToRegion[regionAbbrev];
	return region || 1;
};

/**
 * picks the default region for a product - uses the user default region if it is available for the product
 * @param {Object} param0
 * @param {String} param0.productCode
 * @param {Number | String} param0.userDefaultRegion the user's selected default region that is set in their account settings
 * @param {Object.<string, Number | String>} param0.regionIdPrices the base prices of the product at each available region
 */
const pickDefaultRegion = ({
	productCode,
	userDefaultRegion,
	regionIdPrices,
}) => {
	if (!getIsStorm(productCode)) {
		return pickRegionFromProductCode(productCode);
	}

	if (userDefaultRegion in regionIdPrices) {
		return userDefaultRegion;
	}

	return getLowestRegionId(regionIdPrices);
};

/**
 * Checks if the product needs a zone property
 * @param {ProductDataDetails} productDataDetails
 */
const getNeedsZone = (productDataDetails) => {
	if (!productDataDetails) {
		return false;
	}

	return Object.values(productDataDetails.ptokData).some(
		(ptokDataDetails) => ptokDataDetails.key === 'ConfigId',
	);
};

/**
 * Returns zone for the product option keys that depend on zone, returns region for the rest
 * @param {String} key the readable key for the ptok
 * @param {{region: String | Number, zone: String | Number}} param1
 */
const pickGeo = (key, { region, zone }) => {
	switch (key) {
		case configKeys.ConfigId:
		case configKeys.Template:
			return zone;
		default:
			return region;
	}
};

/**
 * Given maps of region ids to prices and names, creates an LWSelect options object.
 * @param {Object.<string, number>} regionIdPrices
 * @param {Object.<string, string>} regionIdToName
 * @param {string} priceTimeUnit
 * @returns {{value: string, label: string}}
 */
const createRegionOptions = (regionIdPrices, regionIdToName, priceTimeUnit) => {
	const basePrice = Math.min(...Object.values(regionIdPrices));

	return Object.entries(regionIdPrices).map(([region, price]) => {
		const priceDifference = Number(price) - basePrice;
		const regionName = regionIdToName[region] || `Region ${region}`;

		if (priceDifference === 0) {
			return {
				value: region,
				label: regionName,
			};
		}

		return {
			value: region,
			label: regionName,
			priceDifference,
			priceTimeUnit,
		};
	});
};

/**
 * Takes in a ValuesData object to return whether or not it contains a private value.
 * @param {Object} param0
 * @param {ValuesData} param0.values
 * @returns {boolean}
 */
const getHasHiddenValue = ({ values }) => {
	return Object.values(values).some((value) => !value.isPublic);
};

/**
 * Gets the text appended to the end of a product option value's label.
 *
 * This can either be the price delta, or price delta with the numUnits.
 * If showSelectedNumUnits num units is true, the price delta will be the total difference.
 * If showSelectedNumUnits num units is false, the price delta will be the difference per unit.
 * @param {object} param0
 * @param {number} param0.selectedPrice - the price of the value being appended
 * @param {number} param1.selectedPricePerUnit - the price of the value per unit being appended
 * @param {number} param0.valuePrice - the price of the value selected for comparison.
 * @param {string} param0.timeUnit - the time unit to be appended to the price.
 * @param {boolean} param0.hasNumUnits - If true, this will display the price per unit instead of the difference.
 * @param {number} param0.selectedNumUnits - The selected number of units for the currently selected option.
 * @param {boolean} param0.showSelectedNumUnits - if true, the selected numUnits and total price delta will be shown.
 * @returns {string}
 * @example
 * // returns ' [+$1.00 / mo]'
 * getOptionSuffix({
 * 	selectedPrice: 1,
 * 	valuePrice: 2,
 * 	timeUnit: month,
 * });
 * @example
 * // returns ' [+$1.00 / unit]'
 * getOptionSuffix({
 * 	valuePrice: 2,
 * 	selectedPrice: 1,
 * 	selectedNumUnits: 3,
 * 	hasNumUnits: true,
 * });
 * @example
 * // returns ' (3 units) [+$3.00 / season]'
 * getOptionSuffix({
 * 	valuePrice: 2,
 * 	selectedPricePerUnit: 1,
 * 	timeUnit: altTimeUnit,
 * 	showSelectedNumUnits: true,
 * 	selectedNumUnits: 3,
 * 	hasNumUnits: true,
 * });
 */
const getOptionSuffix = ({
	selectedPrice,
	selectedPricePerUnit,
	valuePrice,
	timeUnit: timeUnitArg,
	hasNumUnits,
	selectedNumUnits,
	showSelectedNumUnits,
}) => {
	let priceDiff;
	if (showSelectedNumUnits) {
		priceDiff =
			valuePrice * selectedNumUnits - selectedPricePerUnit * selectedNumUnits;
	} else {
		priceDiff = valuePrice - selectedPrice;
	}

	let timeUnit;
	if (hasNumUnits && !showSelectedNumUnits) {
		timeUnit = 'unit';
	} else if (timeUnitArg === 'month') {
		timeUnit = 'mo';
	} else {
		timeUnit = timeUnitArg;
	}

	if (showSelectedNumUnits && hasNumUnits) {
		let returnString = ` (${selectedNumUnits} units)`;
		if (priceDiff)
			returnString += ` [${currency(priceDiff, {
				sign: true,
				timeUnit,
			})}]`;
		return returnString;
	}
	if (priceDiff) {
		return ` [${currency(priceDiff, {
			sign: true,
			timeUnit,
		})}]`;
	}
	return '';
};

export {
	pickRegionFromProductCode,
	getFallbackPtov,
	getHasHiddenValue,
	getOptionSuffix,
	pickDefaultRegion,
	getLowestRegionId,
	getNeedsZone,
	pickGeo,
	createRegionOptions,
};
