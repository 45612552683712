import React from 'react';

import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';

import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import dialogActions from 'modules/dialogs/actions';
import targetPowerActions from 'modules/server/targetPower/actions';
import LWTypography from 'components/common/LWTypography';
import { POWER_ACTION, POWER_STATUS } from 'utility/powerStatus';

const ServerStartDialogContents = ({ uniqId, onConfirm }) => {
	const dispatch = useDispatch();

	const close = () => {
		dispatch(dialogActions.close());
	};

	const submit = () => {
		close();
		dispatch(
			targetPowerActions.setServerTargetPower({
				uniqId,
				action: POWER_ACTION.START,
				status: POWER_STATUS.ON,
			}),
		);
		onConfirm();
	};

	return (
		<Form
			onSubmit={submit}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<LWTypography>
						Are you sure you want to start this server?
					</LWTypography>
					<ConfirmCancel cancel={close} confirmType="submit" />
				</form>
			)}
		/>
	);
};

export default ServerStartDialogContents;
