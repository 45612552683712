import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import useProjectAdd from 'modules/queries/project/useAdd';
import snackbarActions from 'modules/snackbar/snackbarActions';
import DialogStates from './DialogStates';

const ProjectAddDialog = ({
	dialogProps,
	fetchAvailableAssets,
	fetchCategories,
	fetchPriceData,
	availableAssetsData,
	categoriesData,
	priceDataObj: { data: priceData },
	toggleDialog,
	isOpen,
}) => {
	const dispatch = useDispatch();
	const { mutate: addToProject } = useProjectAdd();
	const { projectId, projectName } = dialogProps;
	const [category, setCategory] = useState('');
	const [asset, setAsset] = useState({ uniq_id: '', domain: '', type: '' });
	const [assetCurrentProject, setAssetCurrentProject] = useState({
		uniqId: '',
		name: '',
	});

	const [showClusterVerbiage, setShowClusterVerbiage] = useState(false);

	// reset select fields on dialog open
	useEffect(() => {
		if (isOpen) {
			setCategory('');
			setAsset({ uniq_id: '', domain: '', type: '' });
			setAssetCurrentProject({ uniqId: '', name: '' });
			const region = 1; // TODO: remove hardcoded region when we have this data available
			fetchCategories();
			fetchPriceData(region);
			setShowClusterVerbiage(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const changeCategory = (value) => {
		fetchAvailableAssets({ category: value, projectId });
		setCategory(value);
	};

	const changeAsset = (value) => {
		const selectedUniqId = value;
		const assetData = availableAssetsData.assets.find(
			(a) => a.get('uniq_id') === selectedUniqId,
		);
		setAsset(assetData.toJS());
		setAssetCurrentProject({
			uniqId: assetData.get('project_id'),
			name: assetData.get('project_name'),
		});
	};

	const dialogStates = new DialogStates({
		showClusterVerbiage,
		assetCurrentProject,
		asset,
		priceData,
		categoriesData,
		projectName,
		category,
		changeCategory,
		availableAssetsData,
		changeAsset,
	});

	// the check, `category === 'servers'` is probably redundant.
	const thereIsMore =
		category === 'servers' && asset.clusterable && !showClusterVerbiage;
	const handleClose = () => {
		if (!thereIsMore) {
			toggleDialog(false);
		}
	};

	const handleConfirm = () => {
		if (thereIsMore) {
			setShowClusterVerbiage(true);
		} else {
			addToProject(
				{
					assets: [asset.uniq_id],
					id: projectId,
					cluster: 1, // hard coded per NEWMAN-1144
				},
				{
					onSuccess: () => {
						dispatch(
							snackbarActions.pushMessage({
								variant: 'success',
								message: `Successfully added ${asset.domain} to ${projectName}`,
							}),
						);
					},
				},
			);
		}
	};

	const confirmable = !!(
		dialogStates.errorTitle !== dialogStates.title &&
		category &&
		asset &&
		asset.uniq_id
	);

	return (
		<ConfirmationDialog
			confirmProps={{ disabled: !confirmable }}
			open={isOpen}
			onConfirm={handleConfirm}
			onCancel={() => toggleDialog(false)}
			onClose={handleClose}
			title={dialogStates.title}
			confirmText={dialogStates.confirmText}
		>
			<Box pt={2} px={1} width={['100%', '100%', 552]}>
				{dialogStates.contents}
			</Box>
		</ConfirmationDialog>
	);
};

ProjectAddDialog.propTypes = {
	fetchCategories: PropTypes.func.isRequired,
	fetchPriceData: PropTypes.func.isRequired,
	categoriesData: PropTypes.object.isRequired,
	fetchAvailableAssets: PropTypes.func.isRequired,
	availableAssetsData: PropTypes.object.isRequired,
	dialogProps: PropTypes.object,
	toggleDialog: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
};

export default ProjectAddDialog;
