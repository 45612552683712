import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) =>
	state.api.billing.paypal.agreement.bindAgreement;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BILLING_PAYPAL_AGREEMENT_BINDAGREEMENT',
	url: '/billing/paypal/agreement/bindAgreement.json',
});

const selectors = {
	setPayMethod: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'set_pay_method'], null),
	),
	token: createSelector(defaultSelectors.getStateSlice, (slice) =>
		slice.getIn(['data', 'token'], null),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
