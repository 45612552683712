import { compose } from 'redux';
import { connect } from 'react-redux';
import {
	actions as renewActions,
	selectors as renewSelectors,
} from 'modules/api/network/dns/domain/renewModule';
import { reduxForm, formValueSelector, change } from 'redux-form';
import {
	selectors as productSelectors,
	actions as productActions,
} from 'modules/api/product/detailsModule';
import { selectors as detailsSelectors } from 'modules/api/network/dns/domain/detailsModule';
import { getPayload } from 'modules/renewDialog/selectors';
import renewDialogActions from 'modules/renewDialog/actions';
import RenewDomainForm from './RenewDomainForm';

const form = 'renewDomainForm';
const formSelector = formValueSelector(form);

const mapStateToProps = (state) => ({
	initialValues: {
		number_of_years: 1,
		autoRenew: detailsSelectors.autorenew(state),
		privacy: detailsSelectors.whoisPrivacy(state) === 'On',
	},
	selected: {
		numberOfYears: formSelector(state, 'number_of_years'),
		autoRenew: formSelector(state, 'autoRenew'),
		privacy: formSelector(state, 'privacy'),
	},
	renewDialogObj: {
		payload: getPayload(state),
	},
	details: {
		domain: detailsSelectors.domain(state),
		data: detailsSelectors.getData(state),
		isExpired: detailsSelectors.expired(state),
		isLoading: detailsSelectors.isLoading(state),
		isRGP: !!detailsSelectors.isRGP(state),
		redemptionPrice: detailsSelectors.redemptionPrice(state),
		renewalMaxYears: detailsSelectors.renewalMaxYears(state),
		renewalPrice: detailsSelectors.renewalPrice(state),
	},
	product: {
		code: productSelectors.code(state),
		isLoading: productSelectors.isLoading(state),
		options: productSelectors.options(state).toJS(),
	},
	renewObj: {
		data: renewSelectors.getNativeData(state),
		isLoading: renewSelectors.isLoading(state),
	},
});

const mapDispatchToProps = (dispatch) => ({
	toggleDialog: (dialogOpen) => {
		dispatch(renewDialogActions.toggleDialog(dialogOpen));
	},
	renewDomain: ({ domain, duration, privacy, autoRenew }) =>
		dispatch(
			renewActions.fetch({
				domain,
				duration,
				privacy,
				autoRenew,
			}),
		),
	changeFieldValue: (field, value) => {
		dispatch(change(form, field, value));
	},
	fetchProduct: ({ code }) => dispatch(productActions.fetch({ code })),
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	reduxForm({
		form,
		enableReinitialize: true,
	}),
)(RenewDomainForm);
