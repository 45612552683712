import { connect } from 'react-redux';
import {
	getError,
	getTitle,
	getProductType,
	getDialogOpen,
} from 'modules/renewDialog/selectors';
import renewDialogActions from 'modules/renewDialog/actions';
import RenewDialog from './RenewDialog';

const mapStateToProps = (state) => ({
	renewDialogObj: {
		title: getTitle(state),
		productType: getProductType(state),
		dialogOpen: getDialogOpen(state),
		error: getError(state),
	},
});

const mapDispatchToProps = (dispatch) => ({
	toggleDialog: (dialogOpen) => {
		dispatch(renewDialogActions.toggleDialog(dialogOpen));
	},
	clearError: () => {
		dispatch(renewDialogActions.setError(null));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(RenewDialog);
