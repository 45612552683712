import { createSelector } from 'reselect';

const getStateSlice = (state) => state.account.address;

const getAddress = createSelector(getStateSlice, (slice) => slice.address);

const getSubmit = createSelector(getStateSlice, (slice) => slice.submit);

const getIsValidateDialogOpen = createSelector(
	getStateSlice,
	(slice) => slice.isValidateDialogOpen,
);

const selectors = {
	getStateSlice,
	getAddress,
	getSubmit,
	getIsValidateDialogOpen,
};

// TODO: we use default exports for selectors now. Remove non-defaults.
export { getStateSlice, getAddress, getSubmit, getIsValidateDialogOpen };
export default selectors;
