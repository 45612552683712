import React from 'react';
import PropTypes from 'prop-types';
import LWDialog from 'components/common/LWDialog';
import Box from '@material-ui/core/Box';

import AbandonCartModalContents from 'components/cart/AbandonCartModalContents';
import AcronisCancelConfirmationDialogContents from 'components/Acronis/Cancel/dialogs/AcronisCancelConfirmationDialogContents';
import AcronisCancelDialogContents from 'components/Acronis/Cancel/dialogs/AcronisCancelDialogContents';
import AddAcronisModalContents from 'containers/pages/servers/BackupsAndImages/cloud/Settings/Acronis/AddAcronis/AddAcronisModalContents';
import AppVersionModalContents from 'containers/connectedOrganisms/AppVersionModalContents';
import BackupConfirmationDialogContents from 'containers/pages/servers/BackupsAndImages/dialogs/BackupConfirmationDialogContents';
import BackupRestoreDialogContents from 'containers/pages/servers/BackupsAndImages/dialogs/BackupRestoreDialogContents';
import CloneServerModalContents from 'containers/pages/servers/details/CloneServerModalContents';
import ConfigSelectModalContents from 'containers/pages/shop/BasketItemWizard/ProductConfig/dropdownDialogs/ConfigSelectModalContents';
import CreateNewCartModalContents from 'containers/pages/shop/dialogs/CreateNewCartModalContents';
import DeleteDNSZoneDialogContents1 from 'containers/pages/domains/details/dns/dialogs/DeleteDNSZoneDialogContents1';
import DeleteDNSZoneDialogContents2 from 'containers/pages/domains/details/dns/dialogs/DeleteDNSZoneDialogContents2';
import DetourDialogContent from 'components/structural/DetourDialogContent';
import FavoritesListDialogContents from 'components/common/FavoritesList/FavoritesListDialogContents';
import HostnameSelection from 'components/cart/Item/HostnameSelection';
import ImageConfirmationDialogContents from 'containers/pages/servers/BackupsAndImages/dialogs/ImageConfirmationDialogContents';
import ImageServerSelectDialogContents from 'containers/pages/servers/BackupsAndImages/dialogs/ImageServerSelectDialogContents';
import MasqueradeModalContents from 'components/cart/MasqueradeModalContents';
import OpportunityModalContents from 'components/common/CartBadge/OpportunityModalContents';
import TemplateConfirmationDialogContents from 'containers/pages/servers/BackupsAndImages/dialogs/TemplateConfirmationDialogContents';
import CreateAdminCartDialogContents from 'containers/connectedOrganisms/CreateAdminCartDialogContents';
import EditCartDetailsDialogContents from 'containers/connectedOrganisms/dialogs/EditCartDetailsDialogContents';
import PrivateParentNameDialogContents from 'containers/connectedOrganisms/PrivateParentNameDialogContents';
import ServerCredentialsDialogContents from 'containers/pages/servers/details/dialogs/CredentialsDialogContents';
import ServerShutdownDialogContents from 'containers/pages/servers/details/ServerShutdown/ServerShutdownDialogContents';
import ServerStartDialogContents from 'containers/pages/servers/details/ServerStart/ServerStartDialogContents';
import CreateBlockStorage from 'containers/pages/services/CloudBlockStorage/Dialogs/CreateBlockStorage';
import CreateLoadBalancer from 'containers/pages/services/CloudBlockStorage/Dialogs/CreateLoadBalancer';

import {
	contentKeyToTitleMap,
	contentKeyToAddPadding,
	contentKeyToColor,
} from './maps';

const ContentFromKey = ({ contentKey, contentProps }) => {
	const Component = (() => {
		switch (contentKey) {
			case 'AbandonCartModalContents':
				return AbandonCartModalContents;
			case 'AcronisCancelConfirmationDialogContents':
				return AcronisCancelConfirmationDialogContents;
			case 'AcronisCancelDialogContents':
				return AcronisCancelDialogContents;
			case 'AddAcronisModalContents':
				return AddAcronisModalContents;
			case 'AppVersionModalContents':
				return AppVersionModalContents;
			case 'BackupConfirmationDialogContents':
				return BackupConfirmationDialogContents;
			case 'BackupRestoreDialogContents':
				return BackupRestoreDialogContents;
			case 'ConfigSelectModalContents':
				return ConfigSelectModalContents;
			case 'CreateNewCartModalContents':
				return CreateNewCartModalContents;
			case 'CloneServerModalContents':
				return CloneServerModalContents;
			case 'DeleteDNSZoneDialogContents1':
				return DeleteDNSZoneDialogContents1;
			case 'DeleteDNSZoneDialogContents2':
				return DeleteDNSZoneDialogContents2;
			case 'DetourDialogContent':
				return DetourDialogContent;
			case 'FavoritesListDialogContents':
				return FavoritesListDialogContents;
			case 'HostnameSelectionDialog':
				return HostnameSelection;
			case 'PrivateParentNameDialog':
				return PrivateParentNameDialogContents;
			case 'ImageConfirmationDialogContents':
				return ImageConfirmationDialogContents;
			case 'ImageServerSelectDialogContents':
				return ImageServerSelectDialogContents;
			case 'MasqueradeModalContents':
				return MasqueradeModalContents;
			case 'OpportunityModalContents':
				return OpportunityModalContents;
			case 'TemplateConfirmationDialogContents':
				return TemplateConfirmationDialogContents;
			case 'CreateAdminCartDialogContents':
				return CreateAdminCartDialogContents;
			case 'EditCartDetailsDialogContents':
				return EditCartDetailsDialogContents;
			case 'ServerCredentialsDialogContents':
				return ServerCredentialsDialogContents;
			case 'ServerShutdownDialogContents':
				return ServerShutdownDialogContents;
			case 'ServerStartDialogContents':
				return ServerStartDialogContents;
			case 'CreateBlockStorageContent':
				return CreateBlockStorage;
			case 'CreateLoadBalancerContent':
				return CreateLoadBalancer;
			default:
				// eslint-disable-next-line no-console
				console.error(`The content Key, ${contentKey} could not be found.`);
				return <Box />;
		}
	})();
	return <Component {...contentProps} isDialog />;
};

const Dialogs = ({
	onClose, // from connected
	resetDialog, // from connected
	open,
	dialogProps,
	content,
	contentProps,
	contentKey,
	color = contentKeyToColor[contentKey],
	title = contentKeyToTitleMap[contentKey],
	dismissible,
	maxWidth = 'sm',
}) => {
	return (
		<LWDialog
			title={title}
			color={color}
			onClose={onClose}
			open={open}
			maxWidth={maxWidth}
			fullWidth
			{...dialogProps}
			dismissible={dismissible}
			contentPadding={contentKeyToAddPadding[contentKey]}
			resetDialog={resetDialog}
		>
			{
				content ||
					(contentKey ? (
						<ContentFromKey
							contentKey={contentKey}
							contentProps={contentProps}
						/>
					) : null)
				// !TODO figure out why an empty dialog flashes onClose, causing this Fragment to be needed
			}
		</LWDialog>
	);
};

Dialogs.propTypes = {
	/**  */
	title: PropTypes.string,
	/**  function executed to close dialog */
	onClose: PropTypes.func,
	/** from dialogs/reducer */
	open: PropTypes.bool,
	color: PropTypes.string,
	dialogProps: PropTypes.object,
	content: PropTypes.node,
	contentProps: PropTypes.object,
	contentKey: PropTypes.string,
	/**  */
	dismissible: PropTypes.bool,
	/** MUI Prop passed to Dialog */
	maxWidth: PropTypes.string,
};

export default Dialogs;
