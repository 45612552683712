import { useTheme } from '@material-ui/core/styles';
import get from 'lodash/get';

const useColor = (color) => {
	const theme = useTheme();

	return color.startsWith('palette') ? get(theme, color) : color;
};

export default useColor;
