import { spawn } from 'redux-saga/effects';
import { sagas as throttleDispatchSagas } from './throttleDispatch';
import { sagas as downloadSagas } from './download';
import copyToClipboardSagas from './copyToClipboard/sagas';

export default function* utilitySagas() {
	yield spawn(throttleDispatchSagas);
	yield spawn(downloadSagas);
	yield spawn(copyToClipboardSagas);
}
