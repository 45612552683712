export const HARDWARE_FILTER = {
	gp: { label: 'General Compute', displayOrder: 1 },
	'mem-opt': { label: 'Memory Optimized', displayOrder: 2 },
	'cpu-opt': { label: 'CPU Optimized', displayOrder: 3 },
	'bare-metal': { label: 'Bare Metal', displayOrder: Infinity },
	storm: { label: 'Storm', displayOrder: Infinity },
	ssd: { label: 'SSD', displayOrder: Infinity },
};

export const NO_FILTER = 'noFilters';
export const SLIDER_FILTER = 'slider';
export const BUTTON_GROUP_FILTER = 'buttongroup';
