import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import get from 'lodash/get';

import SubNavRoutes from 'components/routers/SubNavRoutes';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { initialized } from 'modules/gtm/selectors';
import { roles } from 'utility/constants/roles';

const Marketplace = lazy(() => import('containers/pages/shop/Marketplace'));

const AddProduct = lazy(() => import('containers/pages/shop/AddProduct'));

const BasketItemWizard = lazy(
	() => import('containers/pages/shop/BasketItemWizard'),
);

const DomainSearch = lazy(
	() => import('containers/pages/domains/Register/Search'),
);

const DomainConfigure = lazy(
	() => import('containers/pages/domains/Register/Configure'),
);

const PaymentMethod = lazy(
	() => import('containers/pages/shop/Cart/PaymentMethod'),
);

const Checkout = lazy(() => import('containers/pages/shop/Cart/Checkout'));

const OrderConfirmation = lazy(
	() => import('containers/pages/shop/OrderConfirmation/OrderConfirmation'),
);

const ConfigPackage = lazy(() => import('containers/pages/shop/ConfigPackage'));
const SpecialOffers = lazy(() => import('containers/pages/shop/SpecialOffers'));

const ShopRouter = (props) => {
	const location = useLocation();
	const history = useHistory();
	const gtmInitialized = useSelector(initialized);
	useEffect(() => {
		// can't do navigation events until GTM has been initialized
		if (gtmInitialized && location.pathname === '/shop') {
			history.replace('/shop/marketplace');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, gtmInitialized]);

	const { navData } = props;
	return (
		<Switch>
			<Route
				exact
				path={get(navData, 'path')}
				render={(rProps) => get(navData, 'component')(rProps)}
			/>
			<RoleRestrictedRoute
				exact
				path={`${get(navData, 'path')}/marketplace/:tab`}
				component={Marketplace}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${get(navData, 'path')}/marketplace`}
				component={Marketplace}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${get(navData, 'path')}/domain`}
				component={DomainSearch}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${get(navData, 'path')}/domain/configure`}
				component={DomainConfigure}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<Route
				exact
				path={`${get(navData, 'path')}/add/:productCode`}
				component={AddProduct}
			/>
			<Route
				exact
				path={`${get(navData, 'path')}/config/:productKey/:step`}
				component={BasketItemWizard}
			/>
			<Route
				exact
				path={`${get(navData, 'path')}/config/:productKey`}
				component={BasketItemWizard}
			/>
			<Route
				exact
				path={`${get(navData, 'path')}/package/:packageCode`}
				component={ConfigPackage}
			/>
			<Route
				exact
				path={`${get(navData, 'path')}/package/:packageCode/:uuid`}
				component={ConfigPackage}
			/>
			<RoleRestrictedRoute
				exact
				path={`${get(navData, 'path')}/payment-method`}
				component={PaymentMethod}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${get(navData, 'path')}/checkout`}
				component={Checkout}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${get(navData, 'path')}/special-offers`}
				component={SpecialOffers}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				name="Order Confirmation"
				path="/shop/complete"
				component={OrderConfirmation}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>

			<SubNavRoutes
				subNavs={get(navData, 'subNav')}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
		</Switch>
	);
};

ShopRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};

export default ShopRouter;
