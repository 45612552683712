import { createSelector } from 'reselect';

const getStateSlice = (state) => state.renameProjectDialog;

const selectors = {
	isOpen: createSelector(getStateSlice, (slice) => slice.get('isOpen')),
	dialogProps: createSelector(getStateSlice, (slice) =>
		slice.get('dialogProps'),
	),
};

// eslint-disable-next-line import/prefer-default-export
export { selectors };
