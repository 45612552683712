import { fromApiBoolean } from 'club-sauce';
import { selectors as billingAddressSelectors } from 'modules/api/account/billingAddress/detailsModule';
import { selectors as assetListSelectors } from 'modules/api/asset/listModule';
import { selectors as paymentDetailsSelectors } from 'modules/api/billing/payment/profile/detailsModule';
import { selectors as contactListSelectors } from 'modules/api/contact/listModule';
import { selectors as quoteCreateSelectors } from 'modules/api/market/cart/admin/quote/createModule';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import { cartExpireSelectors } from 'modules/api/market/cart/expireModule';
import { cartMetadataSelectors } from 'modules/api/market/cart/metadataModule';
import { selectors as primaryCategoryMappingSelectors } from 'modules/api/product/primaryCategoryMappingModule';
import appConfigSelectors from 'modules/appConfig/selectors';
import { isBasketAdmin as getIsBasketAdmin } from 'modules/auth';
import { isSetupUser as getIsSetupUser } from 'modules/auth/authSelectors';
import { categoryMap } from 'modules/primaryCategoryMapping';
import { selectors as routeSelectors } from 'modules/route';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.cart;

const getCartIsInitialized = createSelector(
	getStateSlice,
	(slice) => slice.cartInitialized,
);

const getCartInitHasStarted = createSelector(
	getStateSlice,
	(slice) => slice.cartInitHasStarted,
);

const isCustomerPreview = createSelector(
	getStateSlice,
	(slice) => slice.isCustomerPreview,
);

const getIsOrderProcessing = createSelector(
	getStateSlice,
	(slice) => slice.isOrderProcessing,
);

const getHasStormItems = createSelector(
	cartDetailsSelectors.getNativeItems,
	primaryCategoryMappingSelectors.getNativeData,
	(items, catMap) =>
		Boolean(items.find((item) => categoryMap[catMap?.[item.code]] === 'cloud')),
);

const getHasDedicatedItems = createSelector(
	cartDetailsSelectors.getNativeItems,
	primaryCategoryMappingSelectors.getNativeData,
	(items, catMap) =>
		Boolean(
			items.find((item) => categoryMap[catMap?.[item.code]] === 'dedicated'),
		),
);

const getHasStormOrDedicated = createSelector(
	getHasStormItems,
	getHasDedicatedItems,
	(hasStormItems, hasDedicatedItems) => hasStormItems || hasDedicatedItems,
);

const allStepData = [
	{ path: '/account/create', label: 'Account Info' },
	{ path: '/shop/payment-method', label: 'Payment Method' },
	{ path: '/shop/special-offers', label: 'Special Offers' },
	{ path: '/shop/checkout', label: 'Checkout' },
];

const getHiddenSteps = createSelector(
	assetListSelectors.getHasItems,
	cartDetailsSelectors.getAccountDetailsVisited,
	cartDetailsSelectors.getShowSpecialOffers,
	getHasStormOrDedicated,
	(hasAsset, accountDetailsVisited, showSpecialOffers, hasStormOrDedicated) => {
		const hiddenSteps = {};
		// Account Info
		hiddenSteps[0] = hasAsset && !accountDetailsVisited;

		// Special Offers
		hiddenSteps[2] = !showSpecialOffers && !hasStormOrDedicated;
		return hiddenSteps;
	},
);

const getStepsData = createSelector(getHiddenSteps, (hiddenSteps) =>
	allStepData.filter((elem, idx) => !hiddenSteps[idx]),
);

const getActiveStep = createSelector(
	routeSelectors.getPathName,
	getStepsData,
	(pathName, stepsData) => stepsData.map(({ path }) => path).indexOf(pathName), // returns the 0 based count of the current step
);

const getNextStepPath = createSelector(
	cartDetailsSelectors.getShowSpecialOffers,
	cartDetailsSelectors.getSpecialOffersVisited,
	getHasStormOrDedicated,
	cartDetailsSelectors.getHasItems,
	paymentDetailsSelectors.getHasPaymentMethod,
	billingAddressSelectors.getHasBillingAddress,
	paymentDetailsSelectors.getPaymentMethodIsPaypal,
	getStepsData,
	getActiveStep,
	getIsSetupUser,
	contactListSelectors.getNativeItems,
	(
		showSpecialOffers,
		specialOffersVisited,
		hasStormOrDedicated,
		hasCartItems,
		hasPaymentMethod,
		hasBillingAddress,
		paymentMethodIsPaypal,
		stepsData,
		activeStep,
		isSetupUser,
		contacts,
	) => {
		if (activeStep !== -1 /** if the current path is in the step data. */) {
			if (stepsData[activeStep + 1]?.path) {
				// This shouldn't ever be false, but if I'm wrong, use the next algo.
				return stepsData[activeStep + 1].path;
			}
		}
		if (isSetupUser && !hasCartItems) {
			return '/shop/marketplace';
		}
		if (!contacts?.length) {
			return '/account/create';
		}
		if (hasPaymentMethod && (hasBillingAddress || paymentMethodIsPaypal)) {
			if (!specialOffersVisited && showSpecialOffers && hasStormOrDedicated)
				return '/shop/special-offers';
			return '/shop/checkout';
		}
		return '/shop/payment-method';
	},
);

const getIsCartLocked = createSelector(
	getIsBasketAdmin,
	cartDetailsSelectors.getIsQuote,
	cartDetailsSelectors.getIsOrderable,
	cartDetailsSelectors.getAdminData,
	(isBasketAdmin, isQuote, isOrderable, adminData) => {
		if (!isBasketAdmin) {
			return isQuote;
		}

		return fromApiBoolean(adminData.locked) || (isQuote && isOrderable);
	},
);

const getShouldAssertShowSpecialOffers = createSelector(
	getHasStormOrDedicated,
	cartDetailsSelectors.getShowSpecialOffers,
	getIsCartLocked,
	routeSelectors.getIsCart,
	(
		hasStormOrDedicatedItems,
		showSpecialOffersAlreadyAsserted,
		isCartLocked,
		isAtCart,
	) =>
		hasStormOrDedicatedItems &&
		!showSpecialOffersAlreadyAsserted &&
		!isCartLocked &&
		isAtCart,
);

const stepperIsLoading = createSelector(
	assetListSelectors.isLoading,
	cartMetadataSelectors.isLoading,
	cartDetailsSelectors.getAccountDetailsVisited,
	(assetListIsLoading, metaDataIsSending, hasVisited) =>
		assetListIsLoading || (metaDataIsSending && !hasVisited),
);

const getDisplayAdminUI = createSelector(
	getIsBasketAdmin,
	isCustomerPreview,
	(isBasketAdmin, isPreview) => !isPreview && isBasketAdmin,
);

const getExpireIsLoading = createSelector(
	cartExpireSelectors.isLoading,
	cartDetailsSelectors.hasData,
	(expireLoading, basketHasData) => expireLoading || !basketHasData,
);

const getIsCartBadgeLoading = createSelector(
	getCartIsInitialized,
	quoteCreateSelectors.isLoading,
	(cartHasInit, quoteCreateIsLoading) => !cartHasInit || quoteCreateIsLoading,
);

const getCartNotReady = createSelector(
	getIsBasketAdmin,
	cartDetailsSelectors.getNativeData,
	(isBasketAdmin, cartData) => !isBasketAdmin && !cartData?.orderable,
);

const getCartNameDisplay = createSelector(
	getIsBasketAdmin,
	cartDetailsSelectors.getMetadata,
	(isBasketAdmin, metadata) => {
		const nameRaw = metadata.name;

		if (isBasketAdmin && !nameRaw) {
			return 'Unnamed Cart';
		}

		return nameRaw || 'My Cart';
	},
);

const getCartTypeToolTip = createSelector(
	cartDetailsSelectors.getIsQuote,
	getIsBasketAdmin,
	(isQuote, isBasketAdmin) => {
		if (!isBasketAdmin) {
			return '';
		}

		if (isQuote) {
			return 'Quote';
		}

		return 'Basic Cart';
	},
);

const getSalesforceQuoteUrl = createSelector(
	cartDetailsSelectors.getQuoteId,
	appConfigSelectors.getSalesforceHostname,
	(quoteId, salesforceHostname) =>
		quoteId ? `${salesforceHostname}/Quote/${quoteId}/view` : '',
);

const selectors = {
	getActiveStep,
	getCartInitHasStarted,
	getCartIsInitialized,
	getCartNameDisplay,
	getCartNotReady,
	getCartTypeToolTip,
	getDisplayAdminUI,
	getExpireIsLoading,
	getHasDedicatedItems,
	getHasStormItems,
	getHiddenSteps,
	getIsCartBadgeLoading,
	getIsCartLocked,
	getIsOrderProcessing,
	getNextStepPath,
	getSalesforceQuoteUrl,
	getShouldAssertShowSpecialOffers,
	getStepsData,
	isCustomerPreview,
	stepperIsLoading,
};

export { allStepData };
export default selectors;
