// @ts-check
import classNames from 'classnames';
import React from 'react';

const thirdpartyClassnames = [
	/** @see https://help.fullstory.com/hc/en-us/articles/360020623574-How-do-I-protect-my-users-privacy-in-Fullstory */
	'fs-exclude',
	/** @see https://help.vwo.com/hc/en-us/articles/360019733813-How-to-secure-your-visitors-data-in-VWO-Session-Recordings */
	'nls_protected',
];

/**
 * @typedef ThirdpartyExcludePropsI
 * @property {JSX.Element} children
 */

/**
 * Exclude children from thirdparty apps
 *
 * Passphrases and secrets shouldn't be passed to fullstory and linkedin
 * @type React.ForwardRefExoticComponent<ThirdpartyExcludePropsI>
 */
export const ThirdpartyExclude = React.forwardRef((props, ref) => {
	const { children } = props;

	return React.cloneElement(children, {
		ref,
		className: classNames(children?.props?.className, thirdpartyClassnames),
	});
});
ThirdpartyExclude.displayName = 'ThirdpartyExclude';

export default ThirdpartyExclude;
