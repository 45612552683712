import React from 'react';
import { connect } from 'react-redux';
import compose from 'utility/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import navLinks from 'config/navData';
import LWLink from 'components/common/LWLink';
import NavSupport from 'components/structural/footer/NavSupport';
import NavContact from 'components/structural/footer/NavContact';
import manageRedirectActions from 'modules/manageRedirect/manageRedirectActions';
import { hasAuthToken as hasAuthTokenSelector } from 'modules/auth/authSelectors';

export const styles = ({ breakpoints, spacingPage, palette, maxWidth }) => ({
	footerNav: {
		padding: spacingPage,
		margin: 'auto',
		maxWidth,
		[breakpoints.up('md')]: {
			marginBottom: '-70px',
		},
	},
	footerSubNav: {
		marginTop: '1em',
	},
	[breakpoints.up('md')]: {
		navColumn: {
			maxWidth: '14.28%', // 7 equally spaced columns
			flexBasis: '14.28%',
		},
	},
	[breakpoints.down('sm')]: {
		navColumn: {
			maxWidth: '50%',
			flexBasis: '50%',
		},
	},
	navColumn: {
		flexGrow: 0,
	},
	listItem: {
		padding: '0em',
	},
	link: {
		fontSize: '.75em',
		lineHeight: '1.8em',
		color: palette.common.white1,
	},
	title: {
		color: palette.common.white1,
		fontWeight: '700',
		fontSize: '1.3em',
		borderBottom: `3px solid ${palette.primary.dark}`,
		width: '80%',
		display: 'block',
	},
});

const Nav = ({ hasAuthToken = null, classes, openManageRedirectDialog }) => {
	const ignoredPaths = ['/', '/add'];
	const { navColumn, title, footerNav, footerSubNav, link, listItem } = classes;
	const allowedNavLinks = navLinks.filter(
		// TODO change this to a map
		(navLink) => !ignoredPaths.includes(navLink.path) && !navLink.navHidden,
	);
	return (
		<Grid container direction="row" className={footerNav} spacing={0}>
			{hasAuthToken &&
				allowedNavLinks.map((rootNav) => (
					<Grid item key={rootNav.name} className={navColumn}>
						<LWLink
							to={rootNav.path}
							darkBackground
							underline="none"
							onClick={
								rootNav.notYetImplemented ? openManageRedirectDialog : null
							}
						>
							{rootNav.name}
						</LWLink>
						<Grid container direction="column" className={footerSubNav}>
							<List>
								{Object.values(rootNav.subNav)
									.filter((subNav) => !subNav.navHidden)
									.map((subNav) => (
										<ListItem key={subNav.name} classes={{ root: listItem }}>
											<LWLink
												to={subNav.path}
												variant="caption"
												underline="none"
												darkBackground
												onClick={
													subNav.notYetImplemented
														? openManageRedirectDialog
														: null
												}
											>
												{subNav.name}
											</LWLink>
										</ListItem>
									))}
							</List>
						</Grid>
					</Grid>
				))}
			<NavSupport
				classes={{
					navColumn,
					title,
					footerSubNav,
					link,
					listItem,
				}}
			/>
			<NavContact
				classes={{
					navColumn,
					footerSubNav,
					listItem,
				}}
			/>
		</Grid>
	);
};

Nav.propTypes = {
	classes: PropTypes.object.isRequired,
	hasAuthToken: PropTypes.bool,
	openManageRedirectDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	hasAuthToken: hasAuthTokenSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
	openManageRedirectDialog: (e, url) => {
		e.preventDefault();
		dispatch(manageRedirectActions.setOpen(url));
	},
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles),
)(Nav);
