export const regionIdMap = {
	1: 'US Central: Michigan, USA',
	2: 'US West: Arizona, USA',
	3: 'EU West: Amsterdam, NL',
};

export const getLocation = (region, zone, regionId) => {
	const regionName = region?.name || regionIdMap[regionId];
	return zone?.name ? `${regionName} - ${zone.name}` : regionName;
};
