import testUtilActions from 'testUtils/actions';
import packageConfigActions from './actions';

/** @type {PackageConfigState} */
const defaultState = {
	allSelectedConfigs: {},
	selectedBillingCycleId: '',
	allSelectedRegions: {},
	allProductProperties: {},
	allPtokToIsRevealed: {},
};

const packageConfigReducer = (state = defaultState, action) => {
	switch (action.type) {
		case packageConfigActions.PACKAGE_CONFIG_ADD_PROPERTY: {
			const { key, packageProductId, value } = action;

			const prevProperties = state.allProductProperties[packageProductId];
			return {
				...state,
				allProductProperties: {
					...state.allProductProperties,
					[packageProductId]: {
						...prevProperties,
						[key]: value,
					},
				},
			};
		}

		case packageConfigActions.PACKAGE_CONFIG_DELETE_PROPERTY: {
			const { key, packageProductId } = action;

			const { [key]: removed, ...currentProperties } =
				state.allProductProperties[packageProductId];

			return {
				...state,
				allProductProperties: {
					...state.allProductProperties,
					[packageProductId]: {
						...currentProperties,
					},
				},
			};
		}

		case packageConfigActions.PACKAGE_CONFIG_SET_PROPERTIES:
			return {
				...state,
				allProductProperties: {
					...state.allProductProperties,
					[action.packageProductId]: action.properties,
				},
			};

		case packageConfigActions.PACKAGE_CONFIG_SET_BILLING_CYCLE:
			return { ...state, selectedBillingCycleId: action.cycleId };

		case packageConfigActions.PACKAGE_CONFIG_SET_SELECTED_CONFIGS:
			return {
				...state,
				allSelectedConfigs: {
					...state.allSelectedConfigs,
					[action.packageProductId]: action.selectedConfigs,
				},
			};

		case packageConfigActions.PACKAGE_CONFIG_SET_SELECTED_REGION: {
			return {
				...state,
				allSelectedRegions: {
					...state.allSelectedRegions,
					[action.packageProductId]: action.selectedRegion,
				},
			};
		}

		case packageConfigActions.PACKAGE_CONFIG_SET_PTOK_TO_IS_REVEALED: {
			return {
				...state,
				allPtokToIsRevealed: {
					...state.allPtokToIsRevealed,
					[action.packageProductId]: action.ptokToIsRevealed,
				},
			};
		}

		case testUtilActions.TEST_UTIL_CLEAR_STATE:
			return defaultState;

		default:
			return state;
	}
};

export { defaultState };
export default packageConfigReducer;
