import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from 'components/atoms/TextField';

import { getTaxIdLabel } from 'utility/addressLabel';

import TAX_EXEMPT_COUNTRIES from 'utility/constants/taxExemptCountries';
import { taxIdValidators } from 'utility/form/validators';

import TaxExemptFields from './TaxExemptFields';
import variantConfigs from './variantConfigs';

const TEST_ID = 'AddressForm_TaxFormSection';

const TaxFormSection = ({
	changeFieldValue = () => {},
	disabled,
	formValues,
	selectedCountry = { label: 'Country unknown' },
	taxExemptReasons = [],
	variant = 'default',
	isLoading,
}) => {
	const displayTaxExemptFields = useMemo(
		() => TAX_EXEMPT_COUNTRIES.includes(selectedCountry?.label),
		[selectedCountry],
	);

	const { gridItemSettings, gridContainerSettings, showCountry } =
		variantConfigs[variant];

	const countryValue = selectedCountry.label
		? `${selectedCountry.label} (From Mailing Address)`
		: `N/A, Update Mailing Address`;

	const einRequired = formValues.taxExempt ? [required()] : [];
	const einValidate = taxIdValidators[selectedCountry?.value]
		? [taxIdValidators[selectedCountry?.value]()]
		: [];

	return (
		<div data-testid={TEST_ID}>
			<Box pt={variantConfigs[variant]?.einPt}>
				<Grid container {...gridContainerSettings}>
					{showCountry && (
						<Grid item {...gridItemSettings}>
							<TextField
								disabled // read-only.
								label="County"
								variant="outlined"
								value={countryValue}
							/>
						</Grid>
					)}
					<Grid item {...gridItemSettings}>
						<Field
							aria-label={getTaxIdLabel(selectedCountry.label)}
							label={getTaxIdLabel(selectedCountry.label)}
							name="taxId"
							component={TextField}
							validate={[...einRequired, ...einValidate]}
							variant="outlined"
							disabled={disabled || isLoading}
							data-testid="TaxFormSection__TaxId"
						/>
					</Grid>
				</Grid>
			</Box>
			<TaxExemptFields
				{...{
					displayTaxExemptFields,
					formValues,
					taxExemptReasons,
					changeFieldValue,
					selectedCountry,
					variant,
					isLoading,
				}}
			/>
		</div>
	);
};

TaxFormSection.propTypes = {
	/* Function for changing values within the form */
	changeFieldValue: PropTypes.func,
	/* Current values of form inputs stored in state by redux form */
	formValues: PropTypes.object,
	/* Bool to disable the tax form section */
	disabled: PropTypes.bool,
	/* Currently selected country */
	selectedCountry: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	/* returned from API */
	taxExemptReasons: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		}),
	),
};

export default TaxFormSection;
