import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useInput } from '../LWForm';

/**
 * A text input component to be used within the context of LWForm
 * @see https://v4.mui.com/api/text-field/
 * @param {import('@material-ui/core').TextFieldProps} props
 */
const HookedTextField = ({
	helperText,
	label,
	name,
	onBlur,
	onChange,
	placeholder,
	variant = 'outlined',
	error: errorProp,
	...rest
}) => {
	const { value, onValueChange, error, validate } = useInput(name);

	if (!name) {
		return null;
	}

	/** @param {React.ChangeEvent<HTMLInputElement>} event */
	const handleChange = (event) => {
		if (!event.target) {
			return;
		}

		if (onChange) {
			onChange(event);
		}

		onValueChange(event.target.value);

		// validate on input change only if there is an existing error
		if (!error) {
			return;
		}

		validate(event.target);
	};

	/** @param {React.FocusEvent<HTMLInputElement, Element>} event */
	const handleBlur = (event) => {
		if (!event.target) {
			return;
		}

		if (onBlur) {
			onBlur(event);
		}

		validate(event.target);
	};

	return (
		<TextField
			helperText={error || errorProp || helperText}
			label={label}
			name={name}
			onBlur={handleBlur}
			onChange={handleChange}
			placeholder={placeholder || label}
			variant={variant}
			error={Boolean(error || errorProp)}
			{...rest}
			value={value}
		/>
	);
};

HookedTextField.propTypes = {
	name: PropTypes.string.isRequired,
};

export default HookedTextField;
