// @ts-check
import { roles } from 'utility/constants/roles';

/**
 * @typedef SubNavItemI
 * @property {boolean} [breadcrumbHidden]
 * @property {string} [name]
 * @property {boolean} [navHidden]
 * @property {boolean} [notYetImplemented]
 * @property {string} [path]
 * @property {(import('utility/constants/roles').RoleI)[]} [whitelistedRoles]
 * @property {Record<string, SubNavItemI>} [subNav]
 * */

/**
 * @typedef NavI
 * @property {boolean} [allowUnauth]
 * @property {string} [mobileName]
 * @property {string} name
 * @property {boolean} [navHidden]
 * @property {boolean} [notYetImplemented]
 * @property {string} path
 * @property {Record<string, SubNavItemI>} subNav
 * */

/** @type {NavI} */
export const home = {
	name: 'Home',
	path: '/',
	subNav: {},
};

/** @type {NavI} */
export const projects = {
	mobileName: 'Projects List',
	name: 'Projects',
	navHidden: false,
	notYetImplemented: false,
	path: '/projects',
	subNav: {
		create: {
			name: 'Create Project',
			path: '/projects/create',
		},
	},
};

/** @type {NavI} */
export const servers = {
	mobileName: 'Servers List',
	name: 'Servers',
	navHidden: false,
	notYetImplemented: false,
	path: '/servers',
	subNav: {
		sshKeys: {
			path: '/servers/ssh-keys',
			name: 'Public SSH Keys',
		},
		migrationCenter: {
			name: 'Migration Center',
			path: '/servers/migration-center',
		},
		scheduledTasks: {
			path: '/servers/scheduled-tasks',
			name: 'Scheduled Tasks',
		},
	},
};

/** @type {NavI} */
export const domain = {
	allowUnauth: true, // Need this for the navmenu item to show for logged out users
	name: 'Domains',
	mobileName: 'Domains List',
	path: '/domain',
	subNav: {
		registration: {
			path: '/shop/domain',
			name: 'Register a New Domain',
		},
		transfer: {
			path: '/domain/create-transfer',
			name: 'Transfer A Domain',
		},
		dns: {
			path: '/domain/dns',
			name: 'DNS',
		},
		cloudFlare: {
			path: '/domain/cloudflare',
			name: 'Cloudflare',
		},
		ssl: {
			path: '/domain/ssl',
			name: 'SSL',
		},
	},
};

/** @type {NavI} */
export const services = {
	name: 'Services',
	mobileName: 'Services List',
	path: '/services',
	subNav: {
		loadBalancers: {
			navHidden: true,
			path: '/services/load-balancers',
			name: 'Load Balancers',
		},
		cloudPrivateNetworks: {
			navHidden: true,
			path: '/services/cloud-private-networks',
			name: 'Cloud Private Networks',
		},
		advancedServices: {
			navHidden: true,
			path: '/services/advanced-services',
			name: 'Advanced Services',
		},
		cloudBlockStorage: {
			navHidden: true,
			path: '/services/cloud-block-storage',
			name: 'Cloud Block Storage',
		},
		firewallPresets: {
			navHidden: true,
			path: '/services/firewall-presets',
			name: 'Firewall Presets',
		},
		acronis: {
			navHidden: true,
			path: '/services/acronis',
			name: 'Acronis',
		},
		threatDown: {
			navHidden: true,
			path: '/services/threatdown',
			name: 'ThreatDown',
		},
		VPNs: {
			navHidden: true,
			path: '/services/vpns/:uniqId?',
			name: 'Cloud VPNs',
		},
	},
};

/** @type {NavI} */
export const shop = {
	name: 'Shop',
	mobileName: 'Shop',
	path: '/shop',
	subNav: {
		domain: {
			navHidden: true,
			path: '/shop/domain',
			name: 'Register a Domain',
		},
		marketplace: {
			name: 'Marketplace',
			path: '/shop/marketplace',
		},
		cart: {
			name: 'Cart',
			path: '/cart',
		},
		checkout: {
			name: 'Checkout',
			path: '/shop/checkout',
		},
	},
};

/** @type {NavI} */
export const billing = {
	navHidden: true,
	name: 'Billing',
	path: '/billing',
	subNav: {
		pay: {
			path: '/billing/pay',
			name: 'Make a Payment',
		},
		transactions: {
			path: '/billing/transactions',
			name: 'Transactions',
		},
		paymentMethod: {
			path: '/billing/payment-method',
			name: 'Update Payment Method',
		},
	},
};

/** @type {NavI} */
export const account = {
	name: 'Account',
	mobileName: 'Account Overview',
	path: '/account',
	subNav: {
		users: {
			path: '/account/users',
			name: 'Users',
		},
		secureNotes: {
			path: '/account/secure-notes',
			name: 'Secure Notes',
			whitelistedRoles: [roles.PURCHASER, roles.TECHNICIAN],
		},
		billing: {
			name: 'Billing Center',
			path: '/account/billing',
			whitelistedRoles: [
				roles.ACCOUNT_OWNER,
				roles.SECONDARY_OWNER,
				roles.PURCHASER,
				roles.SUSPENDED_OWNER,
			],
			subNav: {
				pay: {
					path: '/account/billing/pay',
					name: 'Make a Payment',
					navHidden: true,
					whitelistedRoles: [
						roles.ACCOUNT_OWNER,
						roles.SECONDARY_OWNER,
						roles.PURCHASER,
						roles.SUSPENDED_OWNER,
					],
				},
				transactions: {
					path: '/account/billing/transactions',
					name: 'Transactions',
					navHidden: true,
				},
				paymentMethod: {
					path: '/account/billing/payment-method',
					name: 'Update Payment Method',
					navHidden: true,
					whitelistedRoles: [
						roles.ACCOUNT_OWNER,
						roles.SECONDARY_OWNER,
						roles.PURCHASER,
						roles.SUSPENDED_OWNER,
					],
				},
			},
		},
		policies: {
			name: 'Policies',
			path: '/account/policies',
			whitelistedRoles: [
				roles.ACCOUNT_OWNER,
				roles.SECONDARY_OWNER,
				roles.PURCHASER,
				roles.SUSPENDED_OWNER,
			],
		},
		history: {
			name: 'History',
			path: '/account/history',
			whitelistedRoles: [
				roles.ACCOUNT_OWNER,
				roles.SECONDARY_OWNER,
				roles.PURCHASER,
				roles.SUSPENDED_OWNER,
			],
		},
		pay: {
			path: '/account/billing/pay',
			name: 'Make a Payment',
			navHidden: true,
			breadcrumbHidden: true,
			whitelistedRoles: [
				roles.ACCOUNT_OWNER,
				roles.SECONDARY_OWNER,
				roles.PURCHASER,
				roles.SUSPENDED_OWNER,
			],
		},
		transactions: {
			path: '/account/billing/transactions',
			name: 'Transactions',
			navHidden: true,
			breadcrumbHidden: true,
		},
		paymentMethod: {
			path: '/account/billing/payment-method',
			name: 'Update Payment Method',
			navHidden: true,
			breadcrumbHidden: true,
			whitelistedRoles: [
				roles.ACCOUNT_OWNER,
				roles.SECONDARY_OWNER,
				roles.PURCHASER,
				roles.SUSPENDED_OWNER,
			],
		},
	},
};

const navLinks = [
	home,
	projects,
	servers,
	domain,
	services,
	shop,
	billing,
	account,
];

export default navLinks;
