import styled from 'styled-components';
import PropTypes from 'prop-types';
import MUIIconButton from '@material-ui/core/IconButton';

const getColor = ({ $variant, theme }) => {
	switch ($variant) {
		case 'emphasized':
		case 'activeEmphasized':
			return theme.palette.primary.dark;
		case 'active':
		case 'activeTile':
		case 'activeTable':
		case 'tile':
			return theme.palette.primary.main;
		case 'table':
			return theme.palette.text.primary;
		case 'faint':
			return theme.palette.text.disabled;
		default:
			return 'inherit';
	}
};

const getBackgroundColor = ({ $variant, theme }) => {
	switch ($variant) {
		case 'active':
		case 'activeTile':
		case 'activeEmphasized':
			return theme.palette.primary.faint;
		default:
			return theme.palette.common.white;
	}
};

const getBorder = ({ $variant, theme }) => {
	switch ($variant) {
		case 'active':
		case 'activeTable':
		case 'activeEmphasized':
			return `2px solid ${theme.palette.primary.main}`;
		case 'emphasized':
			return `2px solid ${theme.palette.primary.faded}`;
		case 'tile':
		case 'activeTile':
		case 'plain':
			return '0';
		default:
			return `2px solid ${theme.palette.divider}`;
	}
};

const getMargin = ({ $variant }) => {
	switch ($variant) {
		case 'active':
			return 0;
		default:
			// only executed if margin is explicitly passed in as true
			return `1px`; // avoid content shifting when the border size changes
	}
};

const IconButton = styled(MUIIconButton)`
	background-color: ${({ $variant, theme }) =>
		getBackgroundColor({ $variant, theme })};
	&:hover {
		background-color: ${(p) => p.theme.palette.primary.faint};
	}
	${({ $border = true, $variant, theme }) => {
		return $border ? `border: ${getBorder({ $variant, theme })};` : '';
	}}
	color: ${({ $variant, theme }) => `${getColor({ $variant, theme })};`}
	${({ $margin = false, $variant }) => {
		return $margin ? `margin: ${getMargin({ $variant })};` : '';
	}}
	${({ $padding }) => ($padding ? `padding: ${$padding};` : '')}
`;

const VARIANTS = [
	'active',
	'emphasized',
	'faint',
	'activeTile',
	'activeTable',
	'tile',
	'table',
	'default',
	'plain',
	'activeEmphasized',
];

IconButton.propTypes = {
	/** What to display */
	$variant: PropTypes.oneOf(VARIANTS),
	/** Padding value */
	$padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/** Whether to add a margin or not */
	$margin: PropTypes.bool,
};

export { VARIANTS };
export default IconButton;
