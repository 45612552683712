import React, {
	lazy,
	Suspense,
	forwardRef,
	useCallback,
	useMemo,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTouchEvents from 'react-touch-events';
import { SnackbarContent } from 'notistack';

// MUI Icons
import ReportProblem from '@material-ui/icons/ReportProblemRounded';
import CheckCircle from '@material-ui/icons/CheckCircleRounded';
import Info from '@material-ui/icons/InfoRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';

// custom components
import LWIconButton from 'components/atoms/LWIconButton';
import Loading from 'components/common/Loading';

// local
import { useTheme } from '@material-ui/core/styles';
import useDispatchOrCall from 'utility/hooks/useDispatchOrCall';
import contentKeys from './contents/contentKeys';
import LWTypography from '../LWTypography';
import LWButton from '../LWButton';

// contents from key
const ServerImageCreateContents = lazy(
	() => import('./contents/ServerImageCreateContents'),
);
const StyledTextEm = lazy(() => import('components/atoms/StyledTextEm'));

const snackbarVariants = {
	DEFAULT: 'default',
	INFO: 'info',
	SUCCESS: 'success',
	ERROR: 'error',
};
const variants = ['default', 'info', 'success', 'error'];

const ContentFromKey = ({ contentKey, contentProps }) => {
	const Component = (() => {
		switch (contentKey) {
			case 'ServerImageCreate':
				return ServerImageCreateContents;
			case 'StyledTextEm':
				return StyledTextEm;
			default:
				return React.Fragment;
		}
	})();
	return (
		<Suspense fallback={<Loading />}>
			<Component {...contentProps} />
		</Suspense>
	);
};

const SSnackbar = styled(SnackbarContent)`
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	min-height: 52px;
	width: calc(100vw - 16px);
	max-width: 344px;

	margin-left: 8px;
	margin-right: 8px;
	border: 2px solid ${({ $color }) => $color};
	border-radius: 16px;

	box-shadow: ${({ theme }) => theme.shadows[6]};
	background: ${({ theme }) => theme.palette.background.paper};
	color: ${({ theme }) => theme.palette.text.primary};
`;

const SIcon = styled.span`
	height: 24px;
	width: 24px;
	margin-left: 16px;
	color: ${({ $color }) => $color};
`;

const SContent = styled((props) => <LWTypography variant="body2" {...props} />)`
	flex: 100% 1 1;
	margin: 16px;
`;

const SAction = styled.button`
	height: 36px;
	min-width: 0;
	${({ $icon }) => $icon && `width: 36px;`}
	margin-right: 8px;
`;

/**
 * Get variant customizations
 * @param {string?} variant
 * @returns [Icon, color]
 */
function useVariant(variant) {
	const theme = useTheme();

	return useMemo(() => {
		switch (variant) {
			case 'info':
				return [Info, theme.palette.primary.main];
			case 'success':
				return [CheckCircle, theme.palette.success.main];
			case 'warning': // Depricated: Warning doesn't exist in the spec
			case 'error':
				return [ReportProblem, theme.palette.error.main];
			default:
		}
		return [() => null, theme.palette.primary.main];
	}, [variant, theme]);
}

const Snackbar = forwardRef((props, ref) => {
	const {
		variant = snackbarVariants.DEFAULT,
		actionLabel,
		onActionClick,
		showClose,
		onClose,
		contentKey,
		contentProps,
		children,
	} = props;

	const [icon, color] = useVariant(variant);

	// Prevent double clicks
	const [disabled, setDisabled] = useState(false);
	const handleClose = useCallback(() => {
		if (disabled) return;

		setDisabled(true);
		if (onClose) {
			onClose();
		}
	}, [disabled, onClose]);

	const content = useMemo(
		() =>
			contentKey ? (
				<ContentFromKey contentKey={contentKey} contentProps={contentProps} />
			) : (
				children
			),
		[children, contentKey, contentProps],
	);

	const handleSwipe = useCallback(
		(event) => {
			if (event.touches) {
				handleClose();
			}
		},
		[handleClose],
	);

	const actionCallback = useDispatchOrCall(onActionClick);
	const handleActionClick = useCallback(() => {
		handleClose();
		actionCallback();
	}, [actionCallback, handleClose]);

	return (
		<ReactTouchEvents ref={ref} onSwipe={handleSwipe}>
			<SSnackbar $color={color}>
				<SIcon as={icon} $color={color} />
				<SContent>{content}</SContent>
				{actionLabel ? (
					<SAction
						as={LWButton}
						disabled={disabled}
						onClick={handleActionClick}
					>
						{actionLabel}
					</SAction>
				) : (
					showClose && (
						<SAction
							as={LWIconButton}
							$icon
							disabled={disabled}
							onClick={handleClose}
						>
							<CloseIcon />
						</SAction>
					)
				)}
			</SSnackbar>
		</ReactTouchEvents>
	);
});
Snackbar.displayName = 'Snackbar';

Snackbar.propTypes = {
	variant: PropTypes.oneOf(variants),
	contentKey: PropTypes.oneOf(Object.values(contentKeys)),
	contentProps: PropTypes.object,
	statusSheet: PropTypes.shape({
		isOpen: PropTypes.bool.isRequired,
		height: PropTypes.number.isRequired,
	}),
};

export { variants, snackbarVariants };
export default Snackbar;
