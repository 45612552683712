import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { actions as dialogActions } from 'modules/dialogs';
import { adminCartsActions } from 'modules/adminCarts';
import { validateDomain, validateOppId } from 'utility/lwForm/validators';
import LWForm from 'components/LWForm';
import HookedTextField from 'components/LWForm/HookedTextField';
import HookedConfirmCancel from 'components/LWForm/HookedConfirmCancel';

export const domainFieldLabel = 'Domain to Auto-add';

const CreateAdminCartDialogContents = () => {
	const dispatch = useDispatch();
	const [formValues, setFormValues] = useState({
		opportunityId: '',
		name: '',
		description: '',
		domainToAutoAdd: '',
	});
	const [formErrors, setFormErrors] = useState({
		opportunityId: '',
		name: '',
		description: '',
		domainToAutoAdd: '',
	});

	const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);

	const closeDialog = () => dispatch(dialogActions.close());

	const handleSubmit = (values) => {
		const { opportunityId, name, description, domainToAutoAdd } = values;

		if (!showAdditionalDetails) {
			dispatch(
				adminCartsActions.createNewCart({
					opportunityId,
				}),
			);
		} else {
			dispatch(
				adminCartsActions.createNewCart({
					opportunityId,
					name,
					description,
					domainToAutoAdd,
				}),
			);
		}

		closeDialog();
	};

	return (
		<Grid
			container
			direction="column"
			component={LWForm}
			validators={{
				opportunityId: validateOppId,
				domainToAutoAdd: validateDomain,
			}}
			onSubmit={handleSubmit}
			values={formValues}
			errors={formErrors}
			onValuesChange={setFormValues}
			onErrorsChange={setFormErrors}
			spacing={3}
		>
			<Grid item>
				<HookedTextField name="opportunityId" label="Opportunity" required />
			</Grid>
			<Grid item>
				<FormControlLabel
					label="Add Details"
					control={
						<Checkbox
							color="primary"
							checked={showAdditionalDetails}
							onChange={(event, checked) => setShowAdditionalDetails(checked)}
						/>
					}
				/>
			</Grid>
			<Grid item>
				<Collapse in={showAdditionalDetails}>
					<Grid container direction="column" spacing={3}>
						<Grid item>
							<HookedTextField name="name" label="Name" />
						</Grid>
						<Grid item>
							<HookedTextField
								fullWidth
								name="description"
								label="Description"
								multiline
								minRows={4}
							/>
						</Grid>
						<Grid item>
							<HookedTextField
								name="domainToAutoAdd"
								label={domainFieldLabel}
							/>
						</Grid>
					</Grid>
				</Collapse>
			</Grid>
			<Grid item>
				<HookedConfirmCancel confirmText="Create" cancel={closeDialog} />
			</Grid>
		</Grid>
	);
};

export default CreateAdminCartDialogContents;
