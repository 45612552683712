// @ts-check

/**
 * @typedef AddonCapabilityT
 * @property {string} displayName
 * @property {string} productCode
 * @property {string} listUrl
 * @property {'security' | 'backup'} type
 * @property {string} capability
 * */

/**
 * A list of the supported product addons with their notable attributes.
 * @type {Record<string, AddonCapabilityT>}
 * */
const addonCapabilityDetails = {
	canThreatDown: {
		displayName: 'ThreatDown',
		productCode: 'ServerSecurity.ThreatDown',
		listUrl: '/services/threatdown',
		type: 'security',
		capability: 'canThreatDown',
	},
	addonAcronisBackup: {
		displayName: 'Acronis',
		productCode: 'addonAcronisBackup',
		listUrl: '/services/acronis',
		type: 'backup',
		capability: 'addonAcronisBackup',
	},
};

export default addonCapabilityDetails;
